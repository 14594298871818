import * as React from 'react';
import {default as ProductVariantInterface} from '../../entities/ProductVariant';
import styles from './ProductVariant.module.scss';
import { Image } from '../Image/Image';

export interface Props {
  variant: ProductVariantInterface;
  checked: boolean;
  onChange: (variant: ProductVariantInterface) => void;
}

const handleChange = (variant:ProductVariantInterface, callback: Function) => {
  callback(variant);
}

const ProductVariant: React.SFC<Props> = (props) => {
  const { variant, checked, onChange } = props;

  return (
    <>
      <div className={styles.checkbox}>
          <input
            checked={checked}
            type="radio"
            id={variant.id}
            name="productVariant"
            value={variant.slug}
            onChange={() => handleChange(variant, onChange)}
          />
          <label className={styles.label} htmlFor={variant.id}>
            <Image className={styles.label__image} image={variant.image} />
            <p className={styles.label__text}>{variant.title}</p>
          </label>
      </div>
    </>
  );
};

export default ProductVariant;
