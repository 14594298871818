/* eslint-disable no-useless-escape */
import { Location } from "history";

export default class PathHelper {
  public static defaultLocale = "de";

  public static pathFromSlug(slug: string, locale: string): string {
    if (!slug || slug === "" || slug === "/") {
      return `/${locale}`;
    }

    let suffix = slug.startsWith("/") ? slug : `/${slug}`;
    if (suffix.endsWith("/")) {
      suffix = suffix.substring(0, suffix.length - 2);
    }

    return `/${locale}${suffix}`;
  }

  public static canonicalPath(location: Location): string {
    return PathHelper.canonicalPathOfPath(location.pathname);
  }

  public static canonicalPathOfPath(path: string): string {
    let p = path;
    if (path.endsWith("/")) {
      p = path.substring(0, path.length - 1);
    }
    if (!p.startsWith("/")) {
      p = `/${p}`;
    }
    return "https://www.ipuro.com" + p;
  }

  public static localeOfPath(path: string) {
    const locale = path.split("/")[1];
    const matches = /^\/([^\/]*)\//.exec(path);
    if (matches && matches.length > 1) {
      return matches[1];
    }

    return locale || PathHelper.defaultLocale;
  }

  public static comma2LineBreak(title: string) {
    return { __html: `${title.split(",").join("<br />")}` };
  }
}
