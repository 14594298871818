import * as geolib from "geolib";

export default class GeoLocation {
  public readonly latitude: number;
  public readonly longitude: number;

  constructor(latitude: number, longitude: number) {
    this.latitude = latitude;
    this.longitude = longitude;
  }

  public distanceTo(other: GeoLocation): number {
    return other
      ? geolib
      .getDistance(
        { latitude: this.latitude, longitude: this.longitude },
        { latitude: other.latitude, longitude: other.longitude }
      )
      .valueOf()
      : -1;
  }

  // Use this method if you need to roughly sort locations by distance ot another location, e.g. when preselecting for store search
  public euclideanDistanceSquared(other: GeoLocation): number {
    const la = this.latitude - other.latitude;
    const lo = this.longitude - other.longitude;
    return (la * la) + (lo * lo);
  }
}
