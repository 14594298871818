import React from "react";

import SocialMediaIcons from "../SocialMediaIcons/SocialMediaIcons";
import FooterLink from "../FooterLink/FooterLink";

import styles from "./Footer.module.scss";
import ServiceRegistry from "../../services/ServiceRegistry";
import ProductLine from "../../entities/ProductLine";
import PathHelper from "../../services/Helper";

interface Props {
  t: Function;
  locale: string;
  productLines: Array<ProductLine>;
}

const Footer: React.FC<Props> = ({ t, locale, productLines }) => {
  const routeRegistry = ServiceRegistry.getRouteRegistry();

  const fallBackRoute = {
    path: "",
    navigationTitle: "",
  };

  // TODO: fix. wtf.
  const aboutIpuro =
    routeRegistry.getByIdentifierAndLocale("about", locale) || fallBackRoute;
  const faq =
    routeRegistry.getByIdentifierAndLocale("faq", locale) || fallBackRoute;
  const storefinder =
    routeRegistry.getByIdentifierAndLocale("storefinder", locale) ||
    fallBackRoute;
  const terms =
    routeRegistry.getByIdentifierAndLocale("terms", locale) || fallBackRoute;
  const privacy =
    routeRegistry.getByIdentifierAndLocale("privacy", locale) || fallBackRoute;
  const imprint =
    routeRegistry.getByIdentifierAndLocale("imprint", locale) || fallBackRoute;

  return (
    <div className={styles.Footer}>
      <div className="columns">
        <div className={`${styles.Footer__column} column is-3 is-flex-mobile`}>
          <h4 className={styles.Footer__headline}>
            {t("footer.headline.contact")}
          </h4>
          <FooterLink to={`mailto:${t("data.email")}`} icon="mail" isExternal>
            {t("data.email")}
          </FooterLink>
          <FooterLink to={`tel:${t("data.phone")}`} icon="phone" isExternal>
            {t("data.phone")}
          </FooterLink>
          <SocialMediaIcons t={t} />
        </div>
        <div className={`${styles.Footer__column} column is-3 is-flex-mobile`}>
          <h4 className={styles.Footer__headline}>
            {t("footer.headline.content")}
          </h4>
          {productLines.map((productLine) => {
            return (
              <FooterLink
                key={productLine.slug}
                to={PathHelper.pathFromSlug(productLine.slug, locale)}
              >
                {productLine.title}
              </FooterLink>
            );
          })}
          <FooterLink to={aboutIpuro.path}>
            {aboutIpuro.navigationTitle}
          </FooterLink>
        </div>
        <div className={`${styles.Footer__column} column is-3 is-flex-mobile`}>
          <h4 className={styles.Footer__headline}>
            {t("footer.headline.help")}
          </h4>
          <FooterLink to={faq.path}>{faq.navigationTitle}</FooterLink>
          <FooterLink to={storefinder.path}>
            {storefinder.navigationTitle}
          </FooterLink>
          <FooterLink>{t("footer.vatHint")}</FooterLink>
        </div>
        <div className={`${styles.Footer__column} column is-3 is-flex-mobile`}>
          <h4 className={styles.Footer__headline}>
            {t("footer.headline.legal")}
          </h4>
          <FooterLink to={terms.path}>{terms.navigationTitle}</FooterLink>
          <FooterLink to={privacy.path}>{privacy.navigationTitle}</FooterLink>
          <FooterLink to={imprint.path}>{imprint.navigationTitle}</FooterLink>
        </div>
      </div>
    </div>
  );
};

export default Footer;
