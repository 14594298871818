import RouteRegistry from "../services/RouteRegistry";
import ImageInfo from "./ImageInfo";
import Product from "./Product";
import ProductLine from "./ProductLine";
import WithAlternates from "./WithAlternates";

export default class ProductCategory implements WithAlternates {
  public readonly id: string;
  public readonly slug: string;
  public readonly title: string;
  public readonly description: any; // RichText
  public readonly headerImage: ImageInfo;
  public readonly headerImageMobile?: ImageInfo;
  public readonly productLine: ProductLine;
  public readonly products: Product[];
  public readonly alternates: { [locale: string]: string };
  public readonly seoDescription: string;
  public readonly isNew: boolean;
  public readonly isOnSale: boolean;
  public readonly limited: boolean;
  public readonly productPreviews: Product[];
  public readonly overviewImage: ImageInfo;
  public readonly hasDarkHeaderImage: boolean;
  public readonly productCategoryDescription: any;

  constructor(
    id: string,
    slug: string,
    title: string,
    description: any,
    headerImage: ImageInfo,
    headerImageMobile: ImageInfo | undefined,
    productLine: ProductLine,
    alternates: { [locale: string]: string },
    seoDescription: string,
    isNew: boolean,
    isOnSale: boolean,
    limited: boolean,
    overviewImage: ImageInfo,
    hasDarkHeaderImage: boolean,
    productCategoryDescription: any
  ) {
    this.id = id;
    this.slug = slug;
    this.title = title;
    this.description = description;
    this.headerImage = headerImage;
    this.headerImageMobile = headerImageMobile;
    this.productLine = productLine;
    this.products = [];
    this.alternates = alternates;
    this.seoDescription = seoDescription;
    this.isNew = isNew;
    this.isOnSale = isOnSale;
    this.limited = limited;
    this.productPreviews = [];
    this.overviewImage = overviewImage;
    this.hasDarkHeaderImage = hasDarkHeaderImage;
    this.productCategoryDescription = productCategoryDescription;
  }

  public addProducts(products: Product[]) {
    products.forEach((product) => this.products.push(product));
  }

  public addProductPreviews(products: Product[]) {
    products.forEach((product) => this.productPreviews.push(product));
  }

  public getAlternatePaths(): { locale: string; path: string }[] {
    const paths: { locale: string; path: string }[] = [];
    Object.keys(this.alternates).forEach((locale) => {
      const path = RouteRegistry.productCategoryAlternatePath(this, locale);
      if (path) {
        paths.push({ locale, path });
      }
    });
    return paths;
  }
}
