import * as Contentful from "contentful";
import ContentService from "../ContentService";
import PathHelper from "../Helper";
import ContentfulContentService from "./ContentfulContentService";

export default class ContentfulContentServiceBuilder {
  private host: string =
    process.env.REACT_APP_ENV === "stage"
      ? "preview.contentful.com"
      : "cdn.contentful.com";
  private spaceId: string;
  private accessToken: string;
  private locale: string;
  private environment: string;

  constructor(spaceId: string, accessToken: string, environment: string) {
    this.spaceId = spaceId;
    this.accessToken = accessToken;
    this.locale = PathHelper.defaultLocale;
    this.environment = environment;
  }

  public getHost(): string {
    return this.host;
  }

  public withHost(host: string): ContentfulContentServiceBuilder {
    this.host = host;
    return this;
  }

  public withLocale(locale: string): ContentfulContentServiceBuilder {
    this.locale = locale;
    return this;
  }

  public withEnvironment(environment: string): ContentfulContentServiceBuilder {
    this.environment = environment;
    return this;
  }

  public build(): ContentService {
    const client = Contentful.createClient({
      space: this.spaceId,
      accessToken: this.accessToken,
      environment: this.environment,
      host: this.host,
    });
    return new ContentfulContentService(client, this.locale);
  }
}
