export default class GoogleMapsApiLoader {
  private readonly apiKey: string;
  private readonly locale: string;
  private readonly region?: string;
  private isLoading: boolean;
  private callbacks: Array<() => void>;
  private tries: number;

  constructor(apiKey: string, locale: string, region?: string) {
    this.apiKey = apiKey;
    this.locale = locale;
    this.region = region;
    this.isLoading = false;
    this.callbacks = [];
    this.tries = 0;

    this.awaitIsLoaded = this.awaitIsLoaded.bind(this);
  }

  public isLoaded(): boolean {
    // @ts-ignore
    return window.google && window.google.maps && window.google.maps.places;
  }

  public callbackWhenLoaded(cb: () => void) {
    if (this.isLoaded()) {
      cb();
    }

    this.callbacks.push(cb);

    if (!this.isLoading) {
      this.isLoading = true;
      const script = document.createElement("script");
      const region = this.region ? `&region=${this.region}` : '';
      script.src = `https://maps.googleapis.com/maps/api/js?key=${this.apiKey}&libraries=places&language=${this.locale}${region}`;
      script.async = true;
      document.body.appendChild(script);

      this.awaitIsLoaded();
    }
  }

  private awaitIsLoaded(): void {
    if (!this.isLoaded()) {
      if (this.tries >= 100) {
        return;
      }

      this.tries++;
      setTimeout(this.awaitIsLoaded, 50);
      return;
    }

    if (this.callbacks.length > 0) {
      this.callbacks.forEach(cb => cb());
      this.callbacks = [];
    }
  }

}
