import React from 'react';
import classNames from 'classnames';

import IpuroLink from '../IpuroLink/IpuroLink';

import styles from './SearchResultItem.module.scss';
import RichText from '../RichText';

interface Props {
    headline: string,
    children: any,
    linkLabel: string,
    link: string,
}

const SearchResultItem: React.SFC<Props> =({headline, children, linkLabel, link}) => {
    const className = classNames({
        [styles.SearchResultItem]: true,
    });

    return (
        <div className={className}>
            <h2 className={styles.SearchResultItem__headline}>
                {headline}
            </h2>

            <RichText className={styles.SearchResultItem__text} text={children} />

            <IpuroLink
                to={link}
            >
                {linkLabel}
            </IpuroLink>
        </div>
    );
};

export default SearchResultItem;