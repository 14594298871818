import PathHelper from "../services/Helper";
import ProductSearchIndex from "../services/ProductSearchIndex";
import Product from "./Product";
import ProductCategory from "./ProductCategory";
import ProductLine from "./ProductLine";
import Scent from "./Scent";

export default class ProductCatalog {
  public readonly locale: string;
  private readonly lines: ProductLine[];
  private readonly lineById: Map<string, ProductLine>;
  private readonly lineByCategoryId: Map<string, ProductLine>;
  private readonly lineByPath: Map<string, ProductLine>;
  private readonly categoryById: Map<string, ProductCategory>;
  private readonly categoryByProductId: Map<string, ProductCategory>;
  private readonly categoryByPath: Map<string, ProductCategory>;
  private readonly productById: Map<string, Product>;
  private readonly productByPath: Map<string, Product>;
  private readonly searchIndex: ProductSearchIndex;
  private readonly productsByScent: Map<string, Product[]>;
  private baseSlug: string;

  constructor(productLines: ProductLine[], baseSlug: string, locale: string) {
    this.baseSlug = baseSlug;
    this.locale = locale;
    this.lines = productLines;
    this.lineById = new Map<string, ProductLine>();
    this.lineByCategoryId = new Map<string, ProductLine>();
    this.lineByPath = new Map<string, ProductLine>();
    this.categoryById = new Map<string, ProductCategory>();
    this.categoryByProductId = new Map<string, ProductCategory>();
    this.categoryByPath = new Map<string, ProductCategory>();
    this.productById = new Map<string, Product>();
    this.productByPath = new Map<string, Product>();
    this.productsByScent = new Map<string, Product[]>();
    this.searchIndex = new ProductSearchIndex();
    for (const line of productLines) {
      this.lineById.set(line.id, line);
      this.searchIndex.add(line);
      for (const category of line.categories) {
        this.lineByCategoryId.set(category.id, line);
        this.categoryById.set(category.id, category);
        this.searchIndex.add(category);
        for (const product of category.products) {
          this.categoryByProductId.set(product.id, category);
          this.productById.set(product.id, product);
          this.searchIndex.add(product);
          product.scents.forEach((scent) => {
            const slot = (this.productsByScent.get(scent.id) || []).filter(
              (p) => p.id !== product.id
            );
            slot.push(product);
            this.productsByScent.set(scent.id, slot);
          });
        }
      }
    }

    for (const line of productLines) {
      this.lineByPath.set(this.getPathOfLine(line), line);
      for (const category of line.categories) {
        this.categoryByPath.set(this.getPathOfCategory(category), category);
        for (const product of category.products) {
          this.productByPath.set(this.getPathOfProduct(product), product);
        }
      }
    }
    this.searchIndex.build();
  }

  public setBaseSlug(slug: string) {
    this.baseSlug = slug;
  }

  public getPath(item: ProductLine | ProductCategory | Product) {
    if (item instanceof ProductLine) {
      return this.getPathOfLine(item as ProductLine);
    }
    if (item instanceof ProductCategory) {
      return this.getPathOfCategory(item as ProductCategory);
    }
    return this.getPathOfProduct(item as Product);
  }

  public getPathOfProduct(product: Product) {
    const arr: string[] = [];
    if (this.baseSlug) {
      arr.push(this.baseSlug);
    }
    const category = this.categoryByProductId.get(product.id);
    if (!category) {
      throw new Error(`Product ${product.id} has no category`);
    }
    const line = this.lineByCategoryId.get(category.id);
    if (!line) {
      throw new Error(
        `Category ${category.id} of product ${product.id} has no line`
      );
    }
    arr.push(line.slug);
    arr.push(category.slug);
    arr.push(product.slug);
    return PathHelper.pathFromSlug(arr.join("/"), this.locale);
  }

  public getPathOfCategory(category: ProductCategory) {
    const arr: string[] = [];
    if (this.baseSlug) {
      arr.push(this.baseSlug);
    }
    const line = this.lineByCategoryId.get(category.id);
    if (!line) {
      throw new Error(`Category ${category.id} has no line`);
    }
    arr.push(line.slug);
    arr.push(category.slug);
    return PathHelper.pathFromSlug(arr.join("/"), this.locale);
  }

  public getPathOfLine(line: ProductLine) {
    const arr: string[] = [];
    if (this.baseSlug) {
      arr.push(this.baseSlug);
    }
    arr.push(line.slug);
    return PathHelper.pathFromSlug(arr.join("/"), this.locale);
  }

  public getProductLines(): ProductLine[] {
    return this.lines;
  }

  public getProductLine(id: string): ProductLine | undefined {
    return this.lineById.get(id);
  }

  public getProductCategory(id: string): ProductCategory | undefined {
    return this.categoryById.get(id);
  }

  public getProduct(id: string): Product | undefined {
    return this.productById.get(id);
  }

  public getProductByPath(path: string): Product | undefined {
    return this.productByPath.get(path);
  }

  public getProductCategoryByPath(path: string): ProductCategory | undefined {
    return this.categoryByPath.get(path);
  }

  public getProductLineByPath(path: string): ProductLine | undefined {
    return this.lineByPath.get(path);
  }

  public search(term: string): Array<ProductLine | ProductCategory | Product> {
    return this.searchIndex.isReady() ? this.searchIndex.search(term) : [];
  }

  public getProductsByScents(
    scents: Scent[],
    exclude: string[] = []
  ): Product[] {
    return this.getProductsByScentIds(
      scents.map((scent) => scent.id),
      exclude
    );
  }

  public getProductsByScentIds(
    scents: string[],
    exclude: string[] = []
  ): Product[] {
    const map = new Map<string, Product>();
    scents.forEach((scent) => {
      const products = this.productsByScent.get(scent);
      if (products) {
        products.forEach((product) => {
          if (!exclude.some((excluded) => product.id === excluded)) {
            map.set(product.id, product);
          }
        });
      }
    });
    const unsorted = Array.from(map.values());
    return unsorted.sort((a, b) => (a.id > b.id ? 1 : a.id < b.id ? -1 : 0));
  }
}
