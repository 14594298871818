import React from 'react';
import { Link } from 'react-router-dom';

import Icon from '../Icon/Icon';
import styles from './TopBarItem.module.scss';

interface Props {
    onClick?: () => any,
    onIconClick?: any,
    children: string | React.ReactNode,
    icon?: string,
    iconSize?: string,
    link?: string,
}

const TopBarItem: React.SFC<Props> =({onClick, icon, iconSize, children, link, onIconClick}) => {
    const content = [
        children,
        icon && <Icon key='topBarIcon' onClick={onIconClick} icon={icon} size={iconSize} className={styles.TopBarItem__icon}/>    
    ];
    
    return (
        <div className={styles.TopBarItem} onClick={onClick}>
            {link
                ?   <Link to={link}>
                        {content}
                    </Link>
                : content
            }
        </div>
    );
};

export default TopBarItem;
