export default class Scent {
  public readonly id: string;
  public readonly identifier: string;
  public readonly title: string;

  constructor(id: string, identifier: string, title: string) {
    this.id = id;
    this.identifier = identifier;
    this.title = title;
  }
}
