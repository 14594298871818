import GeoLocation from "./GeoLocation";

export default class Store {
  public readonly id: string;
  public readonly title: string;
  public readonly street: string;
  public readonly addressAddition?: string;
  public readonly city: string;
  public readonly zipCode: string;
  public readonly countryCode: string;
  public readonly tags: string[];
  public readonly geoLocation?: GeoLocation;
  public readonly openingTimes?: string[];
  public readonly phoneNumber?: string;

  constructor(id: string,
              title: string,
              street: string,
              city: string,
              zipCode: string,
              countryCode: string,
              tags: string[],
              geoLocation?: GeoLocation,
              addressAddition?: string,
              openingTimes?: string[],
              phoneNumber?: string) {
    this.id = id;
    this.title = title;
    this.street = street;
    this.city = city;
    this.zipCode = zipCode;
    this.countryCode = countryCode;
    this.geoLocation = geoLocation;
    this.tags = tags;
    this.addressAddition = addressAddition;
    this.openingTimes = openingTimes;
    this.phoneNumber = phoneNumber;
  }
}
