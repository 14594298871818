import {Builder, Index} from "lunr";
import Product from "../entities/Product";
import ProductCategory from "../entities/ProductCategory";
import ProductLine from "../entities/ProductLine";

export default class ProductSearchIndex {
  private index!: Index;
  private builder: Builder;
  private items: Map<string, ProductLine|ProductCategory|Product>;

  constructor() {
    this.builder = new Builder();
    this.builder.ref('id');
    this.builder.field('title');
    this.builder.field('scents');
    this.builder.field('type');
    this.items = new Map<string, ProductLine|ProductCategory|Product>()
  }

  public add(item: ProductLine|ProductCategory|Product): void {
    this.items.set(item.id, item);
    const doc: any = {
      id: item.id,
      title: item.title
    };
    if (item instanceof Product) {
      const product = item as Product;
      doc.scents = product.scents.map(scent => scent.title);
      doc.type = product.type.singleTitle;
    }
    this.builder.add(doc);
  }

  public build(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.index = this.builder.build();
    });
  }

  public isReady(): boolean {
    return !!this.index;
  }

  public search(term: string): Array<ProductLine|ProductCategory|Product> {
    const found: Array<ProductLine|ProductCategory|Product> = [];
    this.index
    .search(term)
    .forEach(result => {
      const item = this.items.get(result.ref);
      if (item) {
        found.push(item);
      }
    });
    return found;
  }
}
