import React, {ReactNode} from 'react';
import classNames from 'classnames';

import styles from './CompositionTeaserMobile.module.scss';
import Headline from '../Headline/Headline';
import ImageInfo from '../../entities/ImageInfo';
import { Image } from '../Image/Image';

interface Props {
    headline: string,
    subHeadline: string,
    largeImage: ImageInfo | undefined,
    smallImage: ImageInfo | undefined,
    reversePosition?: boolean,
    children?: ReactNode,
}

const CompositionTeaserMobile: React.SFC<Props> =({headline, subHeadline, largeImage, smallImage, reversePosition, children}) => {
    const className = classNames({
        [styles.CompositionTeaserMobile]: true,
    });

    const columnsClassName = classNames('columns', {
        [styles.CompositionTeaserMobile__columns___reversed]: reversePosition,
    });

    const mixedColumnclassName = classNames('column',{
        [styles.CompositionTeaserMobile__mixedColumn]: !children,
        [styles.CompositionTeaserMobile__mixedColumn___withQuote]: children,
        'is-offset-1': reversePosition,
        'is-offset-6': children,
        'is-6-mobile': !children,

    });

    const largeImageColumnclassName = classNames('column', 'is-5', {
        'is-offset-1': !reversePosition,
    });

    const optionalColumnclassName = classNames('column', 'is-3',{
        [styles.CompositionTeaserMobile__optionalColumn]: true,
    });

    return (
        <div className={className}>
            <div className={styles.CompositionTeaserMobile__headlines}>
                <div className={styles.CompositionTeaserMobile__headlines__subheadline}>
                    {subHeadline}
                </div>
                <Headline component="h1" variant="h2">
                    {headline}
                </Headline>
            </div>
            <div className={columnsClassName}>
                <div className={largeImageColumnclassName}>
                    <Image image={largeImage} />
                </div>
                <div className={mixedColumnclassName}>
                    <Image image={smallImage} />
                </div>
                {children &&
                    <div className={optionalColumnclassName}>
                        {children}
                    </div>
                }
            </div>
        </div>
    );
};

export default CompositionTeaserMobile;
