/* eslint-disable array-callback-return */
import * as React from "react";
import { Component } from "react";
import { Redirect, RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
// import RichText from "../components/RichText";
import SeoTags from "../components/SeoTags";
import Product from "../entities/Product";
import PathHelper from "../services/Helper";
import ServiceRegistry from "../services/ServiceRegistry";
import Headline from "../components/Headline/Headline";
import ProductTeaser from "../components/ProductTeaser/ProductTeaser";
import HeroTeaser from "../components/HeroTeaser/HeroTeaserTeaser";
import styles from "./ProductCategoryPage.module.scss";
import ProductNavigation from "../components/ProductNavigation/ProductNavigation";
import { StickyContainer, Sticky } from "react-sticky";
import classNames from "classnames";
import TextTeaser from "../components/TextTeaser/TextTeaser";
import RichText from "../components/RichText";

interface Params {
  locale: string;
  productLine: string;
  productCategory: string;
}

export interface Props extends RouteComponentProps<Params> {}

interface State {
  navigationVisible: any;
}

export default class ProductCategoryPage extends Component<Props, State> {
  navigation: any;

  constructor(props: Props, context: any) {
    super(props, context);
    // this.navigation = React.createRef();

    this.state = {
      navigationVisible: true,
    };

    this.renderProduct = this.renderProduct.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.isInViewport);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.isInViewport);
  }

  isInViewport = () => {
    if (this.navigation) {
      const top = this.navigation.selector.current.getBoundingClientRect().top;
      const isVisible = top + 0 >= 0 && top - 0 <= window.innerHeight;

      console.log(isVisible);
    }
  };

  public render(): JSX.Element {
    const { locale } = this.props.match.params;
    const productCatalog = ServiceRegistry.getProductCatalog();
    // const t = ServiceRegistry.getTranslations().getT();
    const productCategory = productCatalog.getProductCategoryByPath(
      this.props.location.pathname
    );
    if (!productCategory) {
      return <Redirect to={PathHelper.pathFromSlug("404", locale)} />;
    }
    const productLine = productCategory.productLine;

    const productTypes = {};

    // build object with productType keys and corresponding products
    productCategory.products.map((product) => {
      const productType = product.type.identifier;

      if (!productTypes[productType]) {
        productTypes[productType] = [];
      }
      productTypes[productType].push(product);
    });

    return (
      <>
        <SeoTags
          location={this.props.location}
          title={productCategory.title}
          description={productCategory.seoDescription}
          alternates={productCategory.getAlternatePaths()}
        />
        <StickyContainer>
          <div className="columns is-multiline">
            <div className="column is-12">
              <Headline className={styles.headline___main}>
                {productCategory.title}
              </Headline>
              <HeroTeaser
                image={productCategory.headerImage}
                imageMobile={productCategory.headerImageMobile}
              >
                <Sticky>
                  {({ style }) => {
                    return (
                      <ProductNavigation
                        ref={(el) => (this.navigation = el)}
                        productLine={productLine}
                        activeProductCategory={productCategory}
                        isOnCategoryPage
                        style={style}
                        invertedFontColor={productCategory.hasDarkHeaderImage}
                      />
                    );
                  }}
                </Sticky>
              </HeroTeaser>
              {/* {productLine && <h2>{t('productCategory.headline.productLine')}: <Link to={productCatalog.getPathOfLine(productLine)}>{productLine.title}</Link></h2>} */}
              {/* <Headline component="h2">{t('productCategory.headline.products')}</Headline> */}
              {/* <Headline component="h2">{productCategory.title}</Headline> */}
              <TextTeaser>
                <RichText text={productCategory.productCategoryDescription} />
              </TextTeaser>
            </div>
            {/* {productCategory.products.map(this.renderProduct)} */}
            {this.renderProductTypes()}
          </div>
        </StickyContainer>
      </>
    );
  }

  renderProductTypes() {
    const productCatalog = ServiceRegistry.getProductCatalog();
    const productCategory = productCatalog.getProductCategoryByPath(
      this.props.location.pathname
    );
    const productTypes = {};

    // build object with productType keys and corresponding products
    if (productCategory) {
      productCategory.products.map((product) => {
        const productType = product.type.identifier;

        if (!productTypes[productType]) {
          productTypes[productType] = {};
          productTypes[productType].title = product.type.listTitle;
          productTypes[productType].products = [];
        }
        productTypes[productType].products.push(product);
      });
    }

    return Object.keys(productTypes).map((key, index) => {
      return (
        <React.Fragment key={index}>
          <div className="column is-12" id={key}>
            <div className="columns is-multiline is-mobile">
              <div className={classNames(styles.headline, "column is-12")}>
                {productCategory && productCategory.title && (
                  <Headline color="grey" component="h2">
                    {productCategory.title}
                  </Headline>
                )}
                <Headline component="h2">{productTypes[key].title}</Headline>
              </div>
              {productTypes[key].products.map((product) =>
                this.renderProduct(product, key)
              )}
            </div>
          </div>
        </React.Fragment>
      );
    });
  }

  public renderProduct(product: Product, key: string): JSX.Element {
    const t = ServiceRegistry.getTranslations().getT();
    const productCatalog = ServiceRegistry.getProductCatalog();
    const badgeLabel = product.isOnSale
      ? t("product.isOnSale")
      : product.limited
      ? t("product.limited")
      : product.isNew
      ? t("product.new")
      : undefined;

    const useSmallGrid = key === "scents";
    const columnSize = useSmallGrid
      ? "column is-2-desktop is-3-tablet is-6-mobile"
      : "column is-4-tablet is-6-mobile";
    const teaserSize = useSmallGrid ? "small" : "big";

    return (
      <div key={product.id} className={columnSize}>
        <Link to={productCatalog.getPathOfProduct(product)}>
          <ProductTeaser
            size={teaserSize}
            image={product.previewImage}
            title={product.title}
            type={product.type.singleTitle}
            price={product.price}
            numVariants={product.variants.length}
            badgeLabel={badgeLabel}
          />
        </Link>
      </div>
    );
  }
}
