import Store from "./Store";

export default class ClosestStoreResult {
  public readonly store: Store;
  public readonly distance: number;

  constructor(store: Store, distance: number) {
    this.store = store;
    this.distance = distance;
  }
}
