import ContentBlock from "./ContentBlock";
import ImageInfo from "./ImageInfo";

export default class PageData {
  public readonly id: string;
  public readonly slug: string;
  public readonly text: any; // richtext
  public readonly title: string;
  public readonly navigationTitle: string;
  public readonly seoDescription: string;
  public readonly headerImage?: ImageInfo;
  public readonly headerImageMobile?: ImageInfo;
  public readonly contentBlocks?: ContentBlock[];

  constructor(id: string, slug: string, text: any, title: string, navigationTitle: string, seoDescription: string, headerImage?: ImageInfo, headerImageMobile?: ImageInfo, contentBlocks?: ContentBlock[]) {
    this.id = id;
    this.slug = slug;
    this.text = text;
    this.title = title;
    this.navigationTitle = navigationTitle;
    this.seoDescription = seoDescription;
    this.headerImage = headerImage;
    this.headerImageMobile = headerImageMobile;
    this.contentBlocks = contentBlocks;
  }
}
