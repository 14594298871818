import React from 'react';
import classNames from 'classnames';

import IpuroLink from '../IpuroLink/IpuroLink';

import FaqItem from '../../entities/FaqItem';
import RichText from "../RichText";

import styles from './FaqList.module.scss';

interface Props {
    faqItems?: FaqItem[]
}

const FaqList: React.SFC<Props> =({faqItems}) => {
    const listItemClassName = classNames('columns', {
        [styles.FaqList__listItem]: true,
    });

    if (!faqItems || faqItems.length === 0) return null;

    const hasLink = (faqItem) => faqItem.linkLabel && faqItem.linkUrl;

    return (
        <div className={styles.FaqList}>
            {faqItems.map((faqItem) => {
                return (
                    <div className={listItemClassName} key={faqItem.sorting}>
                        <div className='column is-5 is-offset-1'>
                            <h1 className={styles.FaqList__question}>
                                #{faqItem.sorting} {faqItem.question}
                            </h1>
                        </div>
                        <div className='column is-5'>
                            <RichText className={styles.FaqList__answer} text={faqItem.answer}/>
                            {hasLink(faqItem) &&
                                <IpuroLink to={faqItem.linkUrl}>
                                    {faqItem.linkLabel}
                                </IpuroLink>
                            }
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default FaqList;
