import React from "react";

import LanguageSwitch from "../../widgets/LanguageSwitch";
import TopBarSearch from "../TopBarSearch/TopBarSearch";
import TopBarItem from "../TopBarItem/TopBarItem";

import styles from "./TopBar.module.scss";

interface Props {
  t: Function;
  history: object;
  staticPagesPaths: object;
  locale: string;
  onLocaleChange: (locale: string, newPath: string) => void;
}

const TopBar: React.SFC<Props> = ({
  t,
  locale,
  history,
  onLocaleChange,
  staticPagesPaths,
}) => {
  const searchLabel = t("search.label");
  const searchPlaceholder = t("search.placeholder");
  const searchRoute = staticPagesPaths["search"];

  const faqLabel = t("topBar.faq.label");
  const faqRoute = staticPagesPaths["faq"];
  const storeFinderRoute = staticPagesPaths["storefinder"];
  const storeFinderLabel = t("topBar.storeFinder.label");

  return (
    <div className={styles.TopBar}>
      <TopBarSearch
        label={searchLabel}
        placeholder={searchPlaceholder}
        url={searchRoute ? searchRoute : "/"}
        history={history}
      />
      <TopBarItem key={faqLabel} link={faqRoute ? faqRoute : "/"}>
        {faqLabel}
      </TopBarItem>
      <TopBarItem
        key={storeFinderLabel}
        link={storeFinderRoute ? storeFinderRoute : "/"}
      >
        {storeFinderLabel}
      </TopBarItem>
      <LanguageSwitch selected={locale} onChange={onLocaleChange} />
    </div>
  );
};

export default TopBar;
