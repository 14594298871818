import * as React from 'react';
import Headline from '../Headline/Headline';

import { Link } from 'react-router-dom';

import styles from './SubjectTeaser.module.scss';
import { Image } from '../Image/Image';
import ImageInfo from '../../entities/ImageInfo';

interface Props {
  image: ImageInfo,
  title: string,
  copy?: string,
  url?: string | null,
}

const SubjectTeaser: React.SFC<Props> = ({image, title, copy, url}) => {

  const getContent = () => {
    return (
      <div className={styles.wrapper}>
        <Image className={styles.image} image={image} />
        <Headline component="h4">{title}</Headline>
        { copy && <p className={styles.copy}>{copy}</p> }
      </div>
    );
  }

  if (url) {
    return (
      <Link to={url} >
        {getContent()}
      </Link>
    );
  }

  return getContent();
};

export default SubjectTeaser;
