import Price from "../entities/Price";

export default class Translations {
  public readonly entries: Map<string, string>;
  private locale: string;

  constructor(entries: Map<string, string>, locale) {
    this.entries = entries;
    this.locale = locale.toUpperCase();
  }

  public getT(): (key: string, replacements?: {[k: string]: string}) => string {
    return (key, replacements?) => {
      let translation = this.entries.get(key);
      if (!translation) {
        return key;
      }
      // @todo proper i18n
      if (replacements) {
        for (let k in replacements) {
          const v = replacements[k];
          translation = translation.replace(`{{${k}}}`, v);
        }
      }
      return translation;
    };
  }

  public formatPrice(price: Price): string {
    const str = `${price.amount} ${price.currency}`;
    switch(this.locale) {
      case "DE":
        return str.replace(".", ",");
      default: // EN
        return str;
    }
  }

  public setLocale(locale: string): void {
    this.locale = locale;
  }
}
