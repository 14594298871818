import * as React from "react";
import classNames from "classnames";

export interface Props {
  className?: string;
  style?: string;
}

export interface State {}

class Accordeon extends React.Component<Props, State> {
  public render() {
    const { children, className, style } = this.props;
    const accordeonClass = classNames(className, style);

    return <div className={accordeonClass}>{children}</div>;
  }
}

export default Accordeon;
