import ImageInfo from "./ImageInfo";

export default class Topic {
  public readonly id: string;
  public readonly title: string;
  public readonly text?: string;
  public readonly image: ImageInfo;
  public readonly path?: string;

  constructor(id: string, title: string, text: string|undefined, image: ImageInfo, path: string|undefined) {
    this.id = id;
    this.title = title;
    this.text = text;
    this.image = image;
    this.path = path;
  }
}
