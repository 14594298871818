import React from 'react';
import Navigation from '../../widgets/Navigation';
import Logo from '../../components/Logo/Logo';
import styles from './Header.module.scss';
import BurgerMenu from '../../widgets/BurgerMenu';
import classNames from 'classnames';

interface Props {
  locale: string;
  onLocaleChange: any;
}

export interface State {
  isNaviOpen: boolean;
}

class Header extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      isNaviOpen: false,
    };
  }

  toggleNavigation = () => {
    this.setState({isNaviOpen: !this.state.isNaviOpen});
  }

  closeNavigation = () => {
    if (this.state.isNaviOpen) {
      this.setState({isNaviOpen: false});
    }
  }

  public render() {
    const {locale, onLocaleChange} = this.props;
    const {isNaviOpen} = this.state;
    const wrapperClassName = classNames({
      [styles.wrapper]: true,
    });

    return (
      <div className={wrapperClassName}>
        <Logo locale={locale}/>
        <BurgerMenu onClick={this.toggleNavigation} isNaviOpen={isNaviOpen}/>
        <Navigation locale={locale} isNaviOpen={isNaviOpen} callback={this.closeNavigation} onLocaleChange={onLocaleChange}/>
      </div>
    );
  }
}

export default Header;
