import * as React from "react";
import { NavLink } from "react-router-dom";
import ProductCategory from "../entities/ProductCategory";
import styles from "./NavigationItem.module.scss";
import navStyles from "../components/ProductNavigation/ContentItems/ContentItems.module.scss";
import PathHelper from "../services/Helper";
// import Product from '../entities/Product';
import ProductLine from "../entities/ProductLine";
import Icon from "../components/Icon/Icon";
import classNames from "classnames";
// import ProductSubnavigation from '../components/ProductNavigation/ProductSubnavigation';
import Scrollspy from "react-scrollspy";
import { HashLink as Link } from "react-router-hash-link";

interface Props {
  productLine: ProductLine;
  locale: string;
  onClick?: () => void;
}

interface State {
  isOpen: boolean;
}

class NavigationItem extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.toggleNav);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.toggleNav);
  }

  toggleNav = () => {
    if (window.pageYOffset >= 150) {
      this.setState({ isOpen: true });
    } else {
      this.setState({ isOpen: false });
    }
  };

  // private renderProduct(product: Product, baseSlug: string): JSX.Element {
  //   const {locale} = this.props;
  //   const slug = `${baseSlug}/${product.slug}`;

  //   return (
  //     <li key={product.id}>
  //       <NavLink className={styles.navItemLink} to={PathHelper.pathFromSlug(slug, locale)}>
  //         {product.title}
  //       </NavLink>
  //     </li>
  //   );
  // }

  scrollWithOffset = (el, offset) => {
    const elementPosition = el.offsetTop - offset;
    window.scroll({ behavior: "smooth", top: elementPosition });
    // el.scrollIntoView({ behavior: 'smooth'});
    //window.scrollBy(0, offset);
  };

  handleToggleClick = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  private renderProductCategory(
    category: ProductCategory,
    baseSlug: string
  ): JSX.Element {
    const { locale, onClick /* productLine */ } = this.props;
    const slug = `${baseSlug}/${category.slug}`;
    const pathname = window.location.pathname.split("/");
    const activeProductCategory = pathname[pathname.length - 1];
    const isActiveProductCategory = activeProductCategory === category.slug;

    const expandableClassName = classNames({
      [navStyles.ContentItems__expandable]: true,
      "is-hidden-touch": true,
      [navStyles.ContentItems__expandable___expanded]: isActiveProductCategory,
    });

    const secondaryClassName = classNames({
      [navStyles.ContentItems__secondary]: true,
      [navStyles.ContentItems__secondary___active]: false,
      [navStyles.ContentItems__secondary___white]: false,
    });

    const products = {};

    if (category.products && category.products.length) {
      category.products.forEach((product) => {
        products[product.type.identifier] = product.type;
      });
    }

    return (
      <li className={styles.productCategory} key={category.id}>
        <NavLink
          activeClassName={styles.navItemLinkActive}
          onClick={onClick}
          className={styles.navItemLink}
          to={PathHelper.pathFromSlug(slug, locale)}
        >
          <p>{category.title}</p>
          {isActiveProductCategory && (
            <div
              className={expandableClassName}
              style={{ marginTop: 8, textAlign: "left" }}
            >
              <Scrollspy
                items={Object.keys(products).map((key) => key)}
                offset={-400}
                currentClassName={styles.current}
                componentTag="div"
              >
                {Object.keys(products).map((key) => {
                  const item = products[key];
                  let url = `${activeProductCategory}#${key}`;

                  return (
                    <Link
                      key={item.listTitle}
                      className={secondaryClassName}
                      to={url}
                      scroll={(el) => this.scrollWithOffset(el, 0)}
                      style={{ borderBottom: "none", textAlign: "left" }}
                    >
                      {item.listTitle}
                    </Link>
                  );
                })}
              </Scrollspy>
            </div>
          )}
        </NavLink>
      </li>
    );
  }

  public render() {
    const {
      productLine: { id, title, slug, categories },
      locale,
      onClick,
    } = this.props;
    const pathname = window.location.pathname.split("/");
    const activeProductCategory = pathname[pathname.length - 2];
    const isActiveProductCategory = activeProductCategory === slug;

    const navItemClassName = classNames(styles.navItem, {
      [styles.isActive]: this.state.isOpen && isActiveProductCategory,
    });
    return (
      <li className={navItemClassName} key={id}>
        <span className={styles.navItemLinkWrapper}>
          <NavLink
            activeClassName={styles.navItemLinkActive}
            onClick={onClick}
            className={styles.navItemLink}
            to={PathHelper.pathFromSlug(slug, locale)}
          >
            {title}
          </NavLink>
          <Icon
            className={styles.navItemToggler}
            icon="chevronRight"
            size="small"
            onClick={this.handleToggleClick}
          />
        </span>
        <div className={styles.dropdown}>
          <ul className={styles.catergoryList}>
            {categories.map((category) =>
              this.renderProductCategory(category, slug)
            )}
          </ul>
        </div>
      </li>
    );
  }
}

export default NavigationItem;
