import React, {ReactNode} from 'react';
import classNames from 'classnames';

import styles from './CompositionTeaser.module.scss';
import Headline from '../Headline/Headline';
import ImageInfo from '../../entities/ImageInfo';
import { Image } from '../Image/Image';

interface Props {
    headline: string,
    subHeadline: string,
    largeImage: ImageInfo | undefined,
    smallImage: ImageInfo | undefined,
    reversePosition?: boolean,
    children?: ReactNode,
}

const CompositionTeaser: React.SFC<Props> =({headline, subHeadline, largeImage, smallImage, reversePosition, children}) => {
    const className = classNames({
        [styles.CompositionTeaser]: true,
    });

    const columnsClassName = classNames('columns', {
        [styles.CompositionTeaser__columns___reversed]: reversePosition,
    });

    const mixedColumnclassName = classNames('column', 'is-3',{
        [styles.CompositionTeaser__mixedColumn]: true,
        'is-offset-1': reversePosition,

    });

    const largeImageColumnclassName = classNames('column', 'is-5', {
        'is-offset-1': !reversePosition,
        [styles.CompositionTeaser__centerImage]: true,
    });

    const optionalColumnclassName = classNames('column', 'is-3',{
        [styles.CompositionTeaser__optionalColumn]: true,
    });

    return (
        <div className={className}>
            <div className={columnsClassName}>
                {children && reversePosition &&
                    <div className={optionalColumnclassName}>
                        {children}
                    </div>
                }
                <div className={largeImageColumnclassName}>
                    {largeImage && <Image image={largeImage} className={styles.CompositionTeaser__centerImage_image}/>}
                </div>
                <div className={mixedColumnclassName}>
                    <div className={styles.CompositionTeaser__headlines}>
                        <div className={styles.CompositionTeaser__headlines__subheadline}>
                            {subHeadline}
                        </div>
                        <Headline component="h1" variant="h3" className={styles.CompositionTeaser__headlines__mainheadline}>
                            {headline}
                        </Headline>
                    </div>
                    {smallImage && <Image image={smallImage} />}
                </div>
                {children && !reversePosition &&
                    <div className={optionalColumnclassName}>
                        {children}
                    </div>
                }
            </div>
        </div>
    );
};

export default CompositionTeaser;
