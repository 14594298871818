import RouteRegistry from "../services/RouteRegistry";
import ImageInfo from "./ImageInfo";
import ProductCategory from "./ProductCategory";
import WithAlternates from "./WithAlternates";

export default class ProductLine implements WithAlternates {
  public readonly id: string;
  public readonly slug: string;
  public readonly title: string;
  public readonly description: any;
  public readonly categories: ProductCategory[];
  public readonly alternates: { [locale: string]: string };
  public readonly seoDescription: string;
  public readonly isNew: boolean;
  public readonly isOnSale: boolean;
  public readonly limited: boolean;
  public readonly image?: ImageInfo;
  public readonly imageMobile?: ImageInfo;
  public readonly fallbackImage?: ImageInfo;
  public readonly hasDarkHeaderImage: boolean;

  constructor(
    id: string,
    slug: string,
    title: string,
    description: any,
    alternates: { [locale: string]: string },
    seoDescription: string,
    isNew: boolean,
    isOnSale: boolean,
    limited: boolean,
    hasDarkHeaderImage: boolean,
    image: ImageInfo | undefined,
    imageMobile: ImageInfo | undefined,
    fallbackImage: ImageInfo | undefined
  ) {
    this.id = id;
    this.slug = slug;
    this.title = title;
    this.description = description;
    this.categories = [];
    this.alternates = alternates;
    this.seoDescription = seoDescription;
    this.isNew = isNew;
    this.isOnSale = isOnSale;
    this.limited = limited;
    this.image = image;
    this.imageMobile = imageMobile;
    this.fallbackImage = fallbackImage;
    this.hasDarkHeaderImage = hasDarkHeaderImage;
  }

  public addCategories(categories: ProductCategory[]) {
    categories.forEach(category => this.categories.push(category));
  }

  public getAlternatePaths(): { locale: string; path: string }[] {
    const paths: { locale: string; path: string }[] = [];
    Object.keys(this.alternates).forEach(locale => {
      const path = RouteRegistry.productLineAlternatePath(this, locale);
      if (path) {
        paths.push({ locale, path });
      }
    });
    return paths;
  }
}
