export default class LegalItem {
  public readonly id;
  public readonly headline: string;
  public readonly nameDoNotChange: string;
  public readonly content: string;
  public readonly readMoreHeadline: string;
  public readonly links: object;

  constructor(id, headline: string, nameDoNotChange: string, content: string, readMoreHeadline: string, links: object) {
    this.id = id;
    this.headline = headline;
    this.nameDoNotChange = nameDoNotChange;
    this.content = content;
    this.readMoreHeadline = readMoreHeadline;
    this.links = links;
  }
}
