/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import classNames from "classnames";
import ReactSVG from "react-svg";

import CheckboxBlankSrc from "../../icons/baseline-check_box_outline_blank-24px.svg";
import ChevronRightSrc from "../../icons/baseline-chevron_right-24px.svg";
import CheckboxCheckedSrc from "../../icons/baseline-check_box-24px.svg";
import ChevronLeftSrc from "../../icons/baseline-chevron_left-24px.svg";
import ExpandDownSrc from "../../icons/baseline-expand_down-24px.svg";
import MailIconSrc from "../../icons/baseline-mail_outline-24px.svg";
import ExpandUpSrc from "../../icons/baseline-expand_up-24px.svg";
import SearchIconSrc from "../../icons/baseline-search-24px.svg";
import MinusIconSrc from "../../icons/baseline-minus-24px.svg";
import PhoneIconSrc from "../../icons/baseline-call-24px.svg";
import PlusIconSrc from "../../icons/baseline-plus-24px.svg";
import CloseIconSrc from "../../icons/baseline-x-24px.svg";
import FacebookIconSrc from "../../icons/facebook.svg";
import InstagramIconSrc from "../../icons/instagram.svg";

import styles from "./Icon.module.scss";

interface Props {
  icon: string;
  size?: string;
  className?: string;
  style?: object;
  onClick?: any;
}

const Icon: React.SFC<Props> = ({
  icon,
  size = "medium",
  className,
  style,
  onClick,
}) => {
  const iconClassName = classNames(className, {
    [styles.Icon]: true,
    [styles.Icon_xxSmall]: size === "xx-small",
    [styles.Icon_xSmall]: size === "x-small",
    [styles.Icon_smaller]: size === "smaller",
    [styles.Icon_small]: size === "small",
    [styles.Icon_medium]: size === "medium",
    [styles.Icon_large]: size === "large",
    [styles.Icon_xLarge]: size === "x-large",
    [styles.Icon_xxLarge]: size === "xx-large",
  });

  let iconSrc;

  switch (icon) {
    case "checkboxBlank":
      iconSrc = CheckboxBlankSrc;
      break;
    case "checkboxChecked":
      iconSrc = CheckboxCheckedSrc;
      break;
    case "chevronLeft":
      iconSrc = ChevronLeftSrc;
      break;
    case "chevronRight":
      iconSrc = ChevronRightSrc;
      break;
    case "expandDown":
      iconSrc = ExpandDownSrc;
      break;
    case "expandUp":
      iconSrc = ExpandUpSrc;
      break;
    case "plus":
      iconSrc = PlusIconSrc;
      break;
    case "minus":
      iconSrc = MinusIconSrc;
      break;
    case "search":
      iconSrc = SearchIconSrc;
      break;
    case "close":
      iconSrc = CloseIconSrc;
      break;
    case "phone":
      iconSrc = PhoneIconSrc;
      break;
    case "mail":
      iconSrc = MailIconSrc;
      break;
    case "facebook":
      iconSrc = FacebookIconSrc;
      break;
    case "instagram":
      iconSrc = InstagramIconSrc;
      break;
    default:
      break;
  }

  if (onClick) {
    return (
      <a onClick={onClick}>
        <ReactSVG svgClassName={iconClassName} src={iconSrc} svgStyle={style} />
      </a>
    );
  }

  return (
    <ReactSVG svgClassName={iconClassName} src={iconSrc} svgStyle={style} />
  );
};

export default Icon;
