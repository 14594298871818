import RouteRegistry from "../services/RouteRegistry";
import ImageInfo from "./ImageInfo";
import Price from "./Price";
import ProductCategory from "./ProductCategory";
import ProductDetails from "./ProductDetails";
import ProductType from "./ProductType";
import ProductVariant from "./ProductVariant";
import Scent from "./Scent";
import WithAlternates from "./WithAlternates";

export default class Product implements WithAlternates {
  public readonly id: string;
  public readonly slug: string;
  public readonly title: string;
  public readonly description: any; // @todo rich text
  public readonly previewImage: ImageInfo;
  public readonly moodImageSmall: ImageInfo;
  public readonly images: ImageInfo[];
  public readonly moodImage?: ImageInfo;
  public readonly category: ProductCategory;
  public readonly scents: Scent[];
  public readonly type: ProductType;
  public readonly similarProductIds: string[];
  public readonly alternates: { [locale: string]: string };
  public readonly seoDescription: string;
  public readonly details: ProductDetails[];
  public readonly variants: ProductVariant[];
  public readonly link?: string;
  public readonly linkType: string;
  public readonly price?: Price;
  public readonly isNew: boolean;
  public readonly isOnSale: boolean;
  public readonly limited: boolean;
  public readonly useSmallGrid: boolean;
  public readonly basicPrice?: Price;

  constructor(
    id: string,
    slug: string,
    title: string,
    description: string,
    previewImage: ImageInfo,
    images: ImageInfo[],
    moodImage: ImageInfo | undefined,
    category: ProductCategory,
    scents: Scent[],
    type: ProductType,
    similarProductIds: string[],
    alternates: { [locale: string]: string },
    seoDescription: string,
    details: ProductDetails[],
    link: string | undefined,
    linkType: string,
    price: Price | undefined,
    isNew: boolean,
    isOnSale: boolean,
    limited: boolean,
    useSmallGrid: boolean,
    basicPrice: Price
  ) {
    this.id = id;
    this.slug = slug;
    this.title = title || "";
    this.description = description;
    this.previewImage = previewImage;
    this.moodImageSmall = previewImage;
    this.images = images;
    this.moodImage = moodImage;
    this.category = category;
    this.scents = scents;
    this.type = type;
    this.similarProductIds = similarProductIds;
    this.alternates = alternates;
    this.seoDescription = seoDescription;
    this.variants = [];
    this.details = details;
    this.link = link;
    this.linkType = linkType;
    this.price = price;
    this.isNew = isNew;
    this.isOnSale = isOnSale;
    this.limited = limited;
    this.useSmallGrid = useSmallGrid;
    this.basicPrice = basicPrice;
  }

  public addVariants(variants: ProductVariant[]): void {
    variants.forEach((variant) => this.variants.push(variant));
  }

  public getAlternatePaths(): { locale: string; path: string }[] {
    const paths: { locale: string; path: string }[] = [];
    Object.keys(this.alternates).forEach((locale) => {
      const path = RouteRegistry.productAlternatePath(this, locale);
      if (path) {
        paths.push({ locale, path });
      }
    });
    return paths;
  }
}
