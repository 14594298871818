/* eslint-disable array-callback-return */
import { ContentfulClientApi, Entry, EntryCollection } from "contentful";
import FaqItem from "../../entities/FaqItem";
import PartnerShop from "../../entities/PartnerShop";
import PageData from "../../entities/PageData";
import ProductCatalog from "../../entities/ProductCatalog";
import RouteInfo from "../../entities/RouteInfo";
import Store from "../../entities/Store";
import Topic from "../../entities/Topic";
import ContentService from "../ContentService";
import Translations from "../Translations";
import ContentTypeMapper from "./ContentTypeMapper";
import LegalItem from "../../entities/LegalItem";

export default class ContentfulContentService implements ContentService {
  private readonly client: ContentfulClientApi;
  private baseSlug: string;
  private locale: string;
  private mapper: ContentTypeMapper;

  constructor(client: ContentfulClientApi, locale: string) {
    this.client = client;
    this.locale = locale;
    this.baseSlug = "";
    this.mapper = new ContentTypeMapper();
  }

  public setLocale(locale: string): void {
    this.locale = locale;
  }

  public getLocale(): string {
    return this.locale;
  }

  public setBaseSlug(slug: string): void {
    this.baseSlug = slug;
  }

  public getLocales(): Promise<string[]> {
    return this.client
      .getLocales()
      .then((collection) => collection.items.map((locale) => locale.code));
  }

  public getAllRoutes(): Promise<RouteInfo[]> {
    return this.client.getLocales().then((collection) => {
      const promises = collection.items.map((locale) =>
        this.client.getEntries({
          content_type: "page",
          include: 0,
          locale: locale.code,
          select:
            "sys,fields.slug,fields.identifier,fields.navigationTitle,fields.title",
          limit: 1000,
        })
      );
      return Promise.all(promises).then((promise) => {
        return promise
          .map((collection) => collection.items.map(this.mapper.routeInfo))
          .reduce((prev, cur) => prev.concat(cur));
      });
    });
  }

  public getPage(id: string, locale?: string): Promise<PageData> {
    return this.client
      .getEntry(id, {
        content_type: "page",
        include: 3,
        locale: locale || this.locale,
      })
      .then(this.mapper.page);
  }

  public getPageByIdentifier(
    identifier: string,
    locale?: string
  ): Promise<PageData | undefined> {
    return this.client
      .getEntries({
        content_type: "page",
        include: 3,
        locale: locale || this.locale,
        "fields.identifier": identifier,
        limit: 1,
      })
      .then((collection) =>
        collection && collection.items.length > 0
          ? this.mapper.page(collection.items[0])
          : undefined
      );
  }

  /**
   * If we get too many products, loading alternates could be deferred until a ProductLine, ProductCategory or Product is shown.
   * For now this is fine and makes switching between products snappier.
   */
  public getProductCatalog(loadAlternates: string[]): Promise<ProductCatalog> {
    const promises: Promise<
      { id: string; locale: string; slug: string; type: string }[]
    >[] = [];
    loadAlternates.forEach((locale) => {
      const add = (type, select = "sys,fields.slug") =>
        promises.push(
          this.client
            .getEntries({
              content_type: type,
              locale,
              select,
              limit: 1000,
            })
            .then((collection) =>
              (collection.items || []).map((entry: Entry<any>) => {
                const {
                  sys: { id },
                  fields,
                } = entry;
                const slug = fields?.slug || fields?.type.fields.slug;
                return { id, locale, slug, type };
              })
            )
        );
      add("productLine");
      add("productCategory");
      add("product");
      add("productVariant", "sys,fields.type");
    });
    return this.client
      .getEntries({
        content_type: "productLine",
        include: 4,
        locale: this.locale,
        limit: 1000,
        order: "fields.sorting",
      })
      .then((collection) => {
        const alternates: {
          [type: string]: { [id: string]: { [locale: string]: string } };
        } = {};
        return Promise.all(promises).then((pro) => {
          pro.map((arr) =>
            arr.map(({ type, id, locale, slug }) => {
              const byType = alternates[type] || {};
              const byId = byType[id] || {};
              byId[locale] = slug;
              byType[id] = byId;
              alternates[type] = byType;
            })
          );
          return new ProductCatalog(
            collection.items.map((entry) =>
              this.mapper.productLine(entry, alternates)
            ),
            this.baseSlug,
            this.locale
          );
        });
      });
  }

  public getTranslations(): Promise<Translations> {
    return this.client
      .getEntries({
        content_type: "translation",
        locale: this.locale,
        limit: 1000,
      })
      .then((collection) => this.mapper.translations(collection, this.locale));
  }

  public getAllStores(): Promise<Store[]> {
    return this.moreStores(1000, 0, []);
  }

  public getAllFaqItems(): Promise<FaqItem[]> {
    return this.client
      .getEntries({
        content_type: "faq",
        locale: this.locale,
        limit: 1000,
        order: "fields.sorting",
      })
      .then((collection) => collection.items.map(this.mapper.faqitem));
  }

  public getAllLegalItems(): Promise<LegalItem[]> {
    return this.client
      .getEntries({
        content_type: "legal",
        locale: this.locale,
        limit: 1000,
      })
      .then((collection) => collection.items.map(this.mapper.legalitem));
  }

  public getAllPartnerShops(): Promise<PartnerShop[]> {
    return this.client
      .getEntries({
        content_type: "partnerShop",
        locale: this.locale,
        limit: 1000,
        order: "fields.sorting",
      })
      .then((collection) => collection.items.map(this.mapper.partnerShop));
  }

  public getTopics(blockId: string): Promise<Topic[]> {
    return this.client
      .getEntries({
        content_type: "topicBlock",
        locale: this.locale,
        "fields.identifier": blockId,
        limit: 1,
        include: 2,
      })
      .then((collection: EntryCollection<any>) => {
        if (collection.items.length === 0) {
          return [];
        }
        return collection.items[0].fields.topics.map(this.mapper.topic);
      });
  }

  private moreStores(
    limit: number,
    offset: number,
    stores: Store[]
  ): Promise<Store[]> {
    return this.client
      .getEntries({
        content_type: "store",
        locale: this.locale,
        limit,
        skip: offset,
        order: "sys.createdAt",
      })
      .then((collection) => {
        const additional = collection.items.map(this.mapper.store);
        const result = stores.concat(additional);
        return additional.length >= limit
          ? this.moreStores(limit, offset + limit, result)
          : result;
      });
  }
}
