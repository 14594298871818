import React from "react";
import ReactSVG from "react-svg";
import LogoSrc from "../../logo/ipuro_logo_sw_registered.svg";
import styles from "./Logo.module.scss";
import classNames from "classnames";
import { Link } from "react-router-dom";

interface Props {
  locale: string;
}

const Logo: React.SFC<Props> = ({ locale }) => {
  const className = classNames(styles.Logo);

  return (
    <Link to={"/" + locale}>
      <ReactSVG
        svgClassName={className}
        src={LogoSrc}
        style={{ background: "white" }}
      />
    </Link>
  );
};

export default Logo;
