import * as React from "react";
import { Component } from "react";
import PathHelper from "../services/Helper";
import RouteRegistry from "../services/RouteRegistry";
import ServiceRegistry from "../services/ServiceRegistry";

import styles from "./LanguageSwitch.module.scss";

export interface Props {
  selected: string;
  onChange: (locale: string, newPath: string) => void;
}

interface State {
  selected: string;
}

export default class LanguageSwitch extends Component<Props, State> {
  private readonly routeRegistry: RouteRegistry;

  constructor(props: Props, context: any) {
    super(props, context);

    this.routeRegistry = ServiceRegistry.getRouteRegistry();

    this.state = {
      selected: props.selected,
    };

    this.onChange = this.onChange.bind(this);
  }

  public render(): JSX.Element {
    const { selected } = this.state;

    const locales = this.routeRegistry.getLocales();

    // TODO: not working on frontpage

    const choices = locales.map((locale) => (
      <option key={locale} value={locale}>
        {locale}
      </option>
    ));

    return (
      <div className={styles.LanguageSwitch__wrapper}>
        <select
          onChange={(event) => this.onChange(event.target.value)}
          value={selected}
          className={styles.LanguageSwitch}
        >
          {choices}
        </select>
      </div>
    );
  }

  private onChange(selected: string): void {
    if (this.state.selected === selected) {
      return;
    }

    let newPath = this.routeRegistry.translatePath(
      window.location.pathname,
      selected
    );
    if (!newPath) {
      newPath = PathHelper.pathFromSlug("/", selected);
    }
    this.setState({ selected });
    this.props.onChange(selected, newPath);
  }
}
