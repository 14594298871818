import React, { ReactNode } from "react";
import classNames from "classnames";

import styles from "./HeroTeaser.module.scss";
import ImageInfo from "../../entities/ImageInfo";
import HeroTeaserMedia from "./HeroTeaserMedia";

interface Props {
  image: ImageInfo;
  imageMobile?: ImageInfo;
  fallbackImage?: ImageInfo;
  headline?: string;
  children?: ReactNode;
  onLandingPage?: boolean;
}

const HeroTeaser: React.SFC<Props> = ({
  headline,
  image,
  imageMobile,
  fallbackImage,
  children,
  onLandingPage
}) => {
  const className = classNames({
    [styles.HeroTeaser]: true,
    [styles.HeroTeaser___onLandingPage]: onLandingPage
  });

  return (
    <div className={className}>
      <HeroTeaserMedia image={image} imageMobile={imageMobile} fallbackImage={fallbackImage} />
      {headline && (
        <div className={styles.HeroTeaser__headlineWrapper}>
          <div className="columns">
            <div className="column is-12-mobile is-12-desktop">
              <h1 className={styles.HeroTeaser__headline}>{headline}</h1>
            </div>
          </div>
        </div>
      )}
      {children && children}
    </div>
  );
};

export default HeroTeaser;
