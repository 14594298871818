import React from 'react';
import classNames from 'classnames';

import ServiceRegistry from '../../services/ServiceRegistry';
import IpuroLink from '../IpuroLink/IpuroLink';

import styles from './LegalLinkList.module.scss';

interface Props {
    links: object,
    headline: string,
    locale: string,
}

const LegalLinkList: React.SFC<Props> =({headline, links, locale}) => {
    const className = classNames({
        [styles.LegalLinkList]: true,
    });

    const routeRegistry = ServiceRegistry.getRouteRegistry();

    const fallBackRoute = {
        path: '',
        navigationTitle: '',
    };

    return (
        <div className={className}>
            {headline &&
                <h2 className={styles.LegalLinkList__headline}>
                    {headline}
                </h2>
            }

            {Object.keys(links).map((key) => {
                const link = routeRegistry.getByIdentifierAndLocale(key, locale) || fallBackRoute;

                return (
                    <IpuroLink
                        key={link.navigationTitle}
                        to={link.path}
                        type={'primary'}
                        additionalClassName={styles.LegalLinkList__link}
                    >
                        {link.navigationTitle}
                    </IpuroLink>
                );
            })}
        </div>
    );
};

export default LegalLinkList;
