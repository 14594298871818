import classNames from "classnames";
import { Map, Marker } from "google-maps-react";
import * as React from "react";
import Button from "../components/Button/Button";
import ClosestStoreResult from "../entities/ClosestStoreResult";
import GeoLocation from "../entities/GeoLocation";
import GoogleMapsMarker from "../icons/marker.svg";
import ServiceRegistry from "../services/ServiceRegistry";
import theme from "../util/GoogleMapsTheme";
import styles from "./MapContainer.module.scss";

export interface Props {
  stores?: ClosestStoreResult[] | undefined;
  location?: GeoLocation;
  defaultActiveStore?: ClosestStoreResult | undefined;
  allStores?: ClosestStoreResult[] | undefined;
}

export interface State {
  showingInfoWindow: boolean;
  activeStore: ClosestStoreResult | undefined;
  mapCenter: object;
  zoom: number;
  isAllStoresVisible: boolean;
}

// needed to use google propety on window object
declare global {
  interface Window {
    google: any;
  }
}
window.google = window.google || {};

const DEFAULT_MAP_CENTER = {
  lat: 53.550341,
  lng: 10.000654,
};

const DEFAULT_MAP_ZOOM = 4;

export class MapContainer extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showingInfoWindow: false, //Hides or the shows the infoWindow
      activeStore: this.props.defaultActiveStore, //Shows the active marker upon click
      mapCenter: DEFAULT_MAP_CENTER,
      zoom: DEFAULT_MAP_ZOOM,
      isAllStoresVisible: false,
    };

    this.handleResultItemClick = this.handleResultItemClick.bind(this);
    this.showAllStores = this.showAllStores.bind(this);
  }

  onMarkerClick = (props, marker, e) => {
    const { position, google } = props;

    // var latLng = new props.google.maps.LatLng(53.550341, 10.000654); //Makes a latlng
    var latLng = new google.maps.LatLng(position.lat, position.lng); //Makes a latlng
    props.map.panTo(latLng); //Make map global

    // this.setState({
    //   activeStore: marker,
    //   showingInfoWindow: true
    // });
  };

  onClose = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeStore: undefined,
      });
    }
  };

  handleResultItemClick = (store) => {
    this.setState(
      {
        activeStore: store,
      }
      // this.moveMapToStore
    );
  };

  moveMapToStore = () => {
    // this.mapReference && this.mapReference.panTo({
    //     lat: 42.39,
    //     lng: -72.52
    // });
    // if (!this.state.activeStore || !this.state.activeStore.store || !this.state.activeStore.store.geoLocation) return;
    // const { geoLocation: {longitude, latitude} } = this.state.activeStore.store;
    // const mapCenter = {
    //   lat: latitude,
    //   lng: longitude,
    // }
    // this.setState({
    //   mapCenter,
    //   zoom: 14
    // });
  };

  resetMap = () => {
    this.setState({
      mapCenter: DEFAULT_MAP_CENTER,
      zoom: DEFAULT_MAP_ZOOM,
    });
  };

  renderResultItem = (item: ClosestStoreResult) => {
    const t = ServiceRegistry.getTranslations().getT();
    const { store, distance } = item;
    const {
      geoLocation = { longitude: undefined, latitude: undefined },
    } = store;
    const { longitude, latitude } = geoLocation;
    const { location } = this.props;
    const displayDistance = `${Math.round((distance / 1000) * 100) / 100} km`;

    const directionLink =
      longitude && latitude && location ? (
        <a
          className={styles.link}
          href={`https://www.google.com/maps/dir/?api=1&origin=${location.latitude},${location.longitude}&destination=${latitude},${longitude}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("StoreFinder.showDirection")}
        </a>
      ) : null;

    return (
      <li
        key={store.id}
        className={styles.result}
        onClick={() => this.handleResultItemClick(item)}
      >
        <span className={styles.itemHeadline}>
          <span>{store.title}</span>
          <span>{displayDistance}</span>
        </span>
        <address>
          {store.addressAddition && (
            <>
              {store.addressAddition}
              <br />
            </>
          )}
          {store.street}
          <br />
          {`${store.zipCode} ${store.city}`}
        </address>
        {store.openingTimes && (
          <div>
            Öffnungszeiten
            <br />
            {store.openingTimes.map((time, i) => (
              <div key={i}>
                {time}
                <br />
              </div>
            ))}
          </div>
        )}
        {store.tags && (
          <div className={styles.tags}>
            {store.tags.map((tag, i) => (
              <span className={styles.tag} key={i}>
                {tag}
              </span>
            ))}
          </div>
        )}
        {directionLink}
      </li>
    );
  };

  private renderResults() {
    const { stores, allStores } = this.props;
    const { isAllStoresVisible } = this.state;
    const t = ServiceRegistry.getTranslations().getT();

    if (!stores) {
      return null;
    }

    return (
      <>
        <ul>
          {isAllStoresVisible &&
            allStores &&
            allStores.map(this.renderResultItem)}
          {!isAllStoresVisible && stores && stores.map(this.renderResultItem)}
        </ul>
        {!isAllStoresVisible && (
          <Button onClick={this.showAllStores}>
            {t("StoreFinder.showAllShop")}
          </Button>
        )}
      </>
    );
  }

  showAllStores(e: React.SyntheticEvent) {
    e.preventDefault();

    this.setState({
      isAllStoresVisible: true,
    });
  }

  onMapReady(mapProps, map) {
    const styledMapType = new mapProps.google.maps.StyledMapType(theme);

    map.mapTypes.set("styled_map", styledMapType);
    map.setMapTypeId("styled_map");
  }

  renderStoresMarker() {
    const { stores } = this.props;

    if (!stores) return null;

    return stores.map((storeObj) => {
      const {
        store: { title, geoLocation, id },
      } = storeObj;

      return (
        geoLocation && (
          <Marker
            key={id}
            onClick={this.onMarkerClick}
            name={title}
            title={title}
            icon={GoogleMapsMarker}
            position={{ lat: geoLocation.latitude, lng: geoLocation.longitude }}
          />
        )
      );
    });
  }

  async getAllStores() {
    const allStores = ServiceRegistry.getContentService().getAllStores();
    return allStores;
  }

  public render() {
    const { activeStore } = this.state;
    const { stores } = this.props;
    const wrapperClassName = classNames(styles.mapWrapper, "column", {
      "is-9 is-offset-3": stores,
      "is-12": !stores,
    });

    const resultsClassName = classNames("column is-3", styles.results, {
      [styles.isActive]: stores,
    });

    const lat =
      (activeStore &&
        activeStore.store &&
        activeStore.store.geoLocation &&
        activeStore.store.geoLocation.latitude) ||
      DEFAULT_MAP_CENTER.lat;
    const lng =
      (activeStore &&
        activeStore.store &&
        activeStore.store.geoLocation &&
        activeStore.store.geoLocation.longitude) ||
      DEFAULT_MAP_CENTER.lng;
    const mapCenter = {
      lat,
      lng,
    };

    if (!window.google) {
      return null;
    }

    return (
      <div className={styles.container}>
        <div className={`columns`}>
          <div className={wrapperClassName}>
            <Map
              google={window.google}
              onReady={this.onMapReady}
              zoom={activeStore ? 14 : DEFAULT_MAP_ZOOM}
              className={styles.map}
              initialCenter={mapCenter}
              center={mapCenter}
              mapTypeControlOptions={{
                mapTypeIds: ["styled_map"],
              }}
            >
              {this.renderStoresMarker()}
            </Map>
          </div>
          <div className={resultsClassName}>{this.renderResults()}</div>
        </div>
      </div>
    );
  }
}

export default MapContainer;
