import React, { ChangeEvent, Component } from "react";

import styles from "./ContactForm.module.scss";
import Headline from "../Headline/Headline";
import InputText from "../InputText/InputText";
import Button from "../Button/Button";

interface Props {
  headline: string;
  nameLabel: string;
  messageLabel: string;
  buttonLabel: string;
  subject: string;
  ipuroemail: string;
}

interface State {
  name: string;
  message: string;
  mailtoLink: string;
}

class ContactForm extends Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      name: "",
      message: "",
      mailtoLink: this.getMailToLink("", ""),
    };

    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeMessage = this.onChangeMessage.bind(this);
    this.pristineState = this.pristineState.bind(this);
    this.clearField = this.clearField.bind(this);
    this.getMailToLink = this.getMailToLink.bind(this);
  }

  private getMailToLink(name, message): string {
    const { ipuroemail, subject } = this.props;

    let mailtoString = `mailto:${ipuroemail}?`;

    if (name) {
      mailtoString += `subject=${subject} ${name}&`;
    }

    if (message) {
      mailtoString += `body=${message}`;
    }

    return mailtoString;
  }

  private onChangeName(event: ChangeEvent<HTMLInputElement>): void {
    event.preventDefault();
    const name = event.target.value;
    // eslint-disable-next-line no-whitespace-before-property
    const mailtoLink = this.getMailToLink(name, this.state.message);
    this.setState({ name, mailtoLink });
  }

  private onChangeMessage(event: ChangeEvent<HTMLInputElement>): void {
    event.preventDefault();
    const message = event.target.value;
    const mailtoLink = this.getMailToLink(this.state.name, message);
    this.setState({ message, mailtoLink });
  }

  private clearField(name: string): void {
    const newState = {
      ...this.state,
    };
    newState[name] = "";

    if (name === "name") {
      newState.mailtoLink = this.getMailToLink("", this.state.message);
    } else {
      newState.mailtoLink = this.getMailToLink(this.state.name, "");
    }

    this.setState(newState);
  }

  private pristineState(): void {
    this.setState({
      name: "",
      message: "",
      mailtoLink: this.getMailToLink("", ""),
    });
  }

  public render(): JSX.Element {
    const { headline, nameLabel, messageLabel, buttonLabel } = this.props;
    const { mailtoLink, message, name } = this.state;

    return (
      <div className={styles.ContactForm}>
        <Headline
          className={styles.ContactForm__headline}
          component="h1"
          variant="h3"
        >
          {headline}
        </Headline>
        <div className="columns">
          <div className="column is-6 is-offset-3">
            <InputText
              onChange={this.onChangeName}
              onReset={this.clearField.bind(this, "name")}
              label={nameLabel}
              value={name}
              marginBottom
            />
            <InputText
              placeholder={messageLabel}
              onChange={this.onChangeMessage}
              onReset={this.clearField.bind(this, "message")}
              value={message}
              isTextarea
            />
          </div>
        </div>
        <div className="columns">
          <div className="column is-2 is-offset-5">
            <Button
              type="primary"
              href={mailtoLink}
              onClick={this.pristineState}
            >
              {buttonLabel}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactForm;
