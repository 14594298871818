export default class ProductDetails {
  public readonly id: string;
  public readonly headline: string;
  public readonly text: any; // RichText

  constructor(id: string, headline: string, text: any) {
    this.id = id;
    this.headline = headline;
    this.text = text;
  }
}
