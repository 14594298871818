import * as React from "react";
import { Component } from "react";
import { RouteComponentProps } from "react-router";
import Headline from "../components/Headline/Headline";
import IpuroLink from "../components/IpuroLink/IpuroLink";
import PathHelper from "../services/Helper";
interface Params {}

export interface Props extends RouteComponentProps<Params> {}

interface State {
  locale: string;
}

export default class ErrorPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      locale: PathHelper.defaultLocale
    };
  }

  public componentDidMount(): void {
    const path = window.location.pathname;
    let locale = PathHelper.localeOfPath(path);

    this.setState({
      locale: locale
    });
  }

  public render(): JSX.Element {
    const { locale } = this.state;
    return (
      <>
        {locale === "de" && (
          <>
            <Headline component="h4">Diese Seite konnte leider nicht gefunden werden.</Headline>
            <p style={{ padding: "15px 0" }}>
              Bitte überprüfe Deinen eingegeben Link. Es kann sein, dass diese Seite archiviert oder
              umbenannt wurde. Vielleicht findest Du ja den gesuchten Inhalt über unsere Startseite?
            </p>
            <IpuroLink to={"/"}>Hier gehts zur Startseite</IpuroLink>
          </>
        )}
        {locale === "en" && (
          <>
            <Headline component="h4">Page not found.</Headline>
            <p style={{ padding: "15px 0" }}>
              Please check the link you entered. It is possible that the page was moved or renamed.
              Maybe you can find the content you were looking for by visiting our homepage.
            </p>
            <IpuroLink to={"/"}>To homepage</IpuroLink>
          </>
        )}
      </>
    );
  }
}
