import * as React from "react";
import {ChangeEvent, Component} from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
import GeoLocation from "../entities/GeoLocation";
import ServiceRegistry from "../services/ServiceRegistry";
import styles from "./StoreFinder.module.scss";
import Headline from '../components/Headline/Headline';
import MapContainer from './MapContainer';
import Button from '../components/Button/Button';
import InputText from '../components/InputText/InputText';
import Partnershops from './Partnershops';
import PartnerShop from '../entities/PartnerShop';

export interface Props {
}

interface State {
  apiAvailable: boolean;
  searchTerm: string;
  address?: string;
  location?: GeoLocation | undefined;
  highlightedStore: any; // TODO
  partnerShops?: PartnerShop[]
}

export default class StoreFinder extends Component<Props, State> {

  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      apiAvailable: false,
      searchTerm: "",
      address: "",
      highlightedStore: undefined,
    };

    this.onApiAvailable = this.onApiAvailable.bind(this);
    this.onAddressChanged = this.onAddressChanged.bind(this);
    this.onAddressSelected = this.onAddressSelected.bind(this);
    this.renderInput = this.renderInput.bind(this);
  }

  public componentDidMount(): void {
    ServiceRegistry
    .getGoogleMapsApiLoader()
    .callbackWhenLoaded(this.onApiAvailable);

    ServiceRegistry
    .getContentService()
    .getAllPartnerShops()
    .then((partnerShops) => this.setState({partnerShops}));
  }

  handleButtonClick = (e: React.SyntheticEvent, searchTerm: string) => {
    e.preventDefault();
    this.onAddressSelected(searchTerm);
  }


  public render(): JSX.Element {
    const {searchTerm, apiAvailable, location} = this.state;
    const t = ServiceRegistry.getTranslations().getT();
    const stores = location && this.getResults(location);
    const allStores = location && this.getAllStores(location);
    const defaultActiveStore = stores && stores[0];
    const key = defaultActiveStore && defaultActiveStore.store && defaultActiveStore.store.id;
    const {partnerShops} = this.state;

    if (!apiAvailable) {
      return (<div/>);
    }

    return (
      <div className={styles.wrapper}>
        <Headline component="h1" variant='h2'>
          {t('StoreFinder.headline')}
        </Headline>
        {/* {partnerShops && JSON.stringify(partnerShops)} */}
        <div className={`${styles.formWrapper} columns`}>
          <div className='column is-9'>
            <label className={styles.label}>{t('StoreFinder.inputLabel')}</label>
            <PlacesAutocomplete
              value={searchTerm}
              onSelect={this.onAddressSelected}
              onChange={this.onAddressChanged}
            >
              {this.renderInput}
            </PlacesAutocomplete>
          </div>
          <div className='column is-3'>
            <Button onClick={(e: React.SyntheticEvent) => this.handleButtonClick(e, searchTerm)}>{t('StoreFinder.buttonLabel')}</Button>
          </div>
        </div>
        {/* {location && this.renderResults(location)} */}
        {apiAvailable && (
          <MapContainer
            location={location}
            stores={stores}
            allStores={allStores}
            defaultActiveStore={defaultActiveStore}
            key={key}
          />
        )}

        <Partnershops
          headline={t('StoreFinder.partner.headline')}
          items={partnerShops}
        />
      </div>
    );
  }

  private onApiAvailable(): void {
    this.setState({apiAvailable: true});
  }

  private onAddressSelected(address: string): void {
    geocodeByAddress(address)
    .then(results => {
      if (getLatLng) {
        return getLatLng(results[0]);
      } else {
        console.log("getLatLng not defined");
      }
      return undefined;
    })
    .then(latLng => {
      if (latLng) {
        const location = new GeoLocation(latLng.lat, latLng.lng);
        this.setState({location, address, searchTerm: address});
      } else {
        console.log("No lat and lon received");
      }
    })
    .catch(error => console.log(error));
  }

  private onAddressChanged(address: string): void {
    this.setState({searchTerm: address});
  }

  onReset = () => {
    this.setState({
      searchTerm: '',  // clear input
      location: undefined, // hide result list
    });
  }

  onInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();
    this.setState({ searchTerm: event.target.value})
  }


  private renderInput({getInputProps, suggestions, getSuggestionItemProps, loading}): JSX.Element {
    const t = ServiceRegistry.getTranslations().getT();

    return (
      <div className={styles["autocomplete-root"]}>
        {/* <input {...getInputProps({})}/> */}
        <InputText {...getInputProps({})} onReset={this.onReset} value={this.state.searchTerm} />
        <div className={styles["autocomplete-dropdown-container"]}>
          {loading && <div>{t('StoreFinder.loading')}</div>}
          {suggestions.length > 0 && <ul className={styles["autocomplete-dropdown-list"]}>
            {suggestions.map(suggestion => {
              const className = suggestion.active ? styles['storefinder-suggestion-active'] : styles["storefinder-suggestion"];
              return (<li {...getSuggestionItemProps(suggestion, {className})} key={suggestion.id}>{suggestion.description}</li>);
            })}
          </ul>}
        </div>
      </div>
    );
  }



  private getAllStores(location: GeoLocation) {
    return ServiceRegistry.getStoreDirectory().getClosest(location);
  }

  private getResults(location: GeoLocation) {
    const closestStores = ServiceRegistry.getStoreDirectory().getClosest(location, 5)
    return closestStores;
  }

  // private renderResults(location: GeoLocation): JSX.Element {
  //   const {address} = this.state;
  //   const nearest = ServiceRegistry.getStoreDirectory().getClosest(location, 5);
  //   return (<div>
  //     RESULTS FOR {address} {JSON.stringify(location)}<br/>
  //     {nearest.map(result => <div>{JSON.stringify({result})}</div>)}
  //     <hr />
  //   </div>);
  // }
}
