import * as React from 'react';
import styles from './AccordeonPanel.module.scss';
import classNames from 'classnames';
import Icon from '../Icon/Icon';
import { ANIMATION_SPEED_SLOW } from '../../constants';

interface Props {
  title: string,
}

interface State {
  isOpen?: boolean,
  icon: "minus" | "plus",
}


class AccordeonPanel extends React.Component<Props, State> {

  constructor(props:Props) {
    super(props);

    this.state = {
      isOpen: false,
      icon: "plus",
    }
  }

  onClick = () => {
    if (this.state.isOpen) {
      this.closePanel();
    } else {
      this.openPanel();
    }

  }

  openPanel = () => {
    this.setState({
      isOpen: true,
      icon: "minus"
    });
  }

  closePanel = () =>  {
    this.setState({isOpen: false},
      () => {
        window.setTimeout(() => {
          this.setState({icon: "plus"});
      }, ANIMATION_SPEED_SLOW);
      }
    );


  }

  public render() {
    const {title, children} = this.props;
    const {icon} = this.state;

    const {isOpen} = this.state;
    const contentClass = classNames(styles.AccordeonPanel__content, {
      [styles.open]: isOpen
    });

    return (
      <div className={styles.AccordeonPanel}>
        <div onClick={this.onClick} className={styles.AccordeonPanel__title}>
          <span>{title}</span>
          <Icon icon={icon} size="small" />
        </div>
        <div className={contentClass}>
          <div className={styles.AccordeonPanel__copy}>{children}</div>
        </div>
      </div>
    );
  }


};

export default AccordeonPanel;
