import * as React from "react";
import Product from "../../entities/Product";
import ProductVariant from "./ProductVariant";
import styles from "./ProductVariants.module.scss";
import { default as ProductVariantInterface } from "../../entities/ProductVariant";

export interface Props {
  product: Product;
  onChange: (variant: ProductVariantInterface) => void;
  selectedVariant: ProductVariantInterface | undefined;
}

const handleChange = (variant: ProductVariantInterface, callback: Function) => {
  callback(variant);
};

const ProductVariants: React.SFC<Props> = (props) => {
  const { variants } = props.product;
  const { selectedVariant, onChange } = props;

  return (
    <form className={styles.wrapper}>
      {variants.map((variant, i) => {
        return (
          <ProductVariant
            onChange={() => handleChange(variant, onChange)}
            checked={!!(selectedVariant && selectedVariant.id === variant.id)}
            key={i}
            variant={variant}
          />
        );
      })}
    </form>
  );
};

export default ProductVariants;
