import React from 'react';
import classNames from 'classnames';

import IpuroLink from '../IpuroLink/IpuroLink';

import styles from './TextTeaser.module.scss';
import { ReactNode } from 'react';


interface Props {
    isSmall?: boolean
    headline?: string,
    linkLabel?: string,
    link?: string,
    children: string | ReactNode
}

const TextTeaser: React.SFC<Props> =({isSmall, headline, linkLabel, link, children}) => {
    const className = classNames({
        [styles.TextTeaser]: true,
        [styles.TextTeaser___small]: isSmall,
    });

    return (
        <div className={className}>
            {headline &&
                <h2 className={styles.TextTeaser__headline}>
                    {headline}
                </h2>
            }
            <div className={styles.TextTeaser__copy}>
                {children}
            </div>

            {link && linkLabel && (
              <IpuroLink
                  to={link}
                  type='primary'
              >
                  {linkLabel}
              </IpuroLink>

            )}
        </div>
    );
};

export default TextTeaser;
