import * as React from "react";
import { Component } from "react";
import { RouteComponentProps } from "react-router";
import RichText from "../components/RichText";
import SeoTags from "../components/SeoTags";
import PageData from "../entities/PageData";
import RouteInfo from "../entities/RouteInfo";
import ServiceRegistry from "../services/ServiceRegistry";
import ErrorPage from "./ErrorPage";

export interface Props extends RouteComponentProps {}

interface State {
  page?: PageData;
  route?: RouteInfo;
}

export default class StaticPage extends Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {};
  }

  public componentDidMount(): void {
    this.reload();
  }

  public componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (prevProps.location.pathname === this.props.location.pathname) {
      return;
    }
    this.reload();
  }

  public render(): JSX.Element {
    const { location } = this.props;
    const { page } = this.state;
    if (!page) {
      return <div />;
    }

    const is404Page = location.pathname.includes("/404");

    const routeRegistry = ServiceRegistry.getRouteRegistry();
    const alternates: { locale: string; path: string }[] = [];
    ServiceRegistry.getLocales().forEach(locale => {
      const path = routeRegistry.translatePath(location.pathname, locale);
      if (path) {
        alternates.push({ locale, path });
      }
    });

    if (is404Page) {
      return (
        <>
          <SeoTags
            location={location}
            title={page.title}
            description={page.seoDescription}
            alternates={alternates}
            is404Page={true}
            noIndex={true}
          />
          <ErrorPage {...this.props} />
        </>
      );
    }

    return (
      <div style={{ backgroundColor: "#AAAAAA" }}>
        <SeoTags
          location={location}
          title={page.title}
          description={page.seoDescription}
          alternates={alternates}
        />
        {page.text && <RichText text={page.text} />}
      </div>
    );
  }

  private reload(): void {
    const {
      location: { pathname }
    } = this.props;
    const routeRegistry = ServiceRegistry.getRouteRegistry();
    let route = routeRegistry.getByPath(pathname);
    if (!route) {
      throw new Error("Route not found");
    }
    ServiceRegistry.getContentService()
      .getPage(route.pageId, route.locale)
      .then(page => this.setState({ page, route }));
  }
}
