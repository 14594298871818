import classNames from 'classnames';
import React from 'react';

import IpuroLink from '../IpuroLink/IpuroLink';
import RichText from "../RichText";

import styles from './RichTextTeaser.module.scss';


interface Props {
    isSmall?: boolean
    headline?: string,
    linkLabel?: string,
    link?: string,
    text: string
}

const RichTextTeaser: React.SFC<Props> = ({isSmall, headline, linkLabel, link, text}: Props) => {
    const className = classNames({
        [styles.RichTextTeaser]: true,
        [styles.RichTextTeaser___small]: isSmall,
    });

    return (
        <div className={className}>
            {headline &&
                <h2 className={styles.TextTeaser__headline}>
                    {headline}
                </h2>
            }
            <RichText className={className} text={text}/>

            {link && linkLabel && (
              <IpuroLink
                  to={link}
                  type='primary'
              >
                  {linkLabel}
              </IpuroLink>

            )}
        </div>
    );
};

export default RichTextTeaser;
