import * as React from "react";
import { Component } from "react";
import { Redirect, RouteComponentProps } from "react-router";
import SeoTags from "../components/SeoTags";
import ProductCatalog from "../entities/ProductCatalog";
import ProductCategory from "../entities/ProductCategory";
import PathHelper from "../services/Helper";
import ServiceRegistry from "../services/ServiceRegistry";
import Headline from "../components/Headline/Headline";
import HeroTeaser from "../components/HeroTeaser/HeroTeaserTeaser";
import TextTeaser from "../components/TextTeaser/TextTeaser";
import ProductCategoryTeaser from "../components/ProductCategoryTeaser/ProductCategoryTeaser";
import RichText from "../components/RichText";
import styles from "./ProductLinePage.module.scss";
import classNames from "classnames";

interface Params {
  locale: string;
  productLine: string;
}

export interface Props extends RouteComponentProps<Params> {}

interface State {}

export default class ProductLinePage extends Component<Props, State> {
  private productCatalog: ProductCatalog;

  constructor(props: Props, context: any) {
    super(props, context);

    this.productCatalog = ServiceRegistry.getProductCatalog();

    this.state = {};

    this.renderCategory = this.renderCategory.bind(this);
  }

  public render(): JSX.Element {
    const { locale } = this.props.match.params;
    const productLine = this.productCatalog.getProductLineByPath(
      this.props.location.pathname
    );
    const columClass = classNames(
      "columns is-multiline",
      styles.stage__wrapper
    );
    if (!productLine) {
      return <Redirect to={PathHelper.pathFromSlug("404", locale)} />;
    }

    return (
      <>
        <SeoTags
          location={this.props.location}
          title={productLine.title}
          description={productLine.seoDescription}
          alternates={productLine.getAlternatePaths()}
        />
        <div className={columClass}>
          <div className="column is-12">
            <Headline>{productLine.title}</Headline>
          </div>
          {productLine.image && productLine.image.url && (
            <HeroTeaser
              image={productLine.image}
              imageMobile={productLine.imageMobile}
              fallbackImage={productLine.fallbackImage}
            />
          )}
          {productLine.description && (
            <div className="column is-12">
              <TextTeaser>
                <RichText text={productLine.description} />
              </TextTeaser>
            </div>
          )}
        </div>
        {productLine.categories.map(this.renderCategory)}
      </>
    );
  }

  private renderCategory(
    category: ProductCategory,
    index: number
  ): JSX.Element {
    const { locale } = this.props.match.params;
    const { id, title, overviewImage, productPreviews, slug } = category;
    const t = ServiceRegistry.getTranslations().getT();
    const productLine = this.productCatalog.getProductLineByPath(
      this.props.location.pathname
    );

    if (!productLine) {
      return <Redirect to={PathHelper.pathFromSlug("404", locale)} />;
    }

    const numberOfCategories = productLine.categories.length;

    return (
      <div className="column is-12" key={id}>
        <ProductCategoryTeaser
          headline={t(`productLine.${slug}.headline`)}
          subHeadline={title}
          largeImage={overviewImage}
          reversePosition={index % 2 !== 0}
          linkLabel={t(`productLine.${slug}.moreLink`)}
          linkUrl={this.productCatalog.getPathOfCategory(category)}
          products={productPreviews}
          style={numberOfCategories === index + 1 ? { marginBottom: 0 } : {}}
        />
      </div>
    );
  }
}
