import * as React from 'react';
import Price from "../../entities/Price";
import ServiceRegistry from "../../services/ServiceRegistry";
import styles from './ProductTeaser.module.scss';
import classNames from 'classnames';
import ImageInfo from '../../entities/ImageInfo';
import { Image } from '../Image/Image';
import PathHelper from '../../services/Helper';

interface Props {
  image: ImageInfo,
  title: string,
  type: string,
  badgeLabel?: string,
  price?: Price,
  productLine?: string,
  size?: "big" | "small",
  numVariants: number
}

const ProductTeaser: React.SFC<Props> = ({image, title, type, badgeLabel, price, productLine, size = "big", numVariants}) => {

  const teaserWrapper = classNames(styles.wrapper, {
    [styles.small]: size === "small"
  });

  const translations = ServiceRegistry.getTranslations();
  const t = translations.getT();
  const replacements = {
    numVariants: `${numVariants}`,
    price: price && price.amount!== 0 ? translations.formatPrice(price) : ""};
  const priceTag = (numVariants > 1)
  ? t("ProductTeaser.priceFrom.multipleVariants", replacements)
  : t("ProductTeaser.priceFrom.oneVariant", replacements);

  return (
    <div className={teaserWrapper}>
        <div className={styles.badge}>
          {badgeLabel && (
            <span className={styles.badgeText}>{badgeLabel}</span>
          )}
        </div>
      <div className={styles.product}>
        <Image image={image} className={styles.image} />
        <div className={styles.text}>
          <h3 className={styles.headline} dangerouslySetInnerHTML={PathHelper.comma2LineBreak(title)}></h3>
          <div className={styles.type}>{type}</div>
          {productLine && <div className={styles.productLine}>{productLine}</div>}
          <div className={styles.price}>{priceTag}</div>
        </div>
      </div>
    </div>
  );
};

export default ProductTeaser;
