import React from 'react';

// import ServiceRegistry from '../../../services/ServiceRegistry';

import styles from './ContentItems.module.scss';
// import { Link } from 'react-router-dom';

import classNames from 'classnames';
import ProductCategory from '../../../entities/ProductCategory';
import { HashLink as Link } from 'react-router-hash-link';
import Scrollspy from 'react-scrollspy';

interface Props {
    category: ProductCategory,
    pathOfCategory: string,
    isExpanded: boolean,
    toggleCategory: any,
    isOnCategoryPage?: boolean,
    activeProductCategory?: string,
    isMobile?: boolean,
    invertedFontColor?: boolean,
}

const ContentItems: React.SFC<Props> =({category, toggleCategory, isExpanded, pathOfCategory, isOnCategoryPage, activeProductCategory, isMobile, invertedFontColor}) => {

    const thisIsSelectedCategory = activeProductCategory === category.slug || isExpanded

    const expandableClassName = classNames({
        [styles.ContentItems__expandable]: true,
        'is-hidden-touch': true,
        [styles.ContentItems__expandable___expanded]: thisIsSelectedCategory,
    });

    const primaryClassName = classNames({
        [styles.ContentItems__primary]: true,
        [styles.ContentItems__primary___active]: thisIsSelectedCategory,
        [styles.ContentItems__primary___mobile]: isMobile,
        [styles.ContentItems__primary___white]: invertedFontColor,
    });

    const secondaryClassName = classNames({
        [styles.ContentItems__secondary]: true,
        [styles.ContentItems__secondary___active]: false,
        [styles.ContentItems__secondary___white]: invertedFontColor,
    });

    const products = {};

    if (category.products && category.products.length) {
        category.products.forEach((product) => {
            products[product.type.identifier] = product.type;
        })
    }

    const navigateToCategory = () => {
        //@ts-ignore
        window.routerHistory.push(pathOfCategory)
    }

    const scrollWithOffset = (el, offset) => {
      const elementPosition = el.offsetTop - offset;
      window.scroll({ behavior: 'smooth', top: elementPosition});
      // el.scrollIntoView({ behavior: 'smooth'});
      //window.scrollBy(0, offset);
    }

    return (
        <div className={styles.ContentItems}>
            <div className='is-hidden-touch'>
                <span className={primaryClassName} onClick={isOnCategoryPage ? navigateToCategory : toggleCategory}>
                    {category.title}
                </span>
            </div>
            <div className='is-hidden-desktop'>
                <span className={primaryClassName} onClick={navigateToCategory}>
                    {category.title}
                </span>
            </div>
            <div className={expandableClassName}>
                <Scrollspy items={ Object.keys(products).map(key => key) } offset={-400} currentClassName={styles.current} componentTag="div">
                  {Object.keys(products).map(key => {
                    const item = products[key];
                      let url = `${pathOfCategory}#${key}`;

                      return (
                          <Link key={item.listTitle} className={secondaryClassName} to={url} scroll={el => scrollWithOffset(el, 0)}>
                              {item.listTitle}
                          </Link>
                      );
                  })}
                </Scrollspy>
            </div>
        </div>
    );
};

export default ContentItems;
