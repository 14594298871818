
import * as React from 'react';
import { withRouter } from 'react-router';
import {RouteComponentProps} from "react-router";

export interface State {
}

type PathParamsType = {
}

// Your component own properties
type Props = RouteComponentProps<PathParamsType> & {
  location: string,
}

class ScrollToTop extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
    }
  }

  public componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location && this.props.location.hash === "") {
      window.scrollTo(0, 0)
    }
  }

  public render() {
    return this.props.children
  }
}

export default withRouter(ScrollToTop);
