import * as React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/scss/image-gallery.scss";
import "./Gallery.scss";
import ImageInfo from "../../entities/ImageInfo";

interface Props {
  images: ImageInfo[];
  type: string;
}

const Gallery: React.SFC<Props> = (props) => {
  const { images, type } = props;

  const galleryImages = images.map((image) => {
    const { url, height, width } = image;
    const widthHalf = Math.floor(width! / 2);
    const heightHalf = Math.floor(height! / 2);
    const nonRetinaUrl =
      type === "scents"
        ? `${url}?fit=pad` // workaround to fit for small squared images
        : `${url}?fit=scale&w=${widthHalf}&h=${heightHalf}`; // half sized image for non-retina

    const retinaUrl =
      type === "scents"
        ? `${url}?fit=pad` // // workaround to fit for small squared images
        : url; // standard full sized image for non-retina

    return {
      original: nonRetinaUrl,
      srcSet: `${nonRetinaUrl} 1x, ${retinaUrl} 2x`,
      thumbnail: url,
    };
  });

  const renderLeftNav = (onClick, disabled) => {
    return (
      <button
        className="image-gallery-custom-left-nav"
        disabled={disabled}
        onClick={onClick}
      />
    );
  };

  const renderRightNav = (onClick, disabled) => {
    return (
      <button
        className="image-gallery-custom-right-nav"
        disabled={disabled}
        onClick={onClick}
      />
    );
  };

  return (
    <ImageGallery
      showFullscreenButton={false}
      // thumbnailPosition="left"
      showThumbnails={false}
      showPlayButton={false}
      items={galleryImages}
      additionalClass="gallery-wrapper"
      renderLeftNav={renderLeftNav}
      renderRightNav={renderRightNav}
    />
  );
};

export default Gallery;
