import React from "react";

import Icon from "../Icon/Icon";

import styles from "./SocialMediaIcons.module.scss";

interface Props {
  t: Function;
}

const SocialMediaIcons: React.SFC<Props> = ({ t }) => {
  const socialMediaSites = [
    {
      icon: "facebook",
      url: t("data.facebook"),
    },
    {
      icon: "instagram",
      url: t("data.instagram"),
      marginTopIconFix: 2,
    },
  ];
  return (
    <div className={styles.SocialMediaIcons}>
      {socialMediaSites.map((socialMediaSite) => {
        return (
          <a
            key={socialMediaSite.url}
            className={styles.SocialMediaIcons__link}
            href={socialMediaSite.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon
              icon={socialMediaSite.icon}
              size="small"
              className={styles.SocialMediaIcons__icon}
              style={{
                marginTop: socialMediaSite.marginTopIconFix
                  ? socialMediaSite.marginTopIconFix
                  : 0,
              }}
            />
          </a>
        );
      })}
    </div>
  );
};

export default SocialMediaIcons;
