import { Component } from "react";
import * as React from "react";
import Helmet from "react-helmet";
import { Location } from "history";
import PathHelper from "../services/Helper";
import OgImageSrc from "../images/meta/og-image.png";

export interface Props {
  location: Location;
  title?: string;
  description?: string;
  noIndex?: boolean;
  is404Page?: boolean;
  alternates?: { locale: string; path: string }[];
}

export interface State {}

export default class SeoTags extends Component<Props, State> {
  private static readonly SITE_NAME =
    process.env.REACT_APP_SITE_NAME || "ipuro.com";
  private static readonly DEFAULT_LOCALE = "de"; // @todo config?

  public render(): JSX.Element {
    const {
      location: loc,
      title,
      description = "",
      noIndex = false,
      alternates = [],
      is404Page = false,
    } = this.props;

    const absoluteUrl = `${OgImageSrc}`;

    return (
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />

        {!noIndex && (
          <link rel="canonical" href={PathHelper.canonicalPath(loc)} />
        )}

        {alternates.map(({ path, locale }) => {
          const p = PathHelper.canonicalPathOfPath(path);
          const result: JSX.Element[] = [
            <link key={locale} rel="alternate" hrefLang={locale} href={p} />,
          ];
          if (locale.toLowerCase() === SeoTags.DEFAULT_LOCALE) {
            result.push(
              <link
                key="x-default"
                rel="alternate"
                hrefLang="x-default"
                href={p}
              />
            );
          }
          return result;
        })}

        {description.trim() !== "" && (
          <meta name="description" content={description} />
        )}
        {description.trim() !== "" && (
          <meta property="og:description" content={description} />
        )}

        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={SeoTags.SITE_NAME} />

        {PathHelper.canonicalPath(loc).indexOf("404") !== -1 && (
          <meta name="prerender-status-code" content="404" />
        )}
        {noIndex ? (
          <meta name="robots" content="noindex,follow" />
        ) : (
          <meta name="robots" content="index,follow" />
        )}

        {is404Page && <meta name="prerender-status-code" content="404" />}

        <meta property="og:image" content={absoluteUrl} />
      </Helmet>
    );
  }
}
