import * as React from "react";
import { Component } from "react";
import ProductCatalog from "../entities/ProductCatalog";
import { NavLink } from "react-router-dom";
import RouteRegistry from "../services/RouteRegistry";
import ServiceRegistry from "../services/ServiceRegistry";
import styles from "./Navigation.module.scss";
import navItemStyles from "./NavigationItem.module.scss";
import classNames from "classnames";
import NavigationItem from "./NavigationItem";
// import LanguageSwitch from './LanguageSwitch';
import Icon from "../components/Icon/Icon";
import PathHelper from "../services/Helper";

export interface Props {
  locale: string;
  isNaviOpen: boolean;
  callback?: () => void;
  onLocaleChange: (locale: string, newPath: string) => void;
}

interface State {
  localeSwitchisOpen: boolean;
  selected: string;
}

export default class Navigation extends Component<Props, State> {
  private routeRegistry: RouteRegistry;
  private productCatalog: ProductCatalog;

  state = { localeSwitchisOpen: false, selected: this.props.locale };

  constructor(props: Props, context: any) {
    super(props, context);

    this.routeRegistry = ServiceRegistry.getRouteRegistry();
    this.productCatalog = ServiceRegistry.getProductCatalog();
  }

  handleToggleClick = () => {
    this.setState({ localeSwitchisOpen: !this.state.localeSwitchisOpen });
  };

  public render(): JSX.Element {
    const { locale, isNaviOpen, callback } = this.props;
    const { productCatalog } = this;
    if (!productCatalog) {
      return <div />;
    }

    const index = this.routeRegistry.getByIdentifierAndLocale("index", locale);
    const about = this.routeRegistry.getByIdentifierAndLocale("about", locale);
    const productLines = productCatalog
      .getProductLines()
      .map((productLine) => (
        <NavigationItem
          onClick={callback}
          key={productLine.id}
          locale={locale}
          productLine={productLine}
        />
      ));

    const navigationClassName = classNames(styles.navigation, {
      [styles.isNaviOpen]: isNaviOpen,
    });

    const navItemClassName = classNames(navItemStyles.navItem, {
      [navItemStyles.isActive]: this.state.localeSwitchisOpen,
    });

    const locales = this.routeRegistry.getLocales();

    return (
      <nav className={navigationClassName}>
        <ul className={styles.navItemsList}>
          {index && (
            <li className={styles.navItem}>
              <NavLink
                exact
                onClick={callback}
                className={styles.navItemLink}
                activeClassName={styles.navItemLinkActive}
                to={index.path}
              >
                {index.navigationTitle}
              </NavLink>
            </li>
          )}
          {productLines}
          {about && (
            <li
              className={styles.navItem}
              key="about"
              style={{ marginRight: 0 }}
            >
              <NavLink
                onClick={callback}
                className={styles.navItemLink}
                activeClassName={styles.navItemLinkActive}
                to={about.path}
              >
                {about.navigationTitle}
              </NavLink>
            </li>
          )}

          <li className={`${navItemClassName} ${styles.hideOnDesktop}`}>
            <span
              className={navItemStyles.navItemLinkWrapper}
              onClick={this.handleToggleClick}
            >
              <div className={navItemStyles.navItemLink}>{locale}</div>
              <Icon
                className={navItemStyles.navItemToggler}
                icon="chevronRight"
                size="small"
                onClick={this.handleToggleClick}
              />
            </span>
            <div className={navItemStyles.dropdown}>
              <ul className={navItemStyles.catergoryList}>
                {locales.map((locale, index) => (
                  <li
                    key={`${index}-${locale}`}
                    className={navItemStyles.languageSwitchItem}
                  >
                    <span
                      style={{ textTransform: "uppercase" }}
                      onClick={() => this.handleLanguageSwitch(locale)}
                    >
                      {locale}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </li>
        </ul>
      </nav>
    );
  }

  private handleLanguageSwitch(selected: string): void {
    if (this.state.selected === selected) {
      return;
    }

    let newPath = this.routeRegistry.translatePath(
      window.location.pathname,
      selected
    );
    if (!newPath) {
      newPath = PathHelper.pathFromSlug("/", selected);
    }
    this.setState({ selected });
    this.props.onLocaleChange(selected, newPath);
  }
}
