import * as React from 'react';
import styles from './BurgerMenu.module.scss';
import classNames from 'classnames';

interface Props {
  onClick: () => void;
  isNaviOpen:boolean;
}

const BurgerMenu: React.SFC<Props> = ({onClick, isNaviOpen}) => {

  const wrapperClassName = classNames(styles.wrapper, {
    [styles.isNaviOpen]: isNaviOpen
  })

  return (
    <div className={wrapperClassName}>
      <div onClick={onClick} className={styles.mobileNavToggle}>
        <div className={styles.burgerMenu}>
          <span className={styles.burgerMenuLine}></span>
          <span className={styles.burgerMenuLine}></span>
          <span className={styles.burgerMenuLine}></span>
          <span className={styles.burgerMenuLine}></span>
        </div>
      </div>
    </div>
  )
};

export default BurgerMenu;
