import ClosestStoreResult from "../entities/ClosestStoreResult";
import GeoLocation from "../entities/GeoLocation";
import Store from "../entities/Store";

export default class StoreDirectory {
  private stores: Store[];
  private byId: Map<string, Store>;

  constructor(stores: Store[]) {
    this.stores = stores;
    this.byId = new Map<string, Store>();
    stores.forEach(store => {
      this.byId.set(store.id, store);
    });
  }

  public getById(id: string): Store | undefined {
    return this.byId.get(id);
  }

  // TODO: needs to be updated --> replace 9999
  public getClosest(location: GeoLocation, n: number = 9999): ClosestStoreResult[] {
    const rough = this.stores.map(store => {
      if (store.geoLocation) {
        return {
          store,
          d: location.euclideanDistanceSquared(store.geoLocation) // This is way faster than distance and in and around germany fine for initial sorting
        };
      }
      return null;
    })
    .filter(obj => obj != null);

    // @ts-ignore We've filtered for null before
    rough.sort(({d: a}, {d: b}) => (a < b) ? -1 : ((a > b) ? 1 : 0));
    const candidates = StoreDirectory
    .firstN(rough, n * 2)
    // @ts-ignore We've filtered for null before
    .map(({store}) => {
      return {
        store,
        d: store.geoLocation.distanceTo(location)
      }
    });
    candidates.sort(({d: a}, {d: b}) => (a < b) ? -1 : ((a > b) ? 1 : 0));
    return StoreDirectory.firstN(candidates, n).map(({d, store}) => new ClosestStoreResult(store, d));
  }

  private static firstN<T>(collection: T[], n: number): T[] {
    const result: T[] = [];
    for (const element of collection) {
      result.push(element);
      if (result.length >= n) {
        return result;
      }
    }
    return result;
  }

}
