export default class FaqItem {
  public readonly id;
  public readonly question: string;
  public readonly answer: any; // RichText
  public readonly linkLabel: string;
  public readonly linkUrl: string;
  public readonly sorting: number;

  constructor(id: string, question: string, answer: any, linkLabel: string, linkUrl: string, sorting: number) {
    this.id = id;
    this.question = question;
    this.answer = answer;
    this.linkLabel = linkLabel;
    this.linkUrl = linkUrl;
    this.sorting = sorting;
  }
}
