import React from 'react';
import classNames from 'classnames';

import Icon from '../Icon/Icon';

import styles from './Button.module.scss';

interface Props {
    onClick?: any,
    children: string,
    href?: string,
    type?: 'primary' | 'secondary',
    className?: string,
    disabled?: boolean,
    disabledText?: string,
}

const Button: React.SFC<Props> =({onClick, children, className, type='primary', href, disabled, disabledText}) => {

    const buttonClassName = classNames(className, {
        [styles.Button]: true,
        [styles.Button___secondary]: type === 'secondary',
        [styles.Button___disabled]: disabled,
    });

    if (disabled) {
      return (
        <span
            className={buttonClassName}
        >
          <span className={styles.Button__label}>
              {disabledText ? disabledText : children}
          </span>
          {type === 'secondary' &&
              <Icon icon='chevronRight' size='small' className={styles.Button__icon}/>
          }
        </span>
      );
    }

    return (
        <a
            className={buttonClassName}
            onClick={onClick && onClick}
            href={href ? href : ''}
        >
            <span className={styles.Button__label}>
                {children}
            </span>
            {type === 'secondary' &&
                <Icon icon='chevronRight' size='small' className={styles.Button__icon}/>
            }
        </a>
    );
};

export default Button;
