import * as React from "react";
import Headline from "../components/Headline/Headline";
import styles from "./Partnershops.module.scss";
import PartnerShop from "../entities/PartnerShop";

interface Props {
  headline?: string;
  items?: PartnerShop[];
}

const renderShop = (item) => {
  const { url, logo, id } = item;
  if (!logo) return null;

  const {
    fields: { file, title },
  } = logo;

  const {
    details: { image },
    url: retinaUrl,
  } = file;
  const { width, height } = image;
  const widthHalf = Math.floor(width / 2);
  const heightHalf = Math.floor(height / 2);
  const nonRetinaUrl = `${retinaUrl}?w=${widthHalf}&h=${heightHalf}`;

  return (
    <div className={`column is-2-tablet is-4-mobile ${styles.logo}`} key={id}>
      {url && (
        <a href={url} title={title} target="_blank" rel="noopener noreferrer">
          <img
            src={nonRetinaUrl}
            srcSet={`${nonRetinaUrl} 1x, ${retinaUrl} 2x`}
            alt={title}
          />
        </a>
      )}
      {!url && (
        <img
          src={nonRetinaUrl}
          srcSet={`${nonRetinaUrl} 1x, ${retinaUrl} 2x`}
          alt={title}
        />
      )}
    </div>
  );
};

const Partnershops: React.SFC<Props> = ({ headline, items }) => {
  if (!items || items.length === 0) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      {headline && <Headline component="h3">{headline}</Headline>}
      <div className={`columns is-multiline is-mobile ${styles.list}`}>
        {items.map((item) => renderShop(item))}
      </div>
    </div>
  );
};

export default Partnershops;
