import ImageInfo from "./ImageInfo";

export default class PartnerShop {
  public readonly id;
  public readonly logo: ImageInfo;
  public readonly url: string;
  public readonly onlyIpuroExclusive: boolean;
  public readonly sorting: number;

  constructor(
    id,
    logo: ImageInfo,
    url: string,
    onlyIpuroExclusive: boolean,
    sorting: number
  ) {
    this.id = id;
    this.logo = logo;
    this.url = url;
    this.onlyIpuroExclusive = onlyIpuroExclusive;
    this.sorting = sorting;
  }
}
