/* eslint-disable array-callback-return */
import React, { Component, ReactNode } from "react";

import ServiceRegistry from "../../services/ServiceRegistry";
import ContentItems from "./ContentItems/ContentItems";
import ProductLine from "../../entities/ProductLine";

import styles from "./ProductNavigation.module.scss";
import ProductCategory from "../../entities/ProductCategory";
import classNames from "classnames";

interface Props {
  productLine: ProductLine;
  isSubMenu?: boolean;
  isOnCategoryPage?: boolean;
  invertedFontColor?: boolean;
  activeProductCategory?: ProductCategory;
  style?: object;
}
interface State {
  expandedCategoryArrayId: number | string;
}

class ProductNavigation extends Component<Props, State> {
  selector: any;
  searchInput: any;
  positionLeft: any;

  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      expandedCategoryArrayId: "",
    };

    this.toggleCategory = this.toggleCategory.bind(this);
    this.selector = React.createRef();
    this.positionLeft = null;
  }

  public toggleCategory(id): void {
    if (id.toString() === this.state.expandedCategoryArrayId.toString()) {
      this.setState({
        expandedCategoryArrayId: "",
      });
    } else {
      this.setState({
        expandedCategoryArrayId: id,
      });
    }
  }

  public isProductScrolled(): boolean {
    const { style = {} } = this.props;

    return style["position"] === "fixed";
  }

  componentDidMount() {
    const rect =
      this.selector.current && this.selector.current.getBoundingClientRect();
    this.positionLeft = rect.left;
  }

  public getCategoryItems(isMobile): ReactNode[] {
    const {
      isOnCategoryPage,
      activeProductCategory,
      invertedFontColor,
    } = this.props;
    const productCatalog = ServiceRegistry.getProductCatalog();

    let categoryItems: ReactNode[] = [];
    // NEW: Only active product category
    if (activeProductCategory) {
      const invertedColor = this.isProductScrolled()
        ? false
        : invertedFontColor;
      categoryItems.push(
        <ContentItems
          category={activeProductCategory}
          pathOfCategory={productCatalog.getPathOfCategory(
            activeProductCategory
          )}
          isExpanded={true}
          isOnCategoryPage={isOnCategoryPage}
          toggleCategory={() => {}}
          isMobile={isMobile}
          invertedFontColor={isMobile ? false : invertedColor}
        />
      );
    }

    // OLD: multiple categories per product line page
    // const { expandedCategoryArrayId } = this.state;
    // const { productLine } = this.props;
    // Object.keys(productLine.categories).map((key) => {
    //   const categoryObject = productLine.categories[key];
    //   const pathOfCategory = productCatalog.getPathOfCategory(categoryObject);
    //
    //   const invertedColor = this.isProductScrolled()
    //     ? false
    //     : invertedFontColor;
    //
    //   categoryItems.push(
    //     <ContentItems
    //       key={key}
    //       category={categoryObject}
    //       pathOfCategory={pathOfCategory}
    //       isExpanded={key.toString() === expandedCategoryArrayId.toString()}
    //       toggleCategory={() => this.toggleCategory(key)}
    //       isOnCategoryPage={isOnCategoryPage}
    //       activeProductCategory={
    //         activeProductCategory && activeProductCategory.slug
    //       }
    //       isMobile={isMobile}
    //       invertedFontColor={isMobile ? false : invertedColor}
    //     />
    //   );
    // });
    return categoryItems;
  }

  public render(): JSX.Element {
    const { style = {} } = this.props;

    const isScrolled = this.isProductScrolled();

    const customStyle = {
      ...style,
      left: isScrolled ? this.positionLeft : "unset",
      width: "385px",
      top: isScrolled ? "128px" : "24px",
      backgroundColor: isScrolled ? "rgba(255,255,255,0.95)" : "unset",
      transition: "background-color 0.2s ease-in-out",
    };

    const customMobileStyle = {
      ...style,
      position: "fixed" as "fixed",
      left: 0,
      top: "64px",
      width: "100%",
      backgroundColor: "rgba(255,255,255,0.95)",
      padding: "0 0.75rem",
    };

    const mobileProductNavigation = classNames({
      [styles.ProductNavigation]: true,
      [styles.ProductNavigation___mobile]: true,
    });

    return (
      <div>
        <div className="is-hidden-desktop">
          <div
            className={mobileProductNavigation}
            style={customMobileStyle}
            ref={this.selector}
          >
            {this.getCategoryItems(true)}
          </div>
        </div>
        <div className="is-hidden-touch">
          {/* <div className={mobileProductNavigation} style={subNavStyles} ref={this.selector}>{this.getCategoryItems(true)}</div>  */}
          {!isScrolled && (
            <div
              className={styles.ProductNavigation}
              style={customStyle}
              ref={this.selector}
            >
              {this.getCategoryItems(false)}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ProductNavigation;
