import * as React from "react";
import {Component} from "react";
import {RouteComponentProps} from "react-router";
import CompositionTeaser from "../components/CompositionTeaser/CompositionTeaser";

import HeroTeaser from '../components/HeroTeaser/HeroTeaserTeaser';
import Headline from '../components/Headline/Headline';
import RichText from "../components/RichText";
import RichTextTeaser from "../components/RichTextTeaser/RichTextTeaser";
import ContentBlock from "../entities/ContentBlock";
import PageData from "../entities/PageData";
import ServiceRegistry from "../services/ServiceRegistry";
import CompositionTeaserMobile from '../components/CompositionTeaserMobile/CompositionTeaserMobile';
import TextTeaser from '../components/TextTeaser/TextTeaser';
import Topic from '../entities/Topic';
import SubjectsTeaser from '../components/SubjectsTeaser/SubjectsTeaser';
import BigQuote from '../components/BigQuote/BigQuote';

interface Params {
}

export interface Props extends RouteComponentProps<Params> {
}

interface State {
  page?: PageData;
  topics: Topic[];
  vision: Topic[];

}

export default class AboutPage extends Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      topics: [],
      vision: [],
    };

    this.renderContentBlock = this.renderContentBlock.bind(this);
  }

  public componentDidMount(): void {
    const contentService = ServiceRegistry.getContentService();
    contentService.getPageByIdentifier("about").then((page) => this.setState({page}));
    contentService.getTopics("aboutpage-vision").then((vision) => this.setState({vision}));
    contentService.getTopics("aboutpage-further-topics").then((topics) => this.setState({topics}));
  }

  public render(): JSX.Element {
    // const t = ServiceRegistry.getTranslations().getT();
    const {page, topics, vision} = this.state;
    if (!page) {
      return (<div/>);
    }
    const {headerImage, headerImageMobile, text, title, contentBlocks} = page;

    const t = ServiceRegistry.getTranslations().getT();

    return (
      <div>
        <Headline component='h1'>
          {title}
        </Headline>
        {headerImage &&
          <HeroTeaser image={headerImage} imageMobile={headerImageMobile}/>
        }
        {text &&
          <div className="column is-12" style={{paddingTop: '16px'}}>
            <TextTeaser>
              <RichText text={text}/>
            </TextTeaser>
          </div>
        }
        {contentBlocks &&
          <div>
            {contentBlocks.map(this.renderContentBlock)}
          </div>
        }
        {vision.length > 0 &&
          <SubjectsTeaser headline={t && t('global.ipuroVision')} items={vision} />
        }
        <BigQuote headline={t && t('aboutPage.quote.author')}>
          {t && t('aboutPage.quote.text')}
        </BigQuote>
        {topics.length > 0 &&
          <SubjectsTeaser headline={t && t('global.moreTopics')} items={topics} />
        }
      </div>
    );
  }

  // TODO: duplicate code! Same on AboutPage.tsx
  private renderContentBlock({headline, largeImage, smallImage, subHeadline, text, textIsCompact, layout, readMoreTarget, readMoreLabel, id}: ContentBlock): JSX.Element {
    const largeLeft = layout === "large-left";

    if (largeLeft) {
      return (
        <div key={id}>
          <div className='is-hidden-mobile'>
            <CompositionTeaser
              headline={headline || ''}
              subHeadline={subHeadline || ''}
              largeImage={largeImage}
              smallImage={smallImage}
              reversePosition={!largeLeft}
            />
            <RichTextTeaser
              text={text}
              link={readMoreTarget}
              linkLabel={readMoreLabel}
              isSmall={textIsCompact}
            />
          </div>
          <div className='is-hidden-tablet'>
            <CompositionTeaserMobile
                headline={headline || ''}
                subHeadline={subHeadline || ''}
                largeImage={largeImage}
                smallImage={smallImage}
                reversePosition={!largeLeft}
              />
              <RichTextTeaser
                text={text}
                link={readMoreTarget}
                linkLabel={readMoreLabel}
                isSmall={textIsCompact}
              />
          </div>
        </div>
      );
    }

    return (
      <div key={id}>
        <div className='is-hidden-mobile'>
          <CompositionTeaser
            headline={headline || ''}
            subHeadline={subHeadline || ''}
            largeImage={largeImage}
            smallImage={smallImage}
            reversePosition={true}
          >
            <RichTextTeaser
              text={text}
              link={readMoreTarget}
              linkLabel={readMoreLabel}
              isSmall={textIsCompact}
            />
          </CompositionTeaser>
        </div>
        <div className='is-hidden-tablet'>
          <CompositionTeaserMobile
              headline={headline || ''}
              subHeadline={subHeadline || ''}
              largeImage={largeImage}
              smallImage={smallImage}
              reversePosition={true}
            >
              <RichTextTeaser
                text={text}
                link={readMoreTarget}
                linkLabel={readMoreLabel}
                isSmall={textIsCompact}
              />
            </CompositionTeaserMobile>
        </div>
      </div>
    );
  }
}
