import { Entry, EntryCollection } from "contentful";
import ContentBlock from "../../entities/ContentBlock";
import FaqItem from "../../entities/FaqItem";
import GeoLocation from "../../entities/GeoLocation";
import ImageInfo from "../../entities/ImageInfo";
import PageData from "../../entities/PageData";
import Price from "../../entities/Price";
import Product from "../../entities/Product";
import ProductCategory from "../../entities/ProductCategory";
import ProductDetails from "../../entities/ProductDetails";
import ProductLine from "../../entities/ProductLine";
import ProductType from "../../entities/ProductType";
import ProductVariant from "../../entities/ProductVariant";
import RouteInfo from "../../entities/RouteInfo";
import Scent from "../../entities/Scent";
import Store from "../../entities/Store";
import Topic from "../../entities/Topic";
import Translations from "../Translations";
import PathHelper from "../Helper";
import PartnerShop from "../../entities/PartnerShop";
import LegalItem from "../../entities/LegalItem";

export default class ContentTypeMapper {
  private scents: Map<string, Scent>;
  private productTypes: Map<string, ProductType>;

  constructor() {
    this.scents = new Map<string, Scent>();
    this.productTypes = new Map<string, ProductType>();

    this.isValidImageInfo = this.isValidImageInfo.bind(this);
    this.maybeImageInfo = this.maybeImageInfo.bind(this);
    this.imageInfo = this.imageInfo.bind(this);
    this.imageInfos = this.imageInfos.bind(this);
    this.productLine = this.productLine.bind(this);
    this.productCategory = this.productCategory.bind(this);
    this.product = this.product.bind(this);
    this.price = this.price.bind(this);
    this.routeInfo = this.routeInfo.bind(this);
    this.page = this.page.bind(this);
    this.translations = this.translations.bind(this);
    this.scent = this.scent.bind(this);
    this.productType = this.productType.bind(this);
    this.topic = this.topic.bind(this);
    this.contentBlock = this.contentBlock.bind(this);
  }

  public isValidImageInfo(entry: Entry<any>): boolean {
    return entry && entry.fields && entry.fields.file;
  }

  public maybeImageInfo(entry?: Entry<any>): ImageInfo | undefined {
    return entry && this.isValidImageInfo(entry)
      ? this.imageInfo(entry)
      : undefined;
  }

  public imageInfo(entry: Entry<any>): ImageInfo {
    const { fields } = entry || ({} as any);
    const { file, title } = fields || ({} as any);
    return new ImageInfo(
      (file || {}).contentType,
      (((file || {}).details || {}).image || {}).width || 0,
      (((file || {}).details || {}).image || {}).height || 0,
      (file || {}).url,
      title
    );
  }

  public imageInfos(entries: Array<Entry<any>>): ImageInfo[] {
    return entries
      ? entries.filter(this.isValidImageInfo).map(this.imageInfo)
      : [];
  }

  public productLine(
    entry: Entry<any>,
    alternates: {
      [type: string]: { [id: string]: { [locale: string]: string } };
    }
  ): ProductLine {
    const {
      sys: { id },
      fields = {},
    } = entry;
    const lineAlternates = alternates["productLine"] || {};
    const line = new ProductLine(
      id,
      fields.slug,
      fields.title,
      fields.description,
      lineAlternates[id] || {},
      fields.seoDescription,
      fields.new || false,
      fields.onSale || false,
      fields.limited || false,
      fields.hasDarkHeaderImage || false,
      this.isValidImageInfo(fields.image)
        ? this.imageInfo(fields.image)
        : undefined,
      this.isValidImageInfo(fields.imageMobile)
        ? this.imageInfo(fields.imageMobile)
        : undefined,
      this.isValidImageInfo(fields.fallbackImage)
        ? this.imageInfo(fields.fallbackImage)
        : undefined
    );
    const categories = (fields.categories || []).map((category) =>
      this.productCategory(category, line, alternates)
    );
    line.addCategories(categories);
    return line;
  }

  public productCategory(
    entry: Entry<any>,
    productLine: ProductLine,
    alternates: {
      [type: string]: { [id: string]: { [locale: string]: string } };
    }
  ): ProductCategory {
    const {
      sys: { id },
      fields = {},
    } = entry;
    const categoryAlternates = alternates["productCategory"] || {};
    const headerImage = this.imageInfo(fields.image);
    const overviewImage = fields.overviewImage
      ? this.imageInfo(fields.overviewImage)
      : headerImage;
    const category = new ProductCategory(
      id,
      fields.slug,
      fields.title,
      fields.description,
      headerImage,
      this.isValidImageInfo(fields.imageMobile)
        ? this.imageInfo(fields.imageMobile)
        : undefined,
      productLine,
      categoryAlternates[id] || {},
      fields.seoDescription,
      fields.new || false,
      fields.onSale || false,
      fields.limited || false,
      overviewImage,
      fields.hasDarkHeaderImage || false,
      fields.productCategoryDescription
    );
    const products = (fields.products || [])
      .filter((product) => !!product.fields)
      .map((product) => this.product(product, category, alternates));
    const previewIds = (fields.previewList || []).map(
      (previewEntry: Entry<any>) => previewEntry.sys.id
    );
    const previewProducts = previewIds
      .map((pid) => products.find((p: Product) => p.id === pid))
      .filter((pp) => !!pp);
    category.addProducts(products);
    category.addProductPreviews(previewProducts);
    return category;
  }

  public product(
    entry: Entry<any>,
    category: ProductCategory,
    alternates: {
      [type: string]: { [id: string]: { [locale: string]: string } };
    }
  ): Product {
    const {
      sys: { id },
      fields = {},
    } = entry;
    const productAlternates = alternates["product"] || {};

    const minPrice: number | undefined = (fields.variants || [])
      .map((e: Entry<any>) => e.fields.price)
      .reduce(
        (acc: number, cur: number) => (acc ? Math.min(acc, cur) : cur),
        undefined
      );

    const previewImage = this.imageInfo(fields.previewImage);
    const moodImage = this.isValidImageInfo(fields.moodImage)
      ? this.imageInfo(fields.moodImage)
      : undefined;

    const product = new Product(
      id,
      fields.slug,
      fields.title,
      fields.description,
      previewImage,
      this.imageInfos(fields.images),
      moodImage,
      category,
      (fields.scents || []).map(this.scent),
      this.productType(fields.type),
      (fields.similarProducts || []).map((entry: Entry<any>) => entry.sys.id),
      productAlternates[id] || {},
      fields.seoDescription,
      (fields.details || []).map(this.productDetails),
      fields.link,
      fields.linkType || (fields.link ? "ToShop" : "SoonAvailable"),
      minPrice ? this.price(minPrice) : this.price(fields.price),
      fields.new || false,
      fields.onSale || false,
      fields.limited || false,
      fields.useSmallGrid || false,
      this.price(fields.basicPrice)
    );
    product.addVariants(
      (fields.variants || []).map((variant) =>
        this.productVariant(variant, product, alternates)
      )
    );
    return product;
  }

  public productVariant(
    entry: Entry<any>,
    product: Product,
    alternates: {
      [type: string]: { [id: string]: { [locale: string]: string } };
    }
  ): ProductVariant {
    const {
      sys: { id },
      fields: {
        type,
        link,
        linkType,
        price: priceOverride,
        new: isNew,
        onSale,
        limited,
        basicPrice,
      },
    } = entry;
    const { title, image, slug, price } = type.fields;
    const variantAlternates = alternates["productVariant"] || {};
    return new ProductVariant(
      id,
      slug,
      title,
      this.imageInfo(image),
      link,
      linkType || (link ? "ToShop" : "SoonAvailable"),
      variantAlternates[id] || {},
      product,
      isNew || false,
      onSale || false,
      limited || false,
      this.price(priceOverride || price),
      this.price(basicPrice)
    );
  }

  public productDetails(entry: Entry<any>): ProductDetails {
    const {
      sys: { id },
      fields = {},
    } = entry;
    return new ProductDetails(id, (fields || {}).headline, (fields || {}).text);
  }

  public price(amount: number, currency: string = "EUR*"): Price {
    return new Price(amount, currency);
  }

  public routeInfo(entry: Entry<any>): RouteInfo {
    const {
      sys: { id, locale },
      fields: { slug, navigationTitle, title, identifier },
    } = entry;
    return new RouteInfo(
      id,
      identifier,
      locale,
      PathHelper.pathFromSlug(slug, locale),
      navigationTitle || title
    );
  }

  public page(entry: Entry<any>): PageData {
    const {
      sys: { id },
      fields,
    } = entry;
    const { contentBlocks } = fields;
    return new PageData(
      id,
      fields.slug,
      fields.text,
      fields.title,
      fields.navigationTitle,
      fields.seoDescription,
      this.isValidImageInfo(fields.headerImage)
        ? this.imageInfo(fields.headerImage)
        : undefined,
      this.isValidImageInfo(fields.headerImageMobile)
        ? this.imageInfo(fields.headerImageMobile)
        : undefined,
      contentBlocks
        ? contentBlocks.map((cb) => this.contentBlock(cb))
        : undefined
    );
  }

  public translations(
    collection: EntryCollection<any>,
    locale: string
  ): Translations {
    const map = new Map<string, string>();
    collection.items.forEach((entry) =>
      map.set(entry.fields.key, entry.fields.value)
    );
    return new Translations(map, locale);
  }

  public scent(entry: Entry<any>): Scent {
    const {
      sys: { id },
      fields = {},
    } = entry;
    const existing = this.scents.get(id);
    if (existing) {
      return existing;
    }
    const scent = new Scent(id, fields.identifier, fields.title);
    this.scents.set(id, scent);
    return scent;
  }

  public productType(entry: Entry<any>): ProductType {
    const {
      sys: { id },
      fields = {},
    } = entry;
    const existing = this.productTypes.get(id);
    if (existing) {
      return existing;
    }
    const productType = new ProductType(
      id,
      fields.identifier,
      fields.singleTitle,
      fields.listTitle
    );
    this.productTypes.set(id, productType);
    return productType;
  }

  public store(entry: Entry<any>): Store {
    const {
      sys: { id },
      fields = {},
    } = entry;
    return new Store(
      id,
      fields.title,
      fields.street,
      fields.city,
      fields.zipCode,
      fields.countryCode,
      fields.tags,
      fields.geoLocation
        ? new GeoLocation(fields.geoLocation.lat, fields.geoLocation.lon)
        : undefined,
      fields.addressAddition,
      fields.openingTimes,
      fields.phoneNumber
    );
  }

  public faqitem(entry: Entry<any>): FaqItem {
    const {
      sys: { id },
      fields = {},
    } = entry;
    return new FaqItem(
      id,
      fields.question,
      fields.answerText,
      fields.linkLabel,
      fields.linkUrl,
      fields.sorting
    );
  }

  public legalitem(entry: Entry<any>): LegalItem {
    const {
      sys: { id },
      fields = {},
    } = entry;
    return new LegalItem(
      id,
      fields.headline,
      fields.name,
      fields.content,
      fields.readMoreHeadline,
      fields.links
    );
  }

  public partnerShop(entry: Entry<any>): PartnerShop {
    const {
      sys: { id },
      fields = {},
    } = entry;
    return new PartnerShop(
      id,
      fields.logo,
      fields.url,
      fields.onlyIpuroExclusive,
      fields.sorting
    );
  }

  public topic(entry: Entry<any>): Topic {
    const {
      sys: { id },
      fields: { title, text, image, path },
    } = entry;
    return new Topic(id, title, text, this.imageInfo(image), path);
  }

  public contentBlock(entry: Entry<any>): ContentBlock {
    const {
      sys: { id },
      fields,
    } = entry;
    const { largeImage, smallImage } = fields;
    return new ContentBlock(
      id,
      fields.identifier,
      fields.layout,
      fields.subHeadline,
      fields.headline,
      fields.text,
      fields.textIsCompact || false,
      this.isValidImageInfo(largeImage)
        ? this.imageInfo(largeImage)
        : undefined,
      this.isValidImageInfo(smallImage)
        ? this.imageInfo(smallImage)
        : undefined,
      fields.readMoreTarget,
      fields.readMoreLabel
    );
  }
}
