import * as React from "react";
import {Component} from "react";
import {RouteComponentProps} from "react-router";

import Headline from '../components/Headline/Headline';
import LegalItem from '../entities/LegalItem';
import ServiceRegistry from '../services/ServiceRegistry';
import RichText from '../components/RichText';
import LegalLinkList from '../components/LegalLinkList/LegalLinkList';

import styles from './LegalPage.module.scss';

interface Params {
}

export interface Props extends RouteComponentProps<Params> {
  locale: string,
  type: string,
}

interface State {
  thisData?: LegalItem
}

export default class LegalPage extends Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);
    this.state = {
    };
  }

  public componentDidMount(): void {
    ServiceRegistry
    .getContentService()
    .getAllLegalItems()
    .then((data) => {
      const thisData = data.filter(o => o.nameDoNotChange === this.props.type)[0] || {};
      this.setState({thisData})
    });
  }

  public render(): JSX.Element {
    // const t = ServiceRegistry.getTranslations().getT();
    const {thisData} = this.state;
    const {locale} = this.props;

    if (!thisData) return <div />;

    return (
      <div className='columns is-multiline'>
        <div className='column is-12'>
          <Headline component='h1' variant='h2' className={styles.LegalPage__headline}>
            {thisData.headline}
          </Headline>
        </div>
        <div className='column is-8'>
          <RichText
            text={thisData.content}
          />
        </div>
        <div className='column is-4'>
          <LegalLinkList
            headline={thisData.readMoreHeadline}
            links={thisData.links}
            locale={locale}
          />
        </div>
      </div>
    );
  }
}
