import * as React from "react";
import ImageInfo from "../../entities/ImageInfo";
import FadeIn from "react-lazyload-fadein";

export interface Props {
  image: ImageInfo;
  className: string;
  disableRetina?: boolean;
}

export function Image({ image, className = "", disableRetina = false }) {
  if (!image) return null;

  const { url: retinaUrl, height, width, title: alt = "" } = image;
  const widthHalf = Math.floor(width / 2);
  const heightHalf = Math.floor(height / 2);
  const nonRetinaUrl = `${retinaUrl}?w=${widthHalf}&h=${heightHalf}&fm=jpg&fl=progressive&q=${80}`;
  const retina = `${retinaUrl}?fm=jpg&fl=progressive&q=${80}`;

  if (disableRetina) {
    return <img src={retinaUrl} alt={alt} className={className} />;
  }

  return (
    <FadeIn height="100%" duration={200} offset={200} once>
      {(onload) => (
        <img
          onLoad={onload}
          src={nonRetinaUrl}
          srcSet={`${nonRetinaUrl} 1x, ${retina} 2x`}
          alt={alt}
          className={className}
        />
      )}
    </FadeIn>
  );
}
