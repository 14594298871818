import * as React from "react";
import { Component } from "react";
import { Redirect, RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import SeoTags from "../components/SeoTags";
import Helper from "../services/Helper";
import ServiceRegistry from "../services/ServiceRegistry";
import Headline from "../components/Headline/Headline";
import Button from "../components/Button/Button";
import Modal from "../components/Modal/Modal";
import styles from "./ProductPage.module.scss";
import Accordeon from "../components/Accordeon/Accordeon";
import AccordeonPanel from "../components/Accordeon/AccordeonPanel";
import classNames from "classnames";
import Gallery from "../components/Gallery/Gallery";
import ProductTeaser from "../components/ProductTeaser/ProductTeaser";
import replaceTranslationKeys from "../util/replaceTranslationKeys";
import RichText from "../components/RichText";
import ProductVariants from "../components/ProductVariants/ProductVariants";
import ProductVariant from "../entities/ProductVariant";
import ReactSVG from "react-svg";
import LogoSrc from "../logo/ipuro_logo_sw_registered.svg";
import DepotLogo from "../logo/depot_logo.svg";
import Icon from "../components/Icon/Icon";
import { Image } from "../components/Image/Image";

interface Params {
  locale: string;
  productLine: string;
  productCategory: string;
  product: string;
}

export interface Props extends RouteComponentProps<Params> {}

interface State {
  isModalVisible: boolean;
  selectedVariant: ProductVariant | undefined;
}

export default class ProductPage extends Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    const productCatalog = ServiceRegistry.getProductCatalog();
    const product = productCatalog.getProductByPath(
      this.props.location.pathname
    );

    this.state = {
      isModalVisible: false,
      selectedVariant: product && product.variants && product.variants[0],
    };

    this.submitHandler = this.submitHandler.bind(this);
    this.onModalClose = this.onModalClose.bind(this);
  }

  submitHandler(e: React.SyntheticEvent) {
    e.preventDefault();

    const productCatalog = ServiceRegistry.getProductCatalog();
    const product = productCatalog.getProductByPath(
      this.props.location.pathname
    );

    const { selectedVariant } = this.state;
    const depotLink =
      (selectedVariant && selectedVariant.link) || (product && product.link);

    const shopWindow = window.open(
      "",
      "s",
      "width= 1024, height=768, left=0, top=0, resizable=yes, toolbar=yes, location=yes, directories=yes, status=no, menubar=yes, scrollbars=yes, resizable=yes, copyhistory=no"
    );

    this.openModal();

    shopWindow && shopWindow.blur();
    window.focus();

    if (depotLink && shopWindow && shopWindow.location) {
      setTimeout(() => {
        shopWindow.location.href = depotLink;
        shopWindow && shopWindow.focus();
        this.closeModal();
      }, 2000);
    }
  }

  openModal() {
    this.setState({ isModalVisible: true });
  }

  closeModal() {
    this.setState({ isModalVisible: false });
  }

  onModalClose() {
    this.closeModal();
  }

  getRelatedProducts(product, relatedProducts) {
    const t = ServiceRegistry.getTranslations().getT();
    const productCatalog = ServiceRegistry.getProductCatalog();
    const { useSmallGrid } = product;
    const badgeLabel = product.isOnSale
      ? t("product.isOnSale")
      : product.isNew
      ? t("product.new")
      : undefined;

    const columnSize = useSmallGrid
      ? "column is-2-desktop is-3-tablet is-6-mobile"
      : "column is-4-desktop is-6-mobile";
    const teaserSize = useSmallGrid ? "small" : "big";

    return relatedProducts.map((product) => (
      <div key={product.id} className={columnSize}>
        <Link to={productCatalog.getPathOfProduct(product)}>
          <ProductTeaser
            size={teaserSize}
            image={product.previewImage}
            title={product.title}
            type={product.type.singleTitle}
            price={product.price}
            numVariants={product.variants.length}
            badgeLabel={badgeLabel}
          />
        </Link>
      </div>
    ));
  }

  handleOptionChange(selectedVariant: ProductVariant) {
    this.setState({ selectedVariant });
  }

  public render(): JSX.Element {
    const { locale } = this.props.match.params;
    const productCatalog = ServiceRegistry.getProductCatalog();
    const t = ServiceRegistry.getTranslations().getT();
    const product = productCatalog.getProductByPath(
      this.props.location.pathname
    );
    if (!product) {
      return <Redirect to={Helper.pathFromSlug("404", locale)} />;
    }
    const { isModalVisible, selectedVariant } = this.state;
    const columnClass = classNames("column is-4", styles.column);
    const { similarProductIds } = product;
    const hasSimilarProducts =
      similarProductIds && similarProductIds.length > 0;
    const relatedProducts = hasSimilarProducts
      ? similarProductIds.map((id) => productCatalog.getProduct(id))
      : productCatalog.getProductsByScents(product.scents, [product.id]);

    const price = selectedVariant ? selectedVariant.price : product.price;
    const basicPrice = selectedVariant
      ? selectedVariant.basicPrice
      : product.basicPrice;
    const disabled = !(
      (selectedVariant && selectedVariant.link) ||
      (product && product.link)
    );
    const usedLinkType =
      (selectedVariant && selectedVariant.linkType) ||
      (product && product.linkType);
    const linkText =
      usedLinkType === "ToPartner"
        ? t("product.orderFromPartner")
        : usedLinkType === "ToShop"
        ? t("ProductDetail.orderLabel")
        : t("product.comingSoon");

    return (
      <div>
        <SeoTags
          location={this.props.location}
          title={product.title}
          description={product.seoDescription}
          alternates={product.getAlternatePaths()}
        />
        <div className="columns">
          <div className="column is-8">
            <div className="columns is-multiline">
              {!!!product.images.length && (
                <div className="column is-6 is-hidden-desktop is-hidden-tablet">
                  <Image
                    className={`${styles.previewImage}`}
                    image={product.moodImageSmall}
                  />
                </div>
              )}
              {product.moodImageSmall && (
                <>
                  <div className="column is-6 is-hidden-mobile">
                    <Image
                      className={styles.previewImage}
                      image={product.moodImageSmall}
                    />
                  </div>
                  <div className="column is-6">
                    <Gallery
                      images={product.images}
                      type={product.type.identifier}
                    />
                  </div>
                </>
              )}
              {!product.moodImageSmall && (
                <div className="column is-12">
                  <Gallery
                    images={product.images}
                    type={product.type.identifier}
                  />
                </div>
              )}
              {product.moodImage && (
                <div className="column is-12 is-hidden-mobile">
                  <Image
                    className={styles.moodImage}
                    image={product.moodImage}
                  />
                </div>
              )}
            </div>
          </div>
          <div className={columnClass}>
            <Headline component="h1" variant="h3">
              <span
                dangerouslySetInnerHTML={Helper.comma2LineBreak(product.title)}
              />
            </Headline>
            <div className={styles.pricesWrapper}>
              {price && price.amount !== 0 && (
                <div
                  className={styles.price}
                >{`${price.amount} ${price.currency}`}</div>
              )}
              {basicPrice &&
                basicPrice.amount !== undefined &&
                basicPrice.amount !== 0 && (
                  <div className={styles.basicPrice}>
                    {`${basicPrice.amount.toFixed(2)} ${
                      basicPrice.currency
                    }/ 100ml`}
                  </div>
                )}
            </div>
            <ProductVariants
              product={product}
              onChange={(variant) => this.handleOptionChange(variant)}
              selectedVariant={this.state.selectedVariant}
            />
            <Button
              className={styles.button}
              type="primary"
              onClick={this.submitHandler}
              disabled={disabled}
              disabledText={linkText}
            >
              {linkText}
            </Button>
            <div className={styles.copy}>
              <RichText text={product.description} />
            </div>
            <div className={styles.accordeonWrapper}>
              <div className="columns">
                <div className="column">
                  <Accordeon className={styles.accordeon}>
                    {product.details &&
                      product.details.length > 0 &&
                      product.details.map((detail, i) => (
                        <AccordeonPanel key={i} title={detail.headline}>
                          <RichText text={detail.text} />
                        </AccordeonPanel>
                      ))}
                  </Accordeon>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* {productCategory && <h2><Link to={productCatalog.getPathOfCategory(productCategory)}>{productCategory.title}</Link></h2>} */}

        {relatedProducts.length > 0 && (
          <div className={`columns is-multiline is-mobile ${styles.section}`}>
            <div className="column is-12">
              <Headline
                component="h2"
                variant="h3"
                className={styles.headlineRelated}
              >
                {hasSimilarProducts
                  ? replaceTranslationKeys(
                      t("productDetail.addonsHeadline"),
                      product.title
                    )
                  : replaceTranslationKeys(
                      t("productDetail.relatedHeadline"),
                      product.scents[0].title || product.title
                    )}
              </Headline>
            </div>
            {this.getRelatedProducts(product, relatedProducts)}
          </div>
        )}

        {/* {JSON.stringify(product.alternates)} */}

        {isModalVisible && (
          <Modal onClose={this.onModalClose}>
            <div className={styles.logos}>
              <ReactSVG svgClassName={styles.logo} src={LogoSrc} />
              <Icon
                className={styles.arrowRight}
                icon="chevronRight"
                size="small"
              />
              <ReactSVG
                svgClassName={`${styles.logo} ${styles["logo--depot"]}`}
                src={DepotLogo}
              />
            </div>
            <div className={styles.modalText}>{t("OrderModal.text")}</div>
          </Modal>
        )}
      </div>
    );
  }
}
