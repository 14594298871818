import React from 'react';
import { Link } from 'react-router-dom';

import Icon from '../Icon/Icon';

import styles from './FooterLink.module.scss';

interface Props {
    to?: string,
    children: string,
    icon?: string,
    isExternal?: boolean,
}

const FooterLink: React.SFC<Props> =({to, children, icon, isExternal}) => {
    // TODO: remove :any
    let Tag:any = Link;

    if (isExternal) {
        Tag = 'a';
    }
    if (!to) {
      return <p className={styles.FooterLink__static}>
        {children}
      </p>
    }

    return (
        <Tag
            className={styles.FooterLink}
            to={to}
            href={to}
        >
            {icon &&
                <Icon icon={icon} size='small' className={styles.FooterLink__icon}/>
            }
            {children}
        </Tag>
    );
};

export default FooterLink;
