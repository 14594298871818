import React,  {ChangeEvent, Component, FormEvent} from 'react';
import classNames from 'classnames';
import enhanceWithClickOutside from 'react-click-outside';

import ServiceRegistry from '../../services/ServiceRegistry';
import TopBarItem from '../TopBarItem/TopBarItem';

import styles from './TopBarSearch.module.scss';

interface Props {
    label: string,
    placeholder: string,
    url: string,
    history: any,
}

interface State {
    term: string,
    isSearchExpanded: boolean,
}

class TopBarSearch extends Component<Props, State> {
    searchInput: any;
    constructor(props: Props, context: any) {
        super(props, context);

        this.state = {
            term: "",
            isSearchExpanded: false,
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.expandSearch = this.expandSearch.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.closeSearch = this.closeSearch.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    private onChange(event: ChangeEvent<HTMLInputElement>): void {
        event.preventDefault();

        const term = event.target.value;

        this.setState({term});
    }

    private onKeyDown(event: KeyboardEvent) {
        if (event.keyCode === 27) {
            this.closeSearch();
        }
    }

    private expandSearch() {
        const routeRegistry = ServiceRegistry.getRouteRegistry();

        //@ts-ignore
        const path = routeRegistry.getByPath(window.routerHistory.location.pathname);
        //@ts-ignore
        const isOnSearchPage = path && path.identifier === 'search'

        if (isOnSearchPage) {
            return;
        }

        this.setState({
            isSearchExpanded: true,
        });
        this.searchInput.focus();
    }

    private closeSearch() {
        this.setState({
            isSearchExpanded: false,
            term: '',
        });
    }

    private onSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault();
        const {term} = this.state;
        const {url} = this.props;

        if (term) {
            //@ts-ignore
            window.routerHistory.push(url + '?search=' + term)
        } else {
            //@ts-ignore
            // window.routerHistory.push(url)
            window.routerHistory.push(url)
        }

        this.closeSearch();
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.onKeyDown);
    }

    componentDidMount() {
        window.addEventListener('keydown', this.onKeyDown);
    }

    public handleClickOutside () {
        this.closeSearch();
    }

    public render(): JSX.Element {
        const {isSearchExpanded, term} = this.state;
        const {label, placeholder} = this.props;

        const inputClassName = classNames({
            [styles.TopBarSearch__input]: true,
            [styles.TopBarSearch__input___expanded]: isSearchExpanded,
        });
        return (
            <div className={styles.TopBarSearch}>
                <TopBarItem onClick={this.expandSearch} icon='search' iconSize='smaller' onIconClick={isSearchExpanded ? this.onSubmit : this.expandSearch}>
                    {!isSearchExpanded &&
                        label
                    }
                    <form onSubmit={this.onSubmit}>
                      <div className={inputClassName}>
                        <input
                          type="text"
                          className={inputClassName}
                          onChange={this.onChange}
                          value={term}
                          ref={(input) => { this.searchInput = input; }}
                          placeholder={isSearchExpanded ? placeholder : ''}
                          />
                      </div>
                    </form>
                </TopBarItem>
            </div>
        );
    }
};

export default enhanceWithClickOutside(TopBarSearch);
