import "picturefill";
import { History } from "history";
import createBrowserHistory from "history/createBrowserHistory";
import React, { Component } from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import "./App.scss";
import RouteInfo from "./entities/RouteInfo";
import PathHelper from "./services/Helper";
import ServiceRegistry from "./services/ServiceRegistry";
import ProductCategoryPage from "./views/ProductCategoryPage";
import LandingPage from "./views/LandingPage";
import TempPage from "./views/TempPage";
import SearchPage from "./views/SearchPage";
import ProductPage from "./views/ProductPage";
import StaticPage from "./views/StaticPage";

import TopBar from "./components/TopBar/TopBar";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import StoreFinder from "./widgets/StoreFinder";
import FaqPage from "./views/FaqPage";
import AboutPage from "./views/AboutPage";
import ProductLinePage from "./views/ProductLinePage";
import LegalPage from "./views/LegalPage";
// import ErrorPage from "./views/ErrorPage";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import smoothscroll from "smoothscroll-polyfill";

interface Props {}

interface State {
  initialized: boolean;
  locale: string;
}

class App extends Component<Props, State> {
  private readonly history: History;
  isDevelopment = process.env.NODE_ENV === "development";

  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      initialized: false,
      locale: PathHelper.defaultLocale,
    };

    this.history = createBrowserHistory({});
    //@ts-ignore
    window.routerHistory = this.history;

    this.onLocaleChange = this.onLocaleChange.bind(this);
    this.route = this.route.bind(this);
  }

  public componentDidMount(): void {
    const path = window.location.pathname;
    let locale = PathHelper.localeOfPath(path);

    if (
      locale === "de" ||
      locale === "en" ||
      locale === "fr" ||
      locale === "it"
    ) {
      ServiceRegistry.init(locale).then(() =>
        this.setState({ initialized: true, locale })
      );
    } else {
      this.setState({ locale });
    }
    smoothscroll.polyfill();
  }

  renderErrorPage = () => {
    window.location.pathname = `${PathHelper.defaultLocale}/404`;
  };

  private buildStaticPagesPaths(staticPages, routeRegistry): Object {
    const { locale } = this.state;
    const staticPagesPaths = {};

    staticPages.forEach((pageKey) => {
      //@ts-ignore
      staticPagesPaths[pageKey] = routeRegistry.getByIdentifierAndLocale(
        pageKey,
        locale
      ).path;
    });

    return staticPagesPaths;
  }

  public render(): JSX.Element {
    const { initialized, locale } = this.state;

    if (!initialized && locale === PathHelper.defaultLocale) {
      return <div />;
    } else if (!initialized && locale !== "de" && locale !== "en") {
      this.renderErrorPage();
    }

    const productCatalog = ServiceRegistry.getProductCatalog();
    const routeRegistry = ServiceRegistry.getRouteRegistry();
    const routes = routeRegistry.getAllRoutes();
    const t = ServiceRegistry.getTranslations().getT();

    const hasNoSlashRoute = !routeRegistry.getByPath("/");

    const staticPages = [
      "faq",
      "search",
      "about",
      "storefinder",
      "terms",
      "privacy",
      "imprint",
    ];

    const staticPagesPaths = this.buildStaticPagesPaths(
      staticPages,
      routeRegistry
    );

    const productLines = productCatalog.getProductLines();

    return (
      <Router history={this.history}>
        <ScrollToTop>
          <React.Fragment>
            <div className="App">
              <div className="head">
                <TopBar
                  t={t}
                  locale={locale}
                  onLocaleChange={this.onLocaleChange}
                  history={this.history}
                  staticPagesPaths={staticPagesPaths}
                />
                <Header locale={locale} onLocaleChange={this.onLocaleChange} />
              </div>
              <div className="main">
                <Switch>
                  {hasNoSlashRoute && (
                    <Redirect from="/" to="/de" exact={true} />
                  )}
                  <Route
                    key={`/:locale/`}
                    exact={true}
                    path={`/:locale/`}
                    component={LandingPage}
                  />
                  {this.isDevelopment && (
                    <Route
                      key={`/:locale/temp`}
                      exact={true}
                      path={`/:locale/temp`}
                      component={TempPage}
                    />
                  )}
                  <Route
                    key={staticPagesPaths["faq"]}
                    exact={true}
                    path={staticPagesPaths["faq"]}
                    component={FaqPage}
                  />
                  <Route
                    key={staticPagesPaths["search"]}
                    exact={true}
                    path={staticPagesPaths["search"]}
                    component={SearchPage}
                  />
                  <Route
                    key={staticPagesPaths["about"]}
                    exact={true}
                    path={staticPagesPaths["about"]}
                    component={AboutPage}
                  />
                  <Route
                    key={staticPagesPaths["terms"]}
                    exact={true}
                    path={staticPagesPaths["terms"]}
                    render={(props) => (
                      <LegalPage type="terms" locale={locale} {...props} />
                    )}
                  />
                  <Route
                    key={staticPagesPaths["storefinder"]}
                    exact={true}
                    path={staticPagesPaths["storefinder"]}
                    component={StoreFinder}
                  />
                  <Route
                    key={staticPagesPaths["privacy"]}
                    exact={true}
                    path={staticPagesPaths["privacy"]}
                    render={(props) => (
                      <LegalPage type="privacy" locale={locale} {...props} />
                    )}
                  />
                  <Route
                    key={staticPagesPaths["imprint"]}
                    exact={true}
                    path={staticPagesPaths["imprint"]}
                    render={(props) => (
                      <LegalPage type="imprint" locale={locale} {...props} />
                    )}
                  />
                  {routes.map(this.route)}
                  <Route
                    key={`/:locale/.../:product`}
                    exact={false}
                    path={`/:locale/:productLine/:productCategory/:product`}
                    component={ProductPage}
                  />
                  <Route
                    key={`/:locale/.../:productCategory`}
                    exact={false}
                    path={`/:locale/:productLine/:productCategory`}
                    component={ProductCategoryPage}
                  />
                  <Route
                    key={`/:locale/:productLine`}
                    exact={false}
                    path={`/:locale/:productLine`}
                    component={ProductLinePage}
                  />
                  <Redirect to={`/${locale}/404`} />
                </Switch>
              </div>
            </div>
            <div className="footerX">
              <Footer t={t} locale={locale} productLines={productLines} />
            </div>
          </React.Fragment>
        </ScrollToTop>
      </Router>
    );
  }

  private onLocaleChange(locale: string, path: string): void {
    ServiceRegistry.setLocale(locale);
    this.setState({ locale });
    window.location.href = path;
    // @todo don't reload
  }

  private route(info: RouteInfo): JSX.Element {
    return (
      <Route
        key={info.path}
        exact={true}
        path={info.path ? info.path : "/"}
        component={StaticPage}
      />
    );
  }
}

export default App;
