import React from "react";
import styles from "./HeroTeaser.module.scss";
import ImageInfo from "../../entities/ImageInfo";
import { Image } from "../Image/Image";

interface Props {
  image: ImageInfo;
  imageMobile?: ImageInfo;
  fallbackImage?: ImageInfo;
}

class HeroTeaserMedia extends React.Component<Props> {
  video;

  constructor(props: Props) {
    super(props);
    this.video = React.createRef();
  }

  componentDidMount() {
    if (this.video.current) {
      this.video.current.addEventListener("ended", () => {
        this.video.current.load();
        this.video.current.pause();
      });
    }
  }

  render() {
    const { image, imageMobile, fallbackImage } = this.props;

    return (
      <>
        {image.mimeType.includes("video") && (
          <div className={styles.HeroTeaser__videoWrapper}>
            <video
              ref={this.video}
              width="100%"
              poster={fallbackImage && fallbackImage.url}
              autoPlay
              playsInline
              muted
              controls
            >
              <source src={image.url} type={image.mimeType} />
            </video>
          </div>
        )}
        {image.mimeType.includes("image") && (
          <div className={styles.HeroTeaser__imageWrapper}>
            <Image image={image} className={styles.image} />
          </div>
        )}
        {imageMobile !== undefined && (
          <>
            {imageMobile.mimeType.includes("image") && (
              <div className={styles.HeroTeaser__imageWrapperMobile}>
                <Image image={imageMobile} className={styles.imageMobile} disableRetina />
              </div>
            )}
          </>
        )}
        {imageMobile === undefined && (
          <>
            {image.mimeType.includes("image") && (
              <div className={styles.HeroTeaser__imageWrapper}>
                <Image image={image} className={styles.image} />
              </div>
            )}
          </>
        )}
      </>
    );
  }
}

export default HeroTeaserMedia;
