export default class ProductType {
  public readonly id: string;
  public readonly identifier: string;
  public readonly singleTitle: string;
  public readonly listTitle: string;

  constructor(id: string, identifier: string, singleTitle: string, listTitle: string) {
    this.id = id;
    this.identifier = identifier;
    this.singleTitle = singleTitle;
    this.listTitle = listTitle;
  }
}
