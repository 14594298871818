import Product from "../entities/Product";
import ProductCategory from "../entities/ProductCategory";
import ProductLine from "../entities/ProductLine";
import ProductVariant from "../entities/ProductVariant";
import RouteInfo from "../entities/RouteInfo";
import PathHelper from "./Helper";
import ServiceRegistry from "./ServiceRegistry";

function normalizePath(path: string): string {
  return path.endsWith("/") ? path : `${path}/`;
}

export default class RouteRegistry {
  private readonly byPath: Map<string, RouteInfo>;
  private readonly byIdentifier: Map<string, RouteInfo>;
  private readonly byPageId: Map<string, RouteInfo>;
  private routes: RouteInfo[];
  private locales: string[];

  constructor() {
    this.byPath = new Map<string, RouteInfo>();
    this.byIdentifier = new Map<string, RouteInfo>();
    this.byPageId = new Map<string, RouteInfo>();
    this.routes = [];
    this.locales = [];
  }

  public init(routeInfos: RouteInfo[]): void {
    this.byPath.clear();
    this.byIdentifier.clear();
    this.byPageId.clear();
    this.routes = [];
    this.locales = [];
    const locales = {};
    for (const routeInfo of routeInfos) {
      const { locale, pageId, path, identifier } = routeInfo;
      this.byPath.set(normalizePath(path), routeInfo);
      this.byIdentifier.set(`${locale}:${identifier}`, routeInfo);
      this.byPageId.set(`${locale}:${pageId}`, routeInfo);
      this.routes.push(routeInfo);
      if (!locales[locale]) {
        locales[locale] = true;
      }
    }

    Object.keys(locales)
    .forEach((locale) => this.locales.push(locale));
  }

  public addAll(routeInfos: RouteInfo[]) {
    const locales = {};
    for (const routeInfo of routeInfos) {
      const { locale, pageId, path, identifier } = routeInfo;
      this.byPath.set(normalizePath(path), routeInfo);
      this.byIdentifier.set(`${locale}:${identifier}`, routeInfo);
      this.byPageId.set(`${locale}:${pageId}`, routeInfo);
      this.routes.push(routeInfo);
      if (!locales[locale]) {
        locales[locale] = true;
      }
    }
    Object.keys(locales).forEach((locale) => {
      if (!this.locales.some((l) => l === locale)) {
        this.locales.push(locale);
      }
    });
  }

  public add(routeInfo: RouteInfo): void {
    const { locale, pageId, path, identifier } = routeInfo;
    this.byPath.set(normalizePath(path), routeInfo);
    this.byIdentifier.set(`${locale}:${identifier}`, routeInfo);
    this.byPageId.set(`${locale}:${pageId}`, routeInfo);
    this.routes.push(routeInfo);
    if (!this.locales.some((l) => l === locale)) {
      this.locales.push(locale);
    }
  }

  public getPathByPageIdAndLocale(
    pageId: string,
    locale: string
  ): string | undefined {
    const route = this.byPageId.get(`${locale}:${pageId}`);
    return route ? route.path : undefined;
  }

  public getByIdentifierAndLocale(
    identifier: string,
    locale: string
  ): RouteInfo | undefined {
    return this.byIdentifier.get(`${locale}:${identifier}`);
  }

  public getByPath(path: string): RouteInfo | undefined {
    return this.byPath.get(normalizePath(path));
  }

  public getAllRoutes(): RouteInfo[] {
    return this.routes;
  }

  public getLocales(): string[] {
    return this.locales;
  }

  public getTranslatedRoutes(language: string): Array<RouteInfo> {
    return this.routes.filter((r) => r.locale === language);
  }

  /**
   * Try to find the equivalent for the current path in the new locale.
   *
   * We will try to find the PageIdAndLocale of the current path exactly.
   * If we find it, we will look up the path with the same pageId and the
   * new locale.
   *
   * If we don't find it exactly, we will split the path into prefix and
   * suffix. We will then try to find the PageIdAndLocale of the prefix. We
   * will continue to split the prefix into prefix and suffix until the
   * prefix is empty or we find a match.
   * Afterwards we will return new path + suffix.
   *
   * /en/products/rocket -> no match
   * /en/products , rocket
   *   -> prefix matches, we find /de/produkte for the same pageId
   *   -> new path is /de/produkte/rocket
   */
  public translatePath(
    sourcePath: string,
    targetLocale: string
  ): string | undefined {
    let targetPath = this.translateProductXPath(sourcePath, targetLocale);
    if (targetPath) {
      return targetPath;
    }
    let prefix = sourcePath;
    let suffix = "";
    while (!targetPath && prefix && prefix.length > 0) {
      const prefixRoute = this.getByPath(prefix);
      if (prefixRoute) {
        targetPath = this.getPathByPageIdAndLocale(
          prefixRoute.pageId,
          targetLocale
        );
      }
      if (!targetPath) {
        // eslint-disable-next-line no-useless-escape
        const matches = /^(.*)\/([^\/]+)$/.exec(prefix);
        if (matches && matches.length > 2) {
          prefix = matches[1];
          suffix = suffix ? `${matches[2]}/${suffix}` : matches[2];
        } else {
          return PathHelper.pathFromSlug("/", targetLocale);
        }
      } else {
        if (suffix) {
          targetPath = `${targetPath}/${suffix}`;
        }
      }
    }
    return targetPath;
  }

  private translateProductXPath(
    sourcePath: string,
    targetLocale: string
  ): string | undefined {
    const split = sourcePath.split("/");
    if (split.length > 0 && !split[0]) {
      split.shift();
    }
    const catalog = ServiceRegistry.getProductCatalog();
    switch (split.length) {
      case 2: // ProductLine
        return RouteRegistry.productLineAlternatePath(
          catalog.getProductLineByPath(sourcePath),
          targetLocale
        );
      case 3: // ProductCategory
        return RouteRegistry.productCategoryAlternatePath(
          catalog.getProductCategoryByPath(sourcePath),
          targetLocale
        );
      case 4: // Product
        return RouteRegistry.productAlternatePath(
          catalog.getProductByPath(sourcePath),
          targetLocale
        );
      default:
        return undefined;
    }
  }

  public static productVariantAlternatePath(
    productVariant: ProductVariant | undefined,
    locale: string
  ): string | undefined {
    if (!productVariant) {
      return undefined;
    }
    const prefix = RouteRegistry.productAlternatePath(
      productVariant.product,
      locale
    );
    if (!prefix) {
      return undefined;
    }
    const slug = productVariant.alternates[locale];
    return slug ? `${prefix}/${slug}` : undefined;
  }

  public static productAlternatePath(
    product: Product | undefined,
    locale: string
  ): string | undefined {
    if (!product) {
      return undefined;
    }
    const prefix = RouteRegistry.productCategoryAlternatePath(
      product.category,
      locale
    );
    if (!prefix) {
      return undefined;
    }
    const slug = product.alternates[locale];
    return slug ? `${prefix}/${slug}` : undefined;
  }

  public static productCategoryAlternatePath(
    productCategory: ProductCategory | undefined,
    locale: string
  ): string | undefined {
    if (!productCategory) {
      return undefined;
    }
    const prefix = RouteRegistry.productLineAlternatePath(
      productCategory.productLine,
      locale
    );
    if (!prefix) {
      return undefined;
    }
    const slug = productCategory.alternates[locale];
    return slug ? `${prefix}/${slug}` : undefined;
  }

  public static productLineAlternatePath(
    productLine: ProductLine | undefined,
    locale: string
  ): string | undefined {
    return productLine
      ? PathHelper.pathFromSlug(productLine.alternates[locale], locale)
      : undefined;
  }
}
