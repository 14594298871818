import * as React from 'react';
import Headline from '../Headline/Headline';
import SubjectTeaser from './SubjectTeaser';
import styles from './SubjectsTeaser.module.scss';
import Topic from '../../entities/Topic';

interface Props {
  headline: string;
  items: Array<Topic>;
}

const SubjectsTeaser: React.SFC<Props> = ({headline, items}) => {
  return (
    <div className={`columns is-multiline ${styles.wrapper}`}>
      {/* <div className="column is-1 is-hidden-touch"></div>, */}
      <div className={`column is-11 is-offset-1  ${styles.headlineColumn}`}>
        <Headline component="h2" className={styles.headline} variant='h3'>{headline}</Headline>
      </div>
      <div className={`column is-12 ${styles.teaserRowWrapper}`}>
        <div className="columns is-mobile  is-multiline">
          {items.map((item) => {
            const path = item.path && item.path.length > 0 ? item.path : null;

            return (
              [
                <div key={item.id + 1} className="column is-1 is-hidden-touch"></div>,
                <div key={item.id + 2} className="column is-3-desktop is-6-mobile">
                  <SubjectTeaser
                    url={path}
                    image={item.image}
                    title={item.title}
                    copy={item.text}
                  />
                </div>
              ]
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SubjectsTeaser;
