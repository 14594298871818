import * as React from 'react';
import styles from './Select.module.scss';
import classNames from 'classnames';


interface Props {
  onSelect: (value: string) => void,
  className?: string
}

const Select: React.SFC<Props> = (props) => {
  const {children, onSelect, className} = props;
  const handleSelect = (e: React.FormEvent<HTMLSelectElement>) => onSelect(e.currentTarget.value);

  const selectClassName = classNames(styles.wrapper, className)

  return (
    <select onChange={handleSelect} className={selectClassName}>
      {children}
    </select>
  );
};

export default Select;
