import * as React from "react";
import { Component } from "react";
import { RouteComponentProps } from "react-router";

import TextTeaser from "../components/TextTeaser/TextTeaser";
// import CompositionTeaser from '../components/CompositionTeaser/CompositionTeaser';
// import Quote from '../components/Quote/Quote';
// import ProductTeaser from '../components/ProductTeaser/ProductTeaser';
import Button from "../components/Button/Button";
import Modal from "../components/Modal/Modal";
// import HeroTeaser from '../components/HeroTeaser/HeroTeaserTeaser';
import Headline from "../components/Headline/Headline";
// import Price from "../entities/Price";
import ProductSearch from "../widgets/ProductSearch";
import StoreFinder from "../widgets/StoreFinder";
import BigQuote from "../components/BigQuote/BigQuote";
import FaqList from "../components/FaqList/FaqList";
import Accordeon from "../components/Accordeon/Accordeon";
import AccordeonPanel from "../components/Accordeon/AccordeonPanel";
import Select from "../components/Select/Select";
// import ImageTextTeaser from '../components/ImageTextTeaser/ImageTextTeaserTeaser';
import ContactForm from "../components/ContactForm/ContactForm";
import ServiceRegistry from "../services/ServiceRegistry";
import IpuroLink from "../components/IpuroLink/IpuroLink";

interface Params {}

interface State {
  isModalOpen: boolean;
}

export interface Props extends RouteComponentProps<Params> {}

export default class TempPage extends Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      isModalOpen: false,
    };
  }

  public render(): JSX.Element {
    const t = ServiceRegistry.getTranslations().getT();

    return (
      <div>
        <IpuroLink isBacklink to="/">
          Is Backlink
        </IpuroLink>

        <ContactForm
          headline={t("contactform.headline")}
          nameLabel={t("contactform.input.name")}
          messageLabel={t("contactform.input.message")}
          buttonLabel={t("contactform.button")}
          subject={t("contactform.subject")}
          ipuroemail={t("contact.ipuroemail")}
        />

        {/* <ImageTextTeaser
          headline='Exclusive'
          imageUrl='http://via.placeholder.com/592x728'
        >
          Ein ganz Grober ist von und gegangen: Am Sonntagabend ist der Erfinder der beleibten Autokorrektur Mark Umsatzsteuer in Sand Französische an Herzversand gestorben. Der 67-jährige Pioneer hinterlässt eine Frau und zwei Rinder.
        </ImageTextTeaser> */}

        <Select onSelect={(value) => console.log("Grösse:", value)}>
          <option value="s">Grösse S</option>
          <option value="m">Grösse M</option>
          <option value="l">Grösse L</option>
        </Select>
        <BigQuote headline="Zitat-Komponente">
          Alles neu, alles schöner: Das verändert sich bei ipuro. Lorem Ipsum
          dolor.
        </BigQuote>
        {/* <HeroTeaser imageUrl='http://via.placeholder.com/1920x704' headline='Inspiriert von der Natur'/> */}
        <FaqList />
        {/* <HeroTeaser imageUrl='http://via.placeholder.com/1920x704'>
          <div style={{
            width: '342px',
            height: '408px',
            backgroundColor: 'white',
            marginTop: '80px',
            marginRight: '56px',
          }} />
        </HeroTeaser> */}
        <Button onClick={() => null} type="primary">
          Primary
        </Button>
        <Button onClick={() => null} type="secondary">
          Secondary
        </Button>

        <Accordeon>
          <AccordeonPanel title="Panel 1">
            Accordeon Panel 1 Inhalt
          </AccordeonPanel>
          <AccordeonPanel title="Panel 2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sit
            amet maximus dui. Nullam tempor purus mi, sit amet laoreet diam
            pulvinar nec. Praesent ipsum quam, tincidunt id imperdiet et,
            consectetur in nunc. Nunc vel malesuada risus. Proin tristique risus
            a malesuada blandit. Proin malesuada non ipsum vitae porttitor.
            Vivamus fermentum justo in fermentum rhoncus. Maecenas et velit
            bibendum, porta elit eget, pulvinar mi. Sed commodo, ligula sit amet
            mollis mattis, neque diam malesuada mi, sit amet venenatis magna
            dolor vulputate odio. Integer consequat semper urna et semper.
            Vivamus venenatis eu purus eu faucibus. Aliquam vitae neque lectus.
            Nunc semper urna eget posuere accumsan. Nullam vestibulum tortor sed
            tempus pellentesque. Vivamus euismod tincidunt tempor. Vivamus
            porttitor nibh erat, at imperdiet nulla bibendum et. Suspendisse
            lacinia vulputate molestie. Etiam quis pellentesque tellus.
            Vestibulum ante ipsum primis in faucibus orci luctus et ultrices
            posuere cubilia Curae; Pellentesque habitant morbi tristique
            senectus et netus et malesuada fames ac turpis egestas. In tempus
            risus diam. Mauris et augue ut sem rhoncus rutrum aliquam sit amet
            est. Integer at convallis lorem. Sed interdum, arcu ac porta
            condimentum, quam libero malesuada enim, eget ornare tortor ipsum
            sit amet tellus. Fusce convallis lorem quis blandit auctor. In
            tempus interdum scelerisque. Aliquam erat volutpat. Maecenas id diam
            eget enim malesuada sagittis a nec ex.
          </AccordeonPanel>
          <AccordeonPanel title="Panel 3">
            Accordeon Panel 3 Inhalt
          </AccordeonPanel>
        </Accordeon>

        <TextTeaser
          headline="Your fragrance. Anywhere and anytime."
          linkLabel="Über Ipuro"
          link="/about"
        >
          Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
          enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
          ut aliquip ex ea commodo consequat. Tempor incididunt ut labore et
          dolore magna.
        </TextTeaser>
        <TextTeaser isSmall linkLabel="Alle neuen Produkte" link="/about">
          Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
          enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
          ut aliquip ex ea commodo consequat. Tempor incididunt ut labore et
          dolore magna.
        </TextTeaser>

        {/* Modal */}
        <Button
          onClick={() => this.setState({ isModalOpen: true })}
          type="primary"
        >
          Open Modal
        </Button>

        {this.state.isModalOpen && (
          <Modal onClose={() => this.setState({ isModalOpen: false })}>
            <span>
              Du wirst jetzt sicher zu depot-online.com weitergeleitet...
            </span>
            {/* eslint-disable-next-line jsx-a11y/alt-text */}
            <img src="http://placekitten.com/200/300" />
          </Modal>
        )}

        {/* Headline */}
        <Headline>Default Headline</Headline>
        <Headline component="h2">Component h2</Headline>
        <Headline component="h3">Component h3</Headline>
        <Headline component="h4">Component h4</Headline>
        <Headline component="h1" variant="h4">
          Component h1 & Variant h4
        </Headline>

        {/* Product Teaser - big */}
        {/*
        <div className="columns">
          <div className="column">
            <ProductTeaser
              image="http://placekitten.com/310/392"
              title="Product Title"
              type="Product Typ (zB Kerze)"
              price={new Price(42, "CUR")}
              badgeLabel="Neu"
              numVariants={1}
            />
          </div>
          <div className="column">
            <ProductTeaser
              image="http://placekitten.com/310/392"
              title="Product Title"
              type="Product Typ (zB Kerze)"
              price={new Price(42, "CUR")}
              numVariants={1}
            />
          </div>
          <div className="column">
            <ProductTeaser
              image="http://placekitten.com/310/392"
              title="Product Title"
              type="Product Typ (zB Kerze)"
              price={new Price(42, "CUR")}
              badgeLabel="Reduziert"
              numVariants={1}
            />
          </div>
        </div> */}

        {/* Product Teaser - small */}
        {/*
        <div className="columns">
          <div className="column">
            <ProductTeaser
              image="http://placekitten.com/220/184"
              title="Product Title"
              type="Product Typ (zB Kerze)"
              price={new Price(42, "CUR")}
              productLine="Product Line"
              badgeLabel="Neu"
              size="small"
              numVariants={1}
            />
          </div>
          <div className="column">
            <ProductTeaser
              image="http://placekitten.com/220/184"
              title="Product Title"
              type="Product Typ (zB Kerze)"
              price={new Price(42, "CUR")}
              productLine="Product Line"
              size="small"
              numVariants={1}
            />
          </div>
          <div className="column">
            <ProductTeaser
              image="http://placekitten.com/220/184"
              title="Product Title"
              type="Product Typ (zB Kerze)"
              price={new Price(42, "CUR")}
              productLine="Product Line"
              badgeLabel="Reduziert"
              size="small"
              numVariants={1}
            />
          </div>
          <div className="column">
            <ProductTeaser
              image="http://placekitten.com/220/184"
              title="Product Title"
              type="Product Typ (zB Kerze)"
              price={new Price(42, "CUR")}
              productLine="Product Line"
              badgeLabel="%"
              size="small"
              numVariants={1}
            />
          </div>
          <div className="column">
            <ProductTeaser
              image="http://placekitten.com/220/184"
              title="Product Title"
              type="Product Typ (zB Kerze)"
              price={new Price(42, "CUR")}
              productLine="Product Line"
              badgeLabel="Black Friday"
              size="small"
              numVariants={1}
            />
          </div>
          <div className="column">
            <ProductTeaser
              image="http://placekitten.com/220/184"
              title="Product Title"
              type="Product Typ (zB Kerze)"
              price={new Price(42, "CUR")}
              productLine="Product Line"
              badgeLabel="Neu"
              size="small"
              numVariants={1}
            />
          </div>
        </div>
 */}

        {/* <CompositionTeaser
          headline='Alles neu, alles schöner:  Das verändert sich bei ipuro'
          subHeadline='Über ipuro'
          largeImage='http://via.placeholder.com/586x728'
          smallImage='http://via.placeholder.com/342x352'
        /> */}
        {/* <CompositionTeaser
          headline='Alles neu, alles schöner:  Das verändert sich bei ipuro'
          subHeadline='Über ipuro'
          largeImage='http://via.placeholder.com/586x728'
          smallImage='http://via.placeholder.com/342x352'
          reversePosition
        >
          <Quote
            personsName='Christian Greis'
            personsRole='Inhaber'
            linkLabel='Mehr erfahren'
            link='/de/more'
          >
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
          </Quote>
        </CompositionTeaser> */}
        {/* <CompositionTeaser
          headline='Alles neu, alles schöner:  Das verändert sich bei ipuro'
          subHeadline='Über ipuro'
          largeImage='http://via.placeholder.com/586x728'
          smallImage='http://via.placeholder.com/342x352'
        >
          <div>
            Optional!
          </div>
        </CompositionTeaser> */}
        <ProductSearch />
        <StoreFinder />
      </div>
    );
  }
}
