import * as React from 'react';
import * as ReactDOM from 'react-dom';

interface Props {
    children?: React.ReactNode;
    reference?: HTMLElement | null | undefined;
}

class Portal extends React.Component<Props, {}> {
    render() {
        return ReactDOM.createPortal(this.props.children, this.props.reference || document.body);
    }
}

export default Portal;
