import RouteRegistry from "../services/RouteRegistry";
import ImageInfo from "./ImageInfo";
import Price from "./Price";
import Product from "./Product";

export default class ProductVariant {
  public readonly id: string;
  public readonly slug: string;
  public readonly title: string;
  public readonly image: ImageInfo;
  public readonly link?: string;
  public readonly linkType: string;
  public readonly alternates: { [locale: string]: string };
  public readonly product: Product;
  public readonly isNew: boolean;
  public readonly isOnSale: boolean;
  public readonly limited: boolean;
  public readonly price?: Price;
  public readonly basicPrice?: Price;

  constructor(
    id: string,
    slug: string,
    title: string,
    image: ImageInfo,
    link: string | undefined,
    linkType: string,
    alternates: { [locale: string]: string },
    product: Product,
    isNew: boolean,
    isOnSale: boolean,
    limited: boolean,
    price?: Price,
    basicPrice?: Price
  ) {
    this.id = id;
    this.slug = slug;
    this.title = title;
    this.image = image;
    this.link = link;
    this.linkType = linkType;
    this.alternates = alternates;
    this.product = product;
    this.price = price;
    this.isNew = isNew;
    this.isOnSale = isOnSale;
    this.limited = limited;
    this.basicPrice = basicPrice;
  }

  public getAlternatePaths(): { locale: string; path: string }[] {
    const paths: { locale: string; path: string }[] = [];
    Object.keys(this.alternates).forEach(locale => {
      const path = RouteRegistry.productVariantAlternatePath(this, locale);
      if (path) {
        paths.push({ locale, path });
      }
    });
    return paths;
  }
}
