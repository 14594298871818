/* eslint-disable array-callback-return */
/* eslint-disable no-new-func */
import React, { ChangeEvent, Component, ReactNode } from "react";
import Product from "../entities/Product";
import ProductCategory from "../entities/ProductCategory";
import ProductLine from "../entities/ProductLine";
import ServiceRegistry from "../services/ServiceRegistry";
import SearchResultItem from "../components/SearchResultItem/SearchResultItem";
import InputText from "../components/InputText/InputText";
import Button from "../components/Button/Button";
import Headline from "../components/Headline/Headline";

import styles from "./ProductSearch.module.scss";

export interface Props {}

interface State {
  term: string;
  results?: Array<ProductLine | ProductCategory | Product>;
  t?: Function;
}

export default class ProductSearch extends Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      term: "",
      results: undefined,
      t: ServiceRegistry.getTranslations().getT() || new Function(),
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onReset = this.onReset.bind(this);
    this.renderResults = this.renderResults.bind(this);
  }

  private onChange(event: ChangeEvent<HTMLInputElement>): void {
    event.preventDefault();
    const term = event.target.value;
    this.setState({ term });
  }

  public componentDidMount() {
    //@ts-ignore
    const termFromUrl = window.routerHistory.location.search.replace(
      "?search=",
      ""
    );
    this.setState(
      {
        term: termFromUrl.replace("%20", " "),
      },
      () => {
        if (termFromUrl) {
          this.onSubmit(null);
        }
      }
    );
  }

  private onReset() {
    this.setState({
      term: "",
      results: undefined,
    });
  }

  private onSubmit(event): void {
    if (event) {
      event.preventDefault && event.preventDefault();
    }

    const { term } = this.state;
    const productCatalog = ServiceRegistry.getProductCatalog();
    this.setState({ results: productCatalog.search(term) });
  }

  private renderResults(
    results: Array<ProductLine | ProductCategory | Product>
  ): JSX.Element {
    const { t } = this.state;

    const productLines: Array<ReactNode> = [];
    const productCategory: Array<ReactNode> = [];
    const products: Array<ReactNode> = [];

    results.map((result) => {
      if (result instanceof ProductLine) {
        productLines.push(this.renderProductLine(result as ProductLine));
      }
      if (result instanceof ProductCategory) {
        productCategory.push(
          this.renderProductCategory(result as ProductCategory)
        );
      }
      if (result instanceof Product) {
        products.push(this.renderProduct(result as Product));
      }
    });

    return (
      <div>
        {products.length !== 0 && [
          <h1
            key={t && t("global.products.plural")}
            className={styles.ProductSearch__results_headline}
          >
            {t && t("global.products.plural")}
          </h1>,
          products,
        ]}
        {productCategory.length !== 0 && [
          <h1
            key={t && t("global.productCategory.plural")}
            className={styles.ProductSearch__results_headline}
          >
            {t && t("global.productCategory.plural")}
          </h1>,
          productCategory,
        ]}
        {productLines.length !== 0 && [
          <h1
            key={t && t("global.productLines.plural")}
            className={styles.ProductSearch__results_headline}
          >
            {t && t("global.productLines.plural")}
          </h1>,
          productLines,
        ]}
      </div>
    );
  }

  private renderProductLine(productLine: ProductLine): JSX.Element {
    const { t } = this.state;
    const productCatalog = ServiceRegistry.getProductCatalog();
    return (
      <SearchResultItem
        key={productLine.id}
        headline={productLine.title}
        link={productCatalog.getPathOfLine(productLine)}
        linkLabel={t && t("search.results.link.productLine")}
      >
        {productLine.description}
      </SearchResultItem>
    );
  }

  private renderProductCategory(productCategory: ProductCategory): JSX.Element {
    const { t } = this.state;
    const productCatalog = ServiceRegistry.getProductCatalog();
    return (
      <SearchResultItem
        key={productCategory.id}
        headline={productCategory.title}
        link={productCatalog.getPathOfCategory(productCategory)}
        linkLabel={t && t("search.results.link.productCategory")}
      >
        {productCategory.description}
      </SearchResultItem>
    );
  }

  private renderProduct(product: Product): JSX.Element {
    const { t } = this.state;
    const productCatalog = ServiceRegistry.getProductCatalog();
    return (
      <SearchResultItem
        key={product.id}
        headline={`${product.title} · ${product.category.title}`}
        link={productCatalog.getPathOfProduct(product)}
        linkLabel={t && t("search.results.link.products")}
      >
        {product.description}
      </SearchResultItem>
    );
  }

  private getSearchConclusionString(): string {
    const { results, t } = this.state;
    let conclusionString = "";

    const appendToConclusionString = (conclusionString, identifier, count) => {
      if (count > 0) {
        conclusionString = `${conclusionString}, ${count} ${
          count === 1
            ? t && t(`global.${identifier}.singular`)
            : t && t(`global.${identifier}.plural`)
        }`;
      }
      return conclusionString;
    };

    if (t && results) {
      const productsLength = results.filter((i) => i instanceof Product).length;
      conclusionString = appendToConclusionString(
        conclusionString,
        "products",
        productsLength
      );

      const productCategoryLength = results.filter(
        (i) => i instanceof ProductCategory
      ).length;
      conclusionString = appendToConclusionString(
        conclusionString,
        "productCategory",
        productCategoryLength
      );

      const productLineLength = results.filter((i) => i instanceof ProductLine)
        .length;
      conclusionString = appendToConclusionString(
        conclusionString,
        "productLines",
        productLineLength
      );
    }

    return conclusionString.substring(1);
  }

  public render(): JSX.Element {
    const { term, results, t } = this.state;

    return (
      <div>
        <Headline
          component="h1"
          variant="h2"
          className={styles.ProductSearch__headline}
        >
          {t && t("search.headline")}
        </Headline>

        <div className="columns is-multiline">
          <div className="column is-9">
            <InputText
              value={term}
              onSubmit={this.onSubmit}
              onChange={this.onChange}
              onReset={this.onReset}
              placeholder={t && t("search.placeholder")}
            />
            <p className={styles.ProductSearch__results_conclusion}>
              {results && results.length === 0 && t && t("search.noResults")}
              {results &&
                results.length !== 0 &&
                this.getSearchConclusionString()}
            </p>
          </div>
          <div className="column is-3">
            <Button onClick={this.onSubmit}>{t && t("search.label")}</Button>
          </div>
          <div className="column is-12">
            {results && results.length !== 0 && this.renderResults(results)}
          </div>
        </div>
      </div>
    );
  }
}
