/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, Component, FormEvent } from "react";

import classNames from "classnames";

import Icon from "../Icon/Icon";

import styles from "./InputText.module.scss";

interface Props {
  placeholder?: string;
  onChange: Function;
  onReset: Function;
  onSubmit?: Function;
  label?: string;
  isTextarea?: boolean;
  marginBottom?: boolean;
  value?: string;
}

interface State {
  value: string;
}

class InputText extends Component<Props, State> {
  inputField: any;
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      value: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.clearText = this.clearText.bind(this);
  }

  private onChange(event: ChangeEvent<HTMLInputElement>): void {
    event.preventDefault();

    this.props.onChange(event);
    const value = event.target.value;

    this.setState({ value });
  }

  public componentWillReceiveProps(nextProps) {
    const { value } = nextProps;
    if (this.state.value !== value) {
      this.setState({
        value,
      });
    }
  }

  private onSubmit(event: FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    const { onSubmit } = this.props;

    const { value } = this.state;

    onSubmit && onSubmit(value);
  }

  private clearText(): void {
    const { onReset } = this.props;
    onReset();
    this.setState({
      value: "",
    });
  }

  public render(): JSX.Element {
    const { value } = this.state;
    const {
      placeholder,
      label,
      isTextarea,
      marginBottom,
      ...rest
    } = this.props;

    let Tag: any = "input";

    if (isTextarea) {
      Tag = "textarea";
    }

    const inputClassName = classNames({
      [styles.InputText__input]: true,
      [styles.InputText__input___marginbottom]: marginBottom,
    });

    const iconWrapperClassName = classNames({
      [styles.InputText__icon_wrapper]: true,
      [styles.InputText__icon_wrapper__withLabel]: label,
    });

    const id = Math.random().toString();

    return (
      <div className={styles.InputText}>
        <form onSubmit={this.onSubmit}>
          {label && (
            <label className={styles.InputText__label} htmlFor={id}>
              {label}
            </label>
          )}
          <Tag
            id={label ? label + placeholder : placeholder}
            type="text"
            className={inputClassName}
            onChange={this.onChange}
            value={value}
            ref={(input) => {
              this.inputField = input;
            }}
            placeholder={placeholder || ""}
            rows="4"
            {...rest}
          />

          {value !== "" && (
            <a className={iconWrapperClassName} onClick={this.clearText}>
              <Icon
                key="topBarIcon"
                icon={"close"}
                size={"small"}
                className={styles.InputText__icon}
              />
            </a>
          )}
        </form>
      </div>
    );
  }
}

export default InputText;
