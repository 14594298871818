import React from 'react';

import styles from './BigQuote.module.scss';

interface Props {
    children: string,
    headline: string,
}

const BigQuote: React.SFC<Props> =({headline, children}) => {
    return (
        <div className={styles.BigQuote}>
            <div className='columns'>
                <div className='column is-10 is-offset-1'>
                    <h3 className={styles.BigQuote__quote}>
                        “{children}“
                    </h3>
                    <h4 className={styles.BigQuote__headline}>
                        {headline}
                    </h4>
                </div>
            </div>
        </div>
    );
};

export default BigQuote;