/* eslint-disable no-useless-computed-key */
// @ts-ignore
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import React, { Component } from "react";
import PathHelper from "../services/Helper";
import ServiceRegistry from "../services/ServiceRegistry";

export interface Props {
  text: any;
  replacements?: { [key: string]: string };
  className?: string;
}

interface State {}

class Renderer {
  private readonly locale: string;
  private readonly replacements: { [key: string]: string };
  private options: {} = {};

  constructor(locale: string, replacements: { [p: string]: string }) {
    this.locale = locale;
    this.replacements = replacements;
    this.renderEntryHyperlink = this.renderEntryHyperlink.bind(this);
    this.renderHyperlink = this.renderHyperlink.bind(this);
    this.renderEmbeddedAssetBlock = this.renderEmbeddedAssetBlock.bind(this);

    this.options = {
      renderNode: {
        ["entry-hyperlink"]: this.renderEntryHyperlink,
        ["hyperlink"]: this.renderHyperlink,
        ["embedded-asset-block"]: this.renderEmbeddedAssetBlock,
      },
    };
  }

  public static replace(
    template: string,
    values: { [key: string]: string }
  ): string {
    return template.replace(/{([^}]+)}/g, (match, g1) => {
      return values[g1] ? values[g1] : match;
    });
  }

  public render(doc: any): string {
    const { options, replacements } = this;
    const html = documentToHtmlString(doc, options);
    return replacements ? Renderer.replace(html, replacements) : html;
  }

  private renderEntryHyperlink(node: any): string {
    if (node && node.data && node.data.target && node.data.target.fields) {
      const slug = node.data.target.fields.slug;
      const path = PathHelper.pathFromSlug(slug, this.locale);
      const body = documentToHtmlString(this.doc(node.content), this.options);
      return `<a href="${path}" onClick="event.preventDefault(); window.routerHistory.push('${path}')">${body}</a>`;
    } else {
      return "";
    }
  }

  private renderHyperlink(node: any): string {
    const { data, content } = node;
    const body = documentToHtmlString(this.doc(content), this.options);
    const uri = Renderer.replace(data.uri, this.replacements);
    const isRemoteTarget = /^(([a-zA-Z]+:)?\/\/|[a-z]+:)/.test(uri);
    const targetBlank = /^(\/\/|(http)s?:)/.test(uri);
    return isRemoteTarget
      ? targetBlank
        ? `<a href="${uri}" target="_blank">${body}</a>`
        : `<a href="${uri}">${body}</a>`
      : `<a href="${uri}" onClick="event.preventDefault();window.routerHistory.push('${uri}')">${body}</a>`;
  }

  private renderEmbeddedAssetBlock(node: any): string {
    const { data = {} } = node;
    const { target = {} } = data;
    const { fields = {} } = target;
    const { file = {} } = fields;
    const {
      details: { image },
      contentType,
      url,
      title,
    } = file;
    const { width, height } = image;
    const widthHalf = Math.floor(width / 2);
    const heightHalf = Math.floor(height / 2);
    const nonRetinaUrl = `${url}?w=${widthHalf}&h=${heightHalf}`;

    if (contentType) {
      if (contentType.startsWith("image/")) {
        return `<img src="${nonRetinaUrl}" srcSet="${nonRetinaUrl} 1x, ${url} 2x" alt="${title}" />`;
      }
    }
    console.log(`Error: Invalid asset: ${JSON.stringify(node)}`);
    return "";
  }

  private doc(content: any): any {
    return {
      nodeType: "document",
      data: {},
      content,
    };
  }
}

export default class RichText extends Component<Props, State> {
  public render(): JSX.Element {
    const { text, replacements = {}, className } = this.props;
    if (!text) {
      return <div />;
    }
    const locale = ServiceRegistry.getContentService().getLocale();
    const html = new Renderer(locale, replacements)
      .render(text)
      .replace(/\n/g, "<br/>");
    return (
      <div className={className} dangerouslySetInnerHTML={{ __html: html }} />
    );
  }
}
