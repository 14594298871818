import * as React from "react";
import {Component} from "react";
import {RouteComponentProps} from "react-router";

import HeroTeaser from '../components/HeroTeaser/HeroTeaserTeaser';
import Headline from '../components/Headline/Headline';
import FaqList from '../components/FaqList/FaqList';
import ContactForm from '../components/ContactForm/ContactForm';
import FaqItem from "../entities/FaqItem";
import ServiceRegistry from '../services/ServiceRegistry';
import PageData from '../entities/PageData';

interface Params {
}

interface State {
  isModalOpen: boolean,
  faqItems?: FaqItem[],
  page?: PageData;
}

export interface Props extends RouteComponentProps<Params> {
}

export default class FaqPage extends Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      isModalOpen: false,
    };
  }

  public componentDidMount(): void {
    const contentService = ServiceRegistry.getContentService();

    contentService
      .getAllFaqItems()
      .then((faqItems) => this.setState({faqItems}));


    contentService
      .getPageByIdentifier("faq")
      .then((page) => this.setState({page}));


  }

  public render(): JSX.Element {
    const t = ServiceRegistry.getTranslations().getT();
    const {faqItems, page} = this.state;

    return (
      <div>
        <Headline component='h1'>
          {t('topBar.faq.label')}
        </Headline>
        {page && page && page.headerImage &&
          <HeroTeaser image={page && page.headerImage} imageMobile={page.headerImageMobile} />
        }
        <FaqList faqItems={faqItems} />
        <ContactForm
          headline={t('contactform.headline')}
          nameLabel={t('contactform.input.name')}
          messageLabel={t('contactform.input.message')}
          buttonLabel={t('contactform.button')}
          subject={t('contactform.subject')}
          ipuroemail={t('contact.ipuroemail')}
        />
      </div>
    );
  }
}
