import * as React from "react";
import {Component} from "react";
import {RouteComponentProps} from "react-router";

import ProductSearch from "../widgets/ProductSearch";

interface Params {
}

interface State {
  isModalOpen: boolean
}

export interface Props extends RouteComponentProps<Params> {
}

export default class SearchPage extends Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      isModalOpen: false,
    };
  }

  public render(): JSX.Element {
    return (
      <div>
        <ProductSearch/>
      </div>
    );
  }
}
