type SubstitutesInterface = string[] | string;


const replaceTranslationKeys = (phrase: string, substitutes: SubstitutesInterface) => {
  let newPhrase: string = phrase;

  if (typeof substitutes === "string") {
    return phrase.replace('{0}', substitutes);
  }

  substitutes.forEach((substitute, i) => {
    newPhrase = newPhrase.replace(`{${i}}`, substitutes[i]);
  });

  return newPhrase;
}

export default replaceTranslationKeys;
