import LegalItem from "../entities/LegalItem";
import FaqItem from "../entities/FaqItem";
import PageData from "../entities/PageData";
import ProductCatalog from "../entities/ProductCatalog";
import ProductLine from "../entities/ProductLine";
import RouteInfo from "../entities/RouteInfo";
import Store from "../entities/Store";
import Topic from "../entities/Topic";
import Translations from "./Translations";
import ContentService from "./ContentService";
import PartnerShop from "../entities/PartnerShop";

class CacheEntry<T> {
  public payload?: T;
  public timeout: number;

  constructor(timeout: number, payload?: T) {
    this.payload = payload;
    this.timeout = timeout;
  }

  public clear(): void {
    this.timeout = 0;
    this.payload = undefined;
  }
}

class CacheBucket<T> {
  private readonly entries: Map<string, CacheEntry<T>> = new Map<
    string,
    CacheEntry<T>
  >();

  public getEntry(key: string): CacheEntry<T> {
    let entry = this.entries.get(key);
    if (entry) {
      return entry;
    }
    entry = new CacheEntry<T>(0, undefined);
    this.entries.set(key, entry);
    return entry;
  }

  public clear(): void {
    this.entries.clear();
  }
}

export default class CachedContentService implements ContentService {
  private readonly backend: ContentService;
  private readonly ttlMs: number;

  private readonly locales: CacheEntry<string[]>;
  private readonly productLineList: CacheEntry<ProductLine[]>;
  private readonly productLines: CacheBucket<ProductLine>;
  private readonly productCatalog: CacheBucket<ProductCatalog>;
  private readonly pages: CacheBucket<PageData>;
  private readonly pagesByIdentifier: CacheBucket<PageData>;
  private readonly stores: CacheEntry<Store[]>;
  private readonly faqItems: CacheEntry<FaqItem[]>;
  private readonly legalItems: CacheEntry<LegalItem[]>;
  private readonly partnerShops: CacheEntry<PartnerShop[]>;
  private readonly topics: CacheBucket<Topic[]>;

  private locale: string;

  constructor(backend: ContentService, ttlMs: number, locale: string) {
    this.backend = backend;
    this.ttlMs = ttlMs;
    this.locales = new CacheEntry<string[]>(0, undefined);
    this.productLineList = new CacheEntry<ProductLine[]>(0, undefined);
    this.productLines = new CacheBucket<ProductLine>();
    this.productCatalog = new CacheBucket<ProductCatalog>();
    this.pages = new CacheBucket<PageData>();
    this.pagesByIdentifier = new CacheBucket<PageData>();
    this.stores = new CacheEntry<Store[]>(0, undefined);
    this.faqItems = new CacheEntry<FaqItem[]>(0, undefined);
    this.legalItems = new CacheEntry<LegalItem[]>(0, undefined);
    this.partnerShops = new CacheEntry<PartnerShop[]>(0, undefined);
    this.topics = new CacheBucket<Topic[]>();
    this.locale = locale;
  }

  public getLocales(): Promise<string[]> {
    return this.get(this.locales, () => this.backend.getLocales());
  }

  public setLocale(locale: string): void {
    this.backend.setLocale(locale);
    this.locale = locale;
    this.reset();
  }

  public getLocale(): string {
    return this.locale;
  }

  public getAllRoutes(): Promise<RouteInfo[]> {
    return this.backend.getAllRoutes();
  }

  public getPage(id: string, locale?: string): Promise<PageData> {
    const key = `${locale || this.locale}:${id}`;
    return this.get(this.pages.getEntry(key), () =>
      this.backend.getPage(id, locale)
    );
  }

  public getPageByIdentifier(
    identifier: string,
    locale?: string
  ): Promise<PageData | undefined> {
    const key = `${locale || this.locale}:${identifier}`;
    return this.get(this.pagesByIdentifier.getEntry(key), () =>
      this.backend.getPageByIdentifier(identifier, locale)
    );
  }

  public getProductCatalog(
    alternateLocales: string[]
  ): Promise<ProductCatalog> {
    const key = `${this.locale}:${alternateLocales.join(":")}`;
    return this.get(this.productCatalog.getEntry(key), () =>
      this.backend.getProductCatalog(alternateLocales)
    );
  }

  public getTranslations(): Promise<Translations> {
    return this.backend.getTranslations();
  }

  public getAllStores(): Promise<Store[]> {
    return this.get(this.stores, () => this.backend.getAllStores());
  }

  public getAllFaqItems(): Promise<FaqItem[]> {
    return this.get(this.faqItems, () => this.backend.getAllFaqItems());
  }

  public getAllLegalItems(): Promise<LegalItem[]> {
    return this.get(this.legalItems, () => this.backend.getAllLegalItems());
  }

  public getAllPartnerShops(): Promise<PartnerShop[]> {
    return this.get(this.partnerShops, () => this.backend.getAllPartnerShops());
  }

  public getTopics(blockId: string): Promise<Topic[]> {
    return this.get(this.topics.getEntry(blockId), () =>
      this.backend.getTopics(blockId)
    );
  }

  private get<T>(cache: CacheEntry<T>, loader: () => Promise<T>): Promise<T> {
    const now = Date.now();
    if (cache && cache.timeout > now) {
      return new Promise<T>((resolve, _reject) => resolve(cache.payload!));
    }

    return loader().then((payload) => {
      cache.payload = payload;
      cache.timeout = now + this.ttlMs;
      return payload;
    });
  }

  private reset(): void {
    this.productLineList.clear();
    this.productLines.clear();
    this.productCatalog.clear();
  }
}
