export default class RouteInfo {
  public readonly pageId: string;
  public readonly identifier: string;
  public readonly locale: string;
  public readonly path: string;
  public readonly navigationTitle: string;

  constructor(pageId: string,
              identifier: string,
              locale: string,
              path: string,
              navigationTitle: string) {
    this.pageId = pageId;
    this.identifier = identifier;
    this.locale = locale;
    this.path = path;
    this.navigationTitle = navigationTitle;
  }
}
