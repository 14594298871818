import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import Icon from '../Icon/Icon';

import styles from './IpuroLink.module.scss';
import { ReactNode } from 'react';

interface Props {
    to: string,
    children: ReactNode | string,
    isBacklink?: boolean,
    type?: 'primary' | 'secondary',
    additionalClassName?: any,
}

const IpuroLink: React.SFC<Props> =({to, children, type='primary', isBacklink, additionalClassName}) => {

    const className = classNames({
        [styles.IpuroLink]: true,
        [styles.IpuroLink___secondary]: type === 'secondary',
        [styles.IpuroLink___backlink]: isBacklink,
        [additionalClassName]: additionalClassName,
    });

    const isString = typeof(children) === "string";

    return (
        <Link
            className={className}
            to={to}
        >
            {isBacklink && isString &&
                <Icon icon='chevronLeft' size='small' className={styles.IpuroLink__icon}/>
            }
            {children}
            {type === 'primary' && !isBacklink  && isString &&
                <Icon icon='chevronRight' size='small' className={styles.IpuroLink__icon}/>
            }
        </Link>
    );
};

export default IpuroLink;
