import * as React from 'react';
import styles from './Headline.module.scss';
import classNames from 'classnames';

type HeadlineInteface = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'h7';
type HeadlineColor = 'black' | 'grey';

interface Props {
  component?: HeadlineInteface,
  variant?: HeadlineInteface,
  className?: string,
  color?: HeadlineColor
}

const Headline: React.SFC<Props> = ({children, className, color = "black", component = "h1", variant = component}) => {
  const CustomTag: any = component;

  const headlineClass = classNames(styles.wrapper, className, {
    [styles[variant]]: true,
    [styles[color]] : true,
  });

  return (
    <CustomTag className={headlineClass}>{children}</CustomTag>
  );
};

export default Headline;
