import * as React from "react";
import {Component} from "react";
import {RouteComponentProps} from "react-router";

import CompositionTeaser from '../components/CompositionTeaser/CompositionTeaser';
import CompositionTeaserMobile from '../components/CompositionTeaserMobile/CompositionTeaserMobile';
import HeroTeaser from '../components/HeroTeaser/HeroTeaserTeaser';
import RichText from "../components/RichText";
import TextTeaser from '../components/TextTeaser/TextTeaser';
import ContentBlock from "../entities/ContentBlock";
import PageData from "../entities/PageData";
import Topic from "../entities/Topic";
import ServiceRegistry from "../services/ServiceRegistry";
import RichTextTeaser from "../components/RichTextTeaser/RichTextTeaser";
import SubjectsTeaser from '../components/SubjectsTeaser/SubjectsTeaser';
import SeoTags from '../components/SeoTags';

interface Params {
}

export interface Props extends RouteComponentProps<Params> {
}

interface State {
  topics: Topic[];
  page?: PageData;
}

export default class LandingPage extends Component<Props, State> {

  constructor(context: any) {
    super(context);
    this.state = {
      topics: []
    };
    this.renderContentBlock = this.renderContentBlock.bind(this);
  }

  public componentDidMount(): void {
    const contentService = ServiceRegistry.getContentService();
    contentService.getTopics("landingpage-further-topics").then((topics) => this.setState({topics}));
    contentService.getPageByIdentifier("index").then((page) => this.setState({page}));
  }

  public render(): JSX.Element {
    const {topics, page} = this.state;
    if (!page) {
      return (<div/>);
    }
    const {headerImage, headerImageMobile, text, contentBlocks} = page;
    const t = ServiceRegistry.getTranslations().getT();

    return (
      <div>
        <SeoTags
          location={this.props.location}
          title={page.title}
          description={page.seoDescription}
        />
        {headerImage &&
          <HeroTeaser
            image={headerImage}
            imageMobile={headerImageMobile}
            headline={page.title}
            onLandingPage
          />
        }
        {text &&
          <div className="column is-12">
            <TextTeaser>
              <RichText text={text}/>
            </TextTeaser>
          </div>
        }
        {contentBlocks &&
          <div>
            {contentBlocks.map(this.renderContentBlock)}
          </div>
        }
        {topics.length > 0 &&
          <SubjectsTeaser headline={t && t('global.moreTopics')} items={topics} />
        }
      </div>
    );
  }

  // TODO: duplicate code! Same on AboutPage.tsx
  private renderContentBlock({headline, largeImage, smallImage, subHeadline, text, textIsCompact, layout, readMoreTarget, readMoreLabel, id}: ContentBlock): JSX.Element {
    const largeLeft = layout === "large-left";

    if (largeLeft) {
      return (
        <div key={id}>
          <div className='is-hidden-mobile'>
            <CompositionTeaser
              headline={headline || ''}
              subHeadline={subHeadline || ''}
              largeImage={largeImage}
              smallImage={smallImage}
              reversePosition={!largeLeft}
            />
            <RichTextTeaser
              text={text}
              link={readMoreTarget}
              linkLabel={readMoreLabel}
              isSmall={textIsCompact}
            />
          </div>
          <div className='is-hidden-tablet'>
            <CompositionTeaserMobile
                headline={headline || ''}
                subHeadline={subHeadline || ''}
                largeImage={largeImage}
                smallImage={smallImage}
                reversePosition={!largeLeft}
              />
              <RichTextTeaser
                text={text}
                link={readMoreTarget}
                linkLabel={readMoreLabel}
                isSmall={textIsCompact}
              />
          </div>
        </div>
      );
    }

    return (
      <div key={id}>
        <div className='is-hidden-mobile'>
          <CompositionTeaser
            headline={headline || ''}
            subHeadline={subHeadline || ''}
            largeImage={largeImage}
            smallImage={smallImage}
            reversePosition={true}
          >
            <RichTextTeaser
              text={text}
              link={readMoreTarget}
              linkLabel={readMoreLabel}
              isSmall={textIsCompact}
            />
          </CompositionTeaser>
        </div>
        <div className='is-hidden-tablet'>
          <CompositionTeaserMobile
              headline={headline || ''}
              subHeadline={subHeadline || ''}
              largeImage={largeImage}
              smallImage={smallImage}
              reversePosition={true}
            >
              <RichTextTeaser
                text={text}
                link={readMoreTarget}
                linkLabel={readMoreLabel}
                isSmall={textIsCompact}
              />
            </CompositionTeaserMobile>
        </div>
      </div>
    );
  }
}
