import React, { ReactNode } from "react";
import classNames from "classnames";

import styles from "./ProductCategoryTeaser.module.scss";
import IpuroLink from "../IpuroLink/IpuroLink";
import Product from "../../entities/Product";
import Headline from "../Headline/Headline";
import { Link } from "react-router-dom";
import ServiceRegistry from "../../services/ServiceRegistry";
import ImageInfo from "../../entities/ImageInfo";
import { Image } from "../Image/Image";

interface Props {
  headline: string;
  subHeadline: string;
  largeImage: ImageInfo;
  reversePosition?: boolean;
  children?: ReactNode;
  linkLabel?: string;
  linkUrl?: string;
  products?: Product[];
  style?: object;
}

const renderImages = (products) => {
  const productCatalog = ServiceRegistry.getProductCatalog();

  return (
    <div className={`columns is-mobile ${styles.productsList}`}>
      {products.map((product, i) => {
        const { id, previewImage } = product;

        const itemClassName = classNames("column is-5-mobile is-2-tablet", {
          "is-offset-1-tablet": i === 0,
        });

        if (!previewImage) {
          return null;
        }

        return (
          <Link
            key={id}
            className={itemClassName}
            to={productCatalog.getPathOfProduct(product)}
          >
            <Image image={previewImage} />
          </Link>
        );
      })}
    </div>
  );
};

const renderLink = (linkUrl, linkLabel, isMobile = false) => {
  const cn = classNames(styles.ProductCategoryTeaser__link, {
    [styles["is-mobile"]]: isMobile,
  });
  return (
    <div className={cn}>
      <IpuroLink to={linkUrl}>{linkLabel}</IpuroLink>
    </div>
  );
};

const ProductCategoryTeaser: React.SFC<Props> = ({
  headline,
  subHeadline,
  largeImage,
  reversePosition,
  linkLabel,
  linkUrl,
  products,
  children,
  style = {},
}) => {
  const className = classNames({
    [styles.ProductCategoryTeaser]: true,
  });

  const columnsClassName = classNames(
    "columns is-multiline",
    styles.ProductCategoryTeaser__columns,
    {
      [styles.ProductCategoryTeaser__columns___reversed]: reversePosition,
    }
  );

  const mixedColumnclassName = classNames("column", "is-4", {
    [styles.ProductCategoryTeaser__mixedColumn]: true,
    "is-offset-1": reversePosition,
  });

  const largeImageColumnclassName = classNames("column", "is-6", {
    "is-offset-1": !reversePosition,
  });

  return (
    <div className={className} style={style}>
      <div className={columnsClassName}>
        <div className={largeImageColumnclassName}>
          {linkUrl && linkLabel && (
            <IpuroLink to={linkUrl}>
              <Image
                image={largeImage}
                className={styles.ProductCategoryTeaser__ieImageFix}
              />
            </IpuroLink>
          )}
          {!linkUrl || (!linkLabel && <Image image={largeImage} />)}
        </div>
        <div className={mixedColumnclassName}>
          <div className={styles.ProductCategoryTeaser__headlines}>
            <div
              className={styles.ProductCategoryTeaser__headlines__subheadline}
            >
              {subHeadline}
            </div>
            <Headline
              className={styles.ProductCategoryTeaser__headline}
              component="h2"
              variant="h3"
            >
              {headline}
            </Headline>
          </div>
          {linkUrl && linkLabel && renderLink(linkUrl, linkLabel)}
        </div>
      </div>
      {renderImages(products)}
      {linkUrl && linkLabel && renderLink(linkUrl, linkLabel, true)}
    </div>
  );
};

export default ProductCategoryTeaser;
