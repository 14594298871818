import React, { Component } from "react";
import styles from "./Modal.module.scss";
import Portal from "../Portal/Portal";

interface Props {
  onClose?: () => void;
}

class Modal extends Component<Props, {}> {
  render() {
    const { children } = this.props;

    return (
      <Portal>
        <div className={styles.Modal}>
          <div
            className={styles.Modal__backdrop}
            onClick={this.props.onClose}
          ></div>
          <div className={styles.Modal__content}>{children}</div>
        </div>
      </Portal>
    );
  }
}

export default Modal;
