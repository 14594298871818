import ImageInfo from "./ImageInfo";

export type ContentBlockLayout = "large-left" | "large-center-text-right";

export default class ContentBlock {
  public readonly id: string;
  public readonly identifier: string;
  public readonly layout: ContentBlockLayout;
  public readonly subHeadline?: string;
  public readonly headline?: string;
  public readonly text?: any; // RichText
  public readonly textIsCompact?: boolean;
  public readonly largeImage?: ImageInfo;
  public readonly smallImage?: ImageInfo;
  public readonly readMoreTarget?: string;
  public readonly readMoreLabel?: string;

  constructor(
    id: string,
    identifier: string,
    layout: ContentBlockLayout,
    subHeadline?: string,
    headline?: string,
    text?: any,
    textIsCompact?: boolean,
    largeImage?: ImageInfo,
    smallImage?: ImageInfo,
    readMoreTarget?: string,
    readMoreLabel?: string) {
    this.id = id;
    this.identifier = identifier;
    this.layout = layout;
    this.subHeadline = subHeadline;
    this.headline = headline;
    this.text = text;
    this.textIsCompact = textIsCompact;
    this.largeImage = largeImage;
    this.smallImage = smallImage;
    this.readMoreTarget = readMoreTarget;
    this.readMoreLabel = readMoreLabel;
  }
}
