import ProductCatalog from "../entities/ProductCatalog";
import GoogleMapsApiLoader from "../util/GoogleMapsApiLoader";
import CachedContentService from "./CachedContentService";
import ContentfulContentServiceBuilder from "./contentful/ContentfulContentServiceBuilder";
import ContentService from "./ContentService";
import RouteRegistry from "./RouteRegistry";
import StoreDirectory from "./StoreDirectory";
import Translations from "./Translations";

class ServiceContainer {
  private contentService!: ContentService;
  private routeRegistry!: RouteRegistry;
  private productCatalog!: ProductCatalog;
  private translations!: Translations;
  private googleMapsApiLoader!: GoogleMapsApiLoader;
  private storeDirectory!: StoreDirectory;
  private locales!: string[];

  public init(locale: string): Promise<void> {
    const contentful = new ContentfulContentServiceBuilder(
      process.env.REACT_APP_SPACE_ID || "",
      process.env.REACT_APP_CONTENT_DELIVERY_TOKEN || "",
      process.env.REACT_APP_CONTENTFUL_ENVIRONMENT_ID || "master"
    )
      .withHost(process.env.REACT_APP_CONTENT_DELIVERY_HOST || "")
      .withLocale(locale)
      .build();

    if (process.env.REACT_APP_CONTENT_CACHE_ENABLED === "true") {
      const ttl = parseInt(
        process.env.REACT_APP_CONTENT_CACHE_TTL_MS || "300000",
        10
      );
      this.contentService = new CachedContentService(contentful, ttl, locale);
    } else {
      this.contentService = contentful;
    }

    this.routeRegistry = new RouteRegistry();
    this.googleMapsApiLoader = new GoogleMapsApiLoader(
      process.env.REACT_APP_GOOGLE_API_KEY || "",
      locale,
      "DE"
    );

    const promises: Promise<any>[] = [];
    promises.push(
      this.contentService.getAllRoutes().then((routes) => {
        this.routeRegistry.init(routes);
        if (routes) {
          this.routeRegistry.addAll(routes);
        }
      })
    );
    promises.push(
      this.contentService.getLocales().then((locales) => {
        this.locales = locales;
        return this.contentService
          .getProductCatalog(locales)
          .then((productCatalog) => (this.productCatalog = productCatalog));
      })
    );
    promises.push(
      this.contentService
        .getTranslations()
        .then((translations) => (this.translations = translations))
    );
    promises.push(
      this.contentService
        .getAllStores()
        .then((stores) => (this.storeDirectory = new StoreDirectory(stores)))
    ); // @todo this could be deferred until the user uses the StoreFinder
    return Promise.all(promises).then(() => {});
  }

  public getContentService(): ContentService {
    return this.contentService;
  }

  public getRouteRegistry(): RouteRegistry {
    return this.routeRegistry;
  }

  public getProductCatalog(): ProductCatalog {
    return this.productCatalog;
  }

  public getTranslations(): Translations {
    return this.translations;
  }

  public getGoogleMapsApiLoader(): GoogleMapsApiLoader {
    return this.googleMapsApiLoader;
  }

  public getStoreDirectory(): StoreDirectory {
    return this.storeDirectory;
  }

  public getLocales(): string[] {
    return this.locales;
  }

  public setLocale(locale: string): void {
    this.translations.setLocale(locale);
    this.contentService.setLocale(locale);
    // @todo reload translations
  }
}

const ServiceRegistry: ServiceContainer = new ServiceContainer();

export default ServiceRegistry;
